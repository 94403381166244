import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useEffect, useRef, useState } from "react";
import * as yup from 'yup';
import { AccordionContacts } from "../../../../components";
import { Components, MediStrap } from "../../../../MediStrap";
import { getClientToken } from "../../../../utils/client";
import { CITIES, DISTRICTS } from "../../../../utils/commonData";
import { MUTATION_CLIENT_DELETE, MUTATION_CLIENT_UPDATE } from "../../graphql/mutations";
import { QUERY_CLIENT } from "../../graphql/queries";

const districtOptions = DISTRICTS;

const cityOptions = CITIES;

const MediStrapProperties = MediStrap.getProperties();

function ModalUpdate({ isOpen, onRequestClose, onUpdate, onDelete, factorings, clientId }) {
    const [fetchingExport, setFetchingExport] = useState(false);
    const clientUpdateFormRef = useRef(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [client, setClient] = useState(null);

    const [queryClient] = useLazyQuery(
        QUERY_CLIENT,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.client) {
                    setClient(data.client);
                }
            }
        }
    );

    const requestClient = (params = {}) => {
        const { id } = params;

        queryClient({
            variables: {
                id
            }
        });
    }

    useEffect(() => {
        if (!!clientId) {
            requestClient({ id: clientId });
        }
    }, [clientId]);

    const [mutationClientUpdate, { loading }] = useMutation(
        MUTATION_CLIENT_UPDATE,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.clientUpdate) {
                    onUpdate(data.clientUpdate);
                    onRequestClose();
                }
            }
        }
    );

    const updateClient = (params = {}) => {
        const { id, name, nif, address, city, district, postalCode, factoring, contacts } = params;

        mutationClientUpdate({
            variables: {
                id,
                name: !!name ? name : undefined,
                nif: !!nif ? nif : undefined,
                address: !!address ? address : undefined,
                city: !!city ? city : undefined,
                district: !!district ? district : undefined,
                postalCode: !!postalCode ? postalCode : undefined,
                factoring: !!factoring ? factoring : undefined,
                contacts: !!contacts ? contacts : []
            }
        });
    }

    const [mutationClientDelete, { loading: loadingDelete }] = useMutation(
        MUTATION_CLIENT_DELETE,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data) {
                    onDelete(data.clientDelete);
                }
            }
        }
    );

    const deleteClient = (params = {}) => {
        const { id } = params;

        mutationClientDelete({
            variables: {
                id
            }
        });
    }

    useEffect(() => {
        if (!isOpen) {
            setClient(null);
        }
    }, [isOpen])

    const exportRequest = () => {
        if (!fetchingExport) {
            setFetchingExport(true);

            fetch(`${process.env.REACT_APP_BACKEND_HOST}/export/clients`, {
                method: "POST",
                headers: { "Authorization": getClientToken(), "Content-Type": "application/json" },
                body: JSON.stringify({
                    clientId
                })
            })
                .then(res => res.blob())
                .then(blob => {
                    var file = window.URL.createObjectURL(blob);
                    window.open(file, "_blank");
                    setFetchingExport(false);
                })
                .catch(() => {
                    setFetchingExport(false);
                });
        }
    }
    return (
        <Components.ModalThatSlides
            id="editar-cliente"
            title="Editar Cliente"
            width="100%"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            actions={
                <Components.Block row center>
                    <Components.Block mr={12}>
                        <Components.Button loading={loading} label="Guardar" color="brand" onClick={() => { clientUpdateFormRef.current.handleSubmit() }} />
                    </Components.Block>
                    <Components.Block>
                        <Components.Button loading={loadingDelete} icon="Trash" color="red" size="square" onClick={() => setIsDeleteModalOpen(true)} />
                    </Components.Block>
                </Components.Block>
            }
            menu={[
                { icon: "Information", title: "Informações", anchor: "informations" },
                { icon: "Contacts", title: "Contactos", anchor: "contacts" }
            ]}
            onClickExport={exportRequest}
            loadingExport={fetchingExport}
        >
            {!!client ?
                <>
                    <Components.Form
                        initialValues={{
                            name: !!client && client.name,
                            nif: !!client && client.nif,
                            address: !!client && client.address,
                            district: !!client && !!client.district && districtOptions.find((item) => item.value == client.district),
                            city: !!client && !!client.city && cityOptions.find((item) => item.district == client.district).cities.find((item) => item.value == client.city),
                            postalCode: !!client && client.postalCode,
                            factoring: !!client && !!client.factoring && { label: client.factoring.name, value: client.factoring.id },
                            contacts: !!client && client.contacts.map((item) => { return { name: item.name, phone: item.phone, email: item.email } })
                        }}
                        schema={yup.object().shape({
                            name: yup.string().required(),
                            district: yup.object().nullable(),
                            city: yup.object().nullable(),
                            factoring: yup.object().nullable(),
                            address: yup.string().nullable(),
                            postalCode: yup.string().nullable(),
                            nif: yup.string().nullable(),
                            contacts: yup.array(yup.object({
                                name: yup.string().nullable(),
                                email: yup.string().email().nullable(),
                                phone: yup.string().nullable()
                            }))
                        })}
                        onSubmit={(values) => {
                            const district = !!values.district ? values.district.value : undefined;
                            const city = !!values.city ? values.city.value : undefined;
                            const factoring = !!values.factoring ? values.factoring.value : undefined;
                            const contacts = !!values.contacts ? values.contacts : [];
                            updateClient({ id: client.id, name: values.name, nif: values.nif, address: values.address, city, district, postalCode: values.postalCode, factoring, contacts })
                        }}
                        innerRef={clientUpdateFormRef}
                    >
                        {({
                            values,
                            errors,
                            handleSubmit,
                            setFieldValue
                        }) => {
                            !!values.city && !cityOptions.find((item) => item.district == values.district.value).cities.find((item) => item.value == values.city.value) && setFieldValue('city', null);

                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Components.Portlet icon="Information" title="Informações" anchor="informations">
                                        <Components.FormGroup title="Geral" description="Informação geral do contrato e cliente">
                                            <Components.Block width={652}>
                                                <Components.Input error={errors.name} Component={Components.TextInput} name="name" label="Nome*" description="Nome identificativo do cliente ou empresa" placeholder="Nome do cliente" />
                                            </Components.Block>
                                            <Components.Block row>
                                                <Components.Block width={328} pr={10}>
                                                    <Components.Input error={errors.district} Component={Components.DropdownInput} name="district" label="Distrito" placeholder="Distrito" options={districtOptions} />
                                                </Components.Block>
                                                <Components.Block width={304} pl={10}>
                                                    <Components.Input error={errors.city} Component={Components.DropdownInput} name="city" label="Cidade" placeholder="Cidade da sede" options={!!values.district ? cityOptions.find((item) => item.district == values.district.value).cities : []} />
                                                </Components.Block>
                                            </Components.Block>
                                            <Components.Block row>
                                                <Components.Block width={536} pr={10}>
                                                    <Components.Input error={errors.address} Component={Components.TextInput} name="address" label="Morada" placeholder="Morada completa" />
                                                </Components.Block>
                                                <Components.Block width={190} pl={10}>
                                                    <Components.Input error={errors.postalCode} Component={Components.TextInput} name="postalCode" label="Código Postal" placeholder="0000-000" />
                                                </Components.Block>
                                            </Components.Block>
                                        </Components.FormGroup>
                                        <Components.FormGroup title="Faturação" description="Dados de faturação">
                                            <Components.Block row ml={-10} mr={-10}>
                                                <Components.Block width={304} ml={10} mr={10}>
                                                    <Components.Input error={errors.nif} Component={Components.TextInput} name="nif" label="NIF" placeholder="Número de Identificação Fiscal" />
                                                </Components.Block>
                                                <Components.Block width={232} ml={10} mr={10}>
                                                    <Components.Input error={errors.factoring} Component={Components.DropdownInput} name="factoring" label="Factoring" placeholder="Seleccionar factoring" options={factorings.map((factoring) => ({ label: factoring.name, value: factoring.id }))} />
                                                </Components.Block>
                                            </Components.Block>
                                        </Components.FormGroup>
                                    </Components.Portlet>
                                    <Components.Input Component={AccordionContacts} name="contacts" anchor="contacts" errors={errors} />
                                </form>
                            )
                        }}
                    </Components.Form>

                    <Components.ActionPanel1
                        isOpen={isDeleteModalOpen}
                        onRequestClose={() => setIsDeleteModalOpen(false)}
                        title="Eliminar cliente"
                        description="Esta acção irá eliminar definitavamente todos os dados associados ao cliente, pretende mesmo assim continuar?"
                        confirmButtonProps={{
                            label: "Eliminar",
                            icon: "Trash",
                            onClick: () => { deleteClient({ id: client.id }); setIsDeleteModalOpen(false); onRequestClose(); }
                        }}
                        cancelButtonProps={{
                            label: "Cancelar",
                            onClick: () => setIsDeleteModalOpen(false)
                        }}
                    />
                </>
                :
                <Components.Block center middle>
                    <Components.Loading color={MediStrapProperties.colors.brand100} />
                </Components.Block>}
        </Components.ModalThatSlides>
    );
}

export default ModalUpdate;