import React from "react";
import styled, { css } from 'styled-components';
import MediStrap from "../../../MediStrap";
import Block from '../../Block';
import Loading from "../../Loading";
import Text from '../../Text';

const MediStrapProperties = MediStrap.getProperties();

function TextInput(
    {
        label,
        description,
        error,
        success,
        loading,
        onChangeValue,
        value = "",
        icon,
        ...props
    }
) {
    return (
        <Block width="100%">
            {!!label && <Text size={12} height={16} mb={8} color={MediStrapProperties.colors.grey100}>{label}</Text>}
            <Block style={{ position: "relative" }}>
                {(!!icon && !error && !success && !loading) && <IconWrapper><span className={`icon-${icon}`} /></IconWrapper>}
                {(value !== "" && !icon && !props.disabled && !error && !success && !loading) && <DeleteWrapper onClick={() => onChangeValue("")}><span className="icon-Delete" /></DeleteWrapper>}
                {(!!error) && <StatusWrapper><span className="icon-Error" /></StatusWrapper>}
                {(!!success) && <StatusWrapper><span className="icon-Upload_Valid" /></StatusWrapper>}
                {(!!loading) && <StatusWrapper><Loading color={MediStrapProperties.colors.brand100} /></StatusWrapper>}
                <Input
                    type="text"
                    error={error}
                    success={success}
                    value={value}
                    onChange={(e) => !!onChangeValue && onChangeValue(e.target.value)}
                    {...props}
                />
            </Block>
            {(() => {
                let color = null;
                let text = null;
                if (!!error) { text = typeof error === "string" ? error : null; color = MediStrapProperties.colors.alert100; }
                else if (!!success) { text = typeof success === "string" ? success : null; color = MediStrapProperties.colors.validation100; }
                else if (!!loading) { text = typeof loading === "string" ? loading : null; color = MediStrapProperties.colors.brand100; }
                else if (!!description) { text = description; color = MediStrapProperties.colors.grey50; }

                if (!!text) {
                    return (
                        <Block mt={8}>
                            <Text
                                size={11}
                                height={16}
                                color={color}
                            >
                                {error || success || loading || description}
                            </Text>
                        </Block>
                    );
                }
            })()}
        </Block>
    );
}

export default React.memo(TextInput);

const Input = styled.input`
    background-color: ${MediStrapProperties.colors.white100};
    height: 48px;
    color: ${MediStrapProperties.colors.grey100};
    font-size: 14px;
    line-height: 24px;
    font-family: "MedistrapSemiBold";
    border: 0;
    padding: 8px 48px 8px 16px;
    border: 1px solid ${MediStrapProperties.colors.grey20};
    border-radius: ${MediStrapProperties.style.borderRadius}px;

    ::placeholder {
        color: ${MediStrapProperties.colors.grey50};
    }

    :hover {
        border-color: ${({ error, success }) => {
        if (!!error) {
            return MediStrapProperties.colors.alert100;
        }

        if (success) {
            return MediStrapProperties.colors.validation100;
        }

        return MediStrapProperties.colors.grey60;
    }};
    }
    
    :focus {
        border-color: ${MediStrapProperties.colors.brand100};
    }

    :disabled {
        color: ${MediStrapProperties.colors.grey20};
        background-color: ${MediStrapProperties.colors.grey5};
        border-color: ${MediStrapProperties.colors.grey20};
    }

    ${({ error }) => !!error && css`
        border-color:  ${MediStrapProperties.colors.alert100} !important;
    `}

    ${({ success }) => !!success && css`
        border-color:  ${MediStrapProperties.colors.validation100};
    `}
`;

const IconWrapper = styled(Block)`
    position: absolute;
    top: 16px;
    right: 16px;
`;

const DeleteWrapper = styled(Block)`
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
`;

const StatusWrapper = styled(Block)`
    position: absolute;
    top: 16px;
    right: 16px;
`;