import gql from "graphql-tag";

export const QUERY_REMINDERS = gql`
    query reminders($perPage: Int, $currentPage: Int, $search: String) {
        reminders(perPage: $perPage, currentPage: $currentPage, search: $search){
            rows {
                id
                motive
                date
                contract {
                    id
                    status
                    code
                    client {
                        id
                        name
                    }
                }
            }
            pagination {
                total
                perPage
                lastPage
                currentPage
                hasPreviousPage
                hasNextPage
            }
        }
    }
`;

export const QUERY_REMINDER = gql`
    query reminder($id: ID!) {
        reminder(id: $id) {
            id
            motive
            date
            contract {
                id
                status
                code
                client {
                    id
                    name
                }
            }
        }
    }
`;

export const QUERY_CONTRACTS = gql`
    query contracts($perPage: Int, $currentPage: Int, $search: String) { 
        contracts(perPage: $perPage, currentPage: $currentPage, search: $search){
            rows {
                id
                status
                code
                client {
                    id
                    name
                }
            }
            statusCount {
                active
                deactivated
                expired
                renewing
                expiringInOneMonth
            }
        }
    }
`;

export const QUERY_EVENTS = gql`
    query events($perPage: Int, $currentPage: Int, $search: String) { 
        events(perPage: $perPage, currentPage: $currentPage, search: $search){
            rows {
                id
                type
                date
                contract {
                    id
                    code
                    status
                    client {
                        id
                        name
                    }
                }
            }
        }
    }
`;