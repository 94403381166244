import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { MediStrap } from "../../MediStrap";
import Accounts from "./Tabs/Accounts";
import Categories from './Tabs/Categories';
import Licenses from './Tabs/Licenses';
import Factoring from './Tabs/Factoring';

const MediStrapProperties = MediStrap.getProperties();

function Settings() {

    return (
        <Switch>
            <Route path="/dashboard/settings/accounts" component={Accounts} />
            <Route path="/dashboard/settings/categories" component={Categories} />
            <Route path="/dashboard/settings/licensing" component={Licenses} />
            <Route path="/dashboard/settings/factoring" component={Factoring} />
            <Route
                exact
                path="/dashboard/settings"
                render={() => <Redirect to="/dashboard/settings/accounts" />}
            />
        </Switch>
    );
}

export default Settings;
