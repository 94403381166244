import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useEffect, useRef, useState } from "react";
import * as yup from 'yup';
import { Components, MediStrap } from "../../../../../MediStrap";
import { MUTATION_USER_DELETE, MUTATION_USER_UPDATE } from "../../../graphql/mutations";
import { QUERY_USER } from "../../../graphql/queries";

const MediStrapProperties = MediStrap.getProperties();

function ModalUpdate({ onRequestClose, onUpdate, onDelete, isOpen, accountId }) {
    const updateFormRef = useRef(null);

    const [account, setAccount] = useState(null);

    const [queryAccount] = useLazyQuery(
        QUERY_USER,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.user) {
                    setAccount(data.user);
                }
            }
        }
    );

    const requestAccount = (params = {}) => {
        const { id } = params;

        queryAccount({
            variables: {
                id
            }
        });
    }

    useEffect(() => {
        if (!!accountId) {
            requestAccount({ id: accountId });
        }
    }, [accountId]);

    const [mutationAccountUpdate, { loading: loadingAccountUpdate }] = useMutation(
        MUTATION_USER_UPDATE,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.userUpdate) {
                    onUpdate(data.userUpdate);
                    onRequestClose();
                }
            }
        }
    );

    const updateAccount = (params = {}) => {
        const { name, role, email, password } = params;

        mutationAccountUpdate({
            variables: {
                id: account.id,
                name,
                position: role,
                email,
                password
            }
        });
    }

    const [mutationAccountDelete] = useMutation(
        MUTATION_USER_DELETE,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.userDelete) {
                    onDelete();
                    onRequestClose();
                }
            }
        }
    );

    const deleteAccount = () => {
        mutationAccountDelete({
            variables: {
                id: account.id
            }
        });
    }

    useEffect(() => {
        if (!isOpen) {
            setAccount(null);
        }
    }, [isOpen])

    return (
        <Components.Modal
            title="Editar conta"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            rightActions={
                <Components.Block row center>
                    <Components.Block>
                        <Components.Button type="submit" loading={loadingAccountUpdate} size="medium" label="Editar conta" color="brand" onClick={() => updateFormRef.current.handleSubmit()} />
                    </Components.Block>
                </Components.Block>
            }
        >
            {!!account ?
                <>
                    <Components.Form
                        innerRef={updateFormRef}
                        initialValues={{
                            name: account.name,
                            role: account.position,
                            email: account.email
                        }}
                        schema={yup.object().shape({
                            name: yup.string().required(),
                            role: yup.string().required(),
                            email: yup.string().email().required(),
                            password: yup.string()
                        })}
                        onSubmit={(values) => {
                            updateAccount(values);
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Components.FormGroup title="Geral">
                                        <Components.Block width={420}>
                                            <Components.Input error={errors.name} Component={Components.TextInput} name="name" label="Nome" placeholder="Primeiro e último nome" />
                                        </Components.Block>
                                        <Components.Block width={334}>
                                            <Components.Input error={errors.role} Component={Components.TextInput} name="role" label="Cargo" placeholder="Cargo na empresa" />
                                        </Components.Block>
                                    </Components.FormGroup>
                                    <Components.FormGroup title="Credênciais">
                                        <Components.Block width={420}>
                                            <Components.Input error={errors.email} Component={Components.TextInput} name="email" label="Email" placeholder="Email da empresa" />
                                        </Components.Block>
                                        <Components.Block width={334}>
                                            <Components.Input error={errors.password} Component={Components.PasswordInput} name="password" label="Password" placeholder="Password" description="Password para acesso a plataforma" />
                                        </Components.Block>
                                    </Components.FormGroup>
                                    <Components.FormGroup>
                                        <Components.DeleteLine
                                            title="Eliminar conta"
                                            description="Esta ação irá eliminar a conta permanentemente "
                                            onClickDelete={() => { deleteAccount({ id: account.id }); onRequestClose(); }}
                                        />
                                    </Components.FormGroup>
                                </form>
                            )
                        }}
                    </Components.Form>
                </>
                :
                <Components.Block center middle>
                    <Components.Loading color={MediStrapProperties.colors.brand100} />
                </Components.Block>}
        </Components.Modal>
    );
}

export default ModalUpdate;
