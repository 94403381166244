import gql from "graphql-tag";

export const MUTATION_LICENSE_CREATE = gql`
    mutation licenseCreate($name: String!) {
        licenseCreate (name: $name){
            id
            name
        }
    }
`;
export const MUTATION_LICENSE_UPDATE = gql`
    mutation licenseUpdate($id: ID!, $name: String!) {
        licenseUpdate (id: $id, name: $name){
            id
            name
        }
    }
`;
export const MUTATION_LICENSE_DELETE = gql`
    mutation licenseDelete($id: ID!) {
        licenseDelete (id: $id) {
            id
            name
        }
    }
`;

export const MUTATION_FACTORING_CREATE = gql`
    mutation factoringCreate($name: String!) {
        factoringCreate (name: $name){
            id
            name
        }
    }
`;
export const MUTATION_FACTORING_UPDATE = gql`
    mutation factoringUpdate($id: ID!, $name: String!) {
        factoringUpdate (id: $id, name: $name){
            id
            name
        }
    }
`;
export const MUTATION_FACTORING_DELETE = gql`
    mutation factoringDelete($id: ID!) {
        factoringDelete (id: $id) {
            id
            name
        }
    }
`;

export const MUTATION_CATEGORY_CREATE = gql`
    mutation categoryCreate($name: String!) {
        categoryCreate (name: $name){
            id
            name
        }
    }
`;
export const MUTATION_CATEGORY_UPDATE = gql`
    mutation categoryUpdate($id: ID!, $name: String!) {
        categoryUpdate (id: $id, name: $name){
            id
            name
        }
    }
`;
export const MUTATION_CATEGORY_DELETE = gql`
    mutation categoryDelete($id: ID!) {
        categoryDelete (id: $id) {
            id
            name
        }
    }
`;

export const MUTATION_USER_CREATE = gql`
    mutation userCreate($name: String!, $email: String!, $position: String!, $password: String!) {
        userCreate (name: $name, email: $email, position: $position, password: $password){
            id
            name
            email
            position
        }
    }
`;
export const MUTATION_USER_UPDATE = gql`
    mutation userUpdate($id: ID!, $name: String!, $email: String!, $position: String!, $password: String) {
        userUpdate (id: $id, name: $name, email: $email, position: $position, password: $password){
            id
            name
            email
            position
        }
    }
`;
export const MUTATION_USER_DELETE = gql`
    mutation userDelete($id: ID!) {
        userDelete (id: $id) {
            id
            name
            email
            position
        }
    }
`;