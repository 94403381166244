import React from "react";
import styled, { css } from "styled-components";
import MediStrap from "../../MediStrap";
import Block from "../Block";
import Text from "../Text";

const MediStrapProperties = MediStrap.getProperties();

const styles = {
    brand: {
        background: MediStrapProperties.colors.brand10,
        border: MediStrapProperties.colors.brand30,
        color: MediStrapProperties.colors.brand100
    },
    alert: {
        background: MediStrapProperties.colors.alert10,
        border: MediStrapProperties.colors.alert30,
        color: MediStrapProperties.colors.alert100
    },
    infomation: {
        background: MediStrapProperties.colors.information10,
        border: MediStrapProperties.colors.information30,
        color: MediStrapProperties.colors.information100
    }
}

export default function Badge(
    {
        label,
        color = "brand"
    }
) {
    return (
        <Wrapper color={color}>
            <Text size={11} height={16}>{label}</Text>
        </Wrapper>
    );
}

const Wrapper = styled(Block)`
    width: fit-content;
    height: 24px;
    padding: 4px 8px;
    border-radius: ${MediStrapProperties.style.borderRadius}px;

    ${({ color }) => css`
        background-color: ${styles[color].background};
        border: 1px solid ${styles[color].border};
        color: ${styles[color].color};
    `}
`;