import { useLazyQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Components, MediStrap } from "../../../../MediStrap";
import { QUERY_LICENSES } from "../../graphql/queries";

const MediStrapProperties = MediStrap.getProperties();

function AccordionLicensing({ value = [], anchor, onChangeValue, contractLicenses = [] }) {
    const [search, setSearch] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [toDeleteItem, setToDeleteItem] = useState(null);
    const [licenses, setLicenses] = useState([]);
    const [selectedLicenses, setSelectedLicenses] = useState(contractLicenses);

    const [queryLincenses] = useLazyQuery(
        QUERY_LICENSES,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.licenses) {
                    setLicenses(data.licenses.rows);
                }
            }
        }
    );

    const requestLicenses = () => {
        queryLincenses({
            variables: {
                perPage: 1000,
                currentPage: 1,
                search: search !== "" ? search : undefined,
            }
        });
    }

    useEffect(() => {
        requestLicenses();
    }, [search]);

    return (
        <>
            <Components.Portlet anchor={anchor} noInnerPadding icon="Licenses" title="Licenciamento" actions={<Components.Button type="button" label="Adicionar Licenciamento" color="brand" size="small" onClick={() => setIsModalOpen(!isModalOpen)} />}>
                {value.map((item) => {
                    return (
                        <Components.Block key={item.id}>
                            <Components.PortletLine
                                left={
                                    <Components.Block>
                                        <Components.Text>{item.name}</Components.Text>
                                    </Components.Block>
                                }
                                right={
                                    <Components.Block>
                                        <Components.Button icon="Trash" color="empty" size="squareSmall" onClick={() => setToDeleteItem(item)} />
                                        <Components.ActionPanel1
                                            isOpen={!!toDeleteItem && toDeleteItem.id === item.id}
                                            onRequestClose={() => setToDeleteItem(null)}
                                            title="Eliminar licença"
                                            description={"Esta ação irá eliminar os dados relativos à licença permanentemente, pretende mesmo assim continuar?"}
                                            confirmButtonProps={{
                                                label: "Eliminar",
                                                icon: "Trash",
                                                onClick: () => {
                                                    setSelectedLicenses(selectedLicenses.filter((license) => license.id !== item.id));
                                                    onChangeValue(selectedLicenses.filter((license) => license.id !== item.id));
                                                }
                                            }}
                                            cancelButtonProps={{
                                                label: "Cancelar",
                                                onClick: () => setToDeleteItem(null)
                                            }}
                                        />
                                    </Components.Block>
                                }
                            >
                            </Components.PortletLine>
                            <Components.Block height={1} color={MediStrapProperties.colors.grey20} />
                        </Components.Block>
                    );
                })}
            </Components.Portlet>
            <Components.Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                title="Selecionar licenciamento(s)"
                leftActions={
                    <Components.Block>
                        <Components.Text size={12} height={16} color={MediStrapProperties.colors.grey100}>Selecionados</Components.Text>
                        <Components.Block row>
                            <Components.Text size={14} height={24} color={MediStrapProperties.colors.brand100}>({selectedLicenses.length})</Components.Text>
                            <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey50} ml={4}>{selectedLicenses.length === 1 ? "Licenciamento" : "Licenciamentos"}</Components.Text>
                        </Components.Block>
                    </Components.Block>
                }
                rightActions={
                    <Components.Block row>
                        <Components.Block>
                            <Components.Button icon="Trash" label="Limpar" color="empty" size="medium" onClick={() => setSelectedLicenses([])} />
                        </Components.Block>
                        <Components.Block ml={12}>
                            <Components.Button label="Aplicar" color="brand" size="medium" onClick={() => { onChangeValue(selectedLicenses); setIsModalOpen(false); }} />
                        </Components.Block>
                    </Components.Block>
                }
                width={444}
                noInnerPadding
            >
                <Components.FormGroup>
                    <Components.Block center mt={24}>
                        <Components.Block width={396}>
                            <Components.TextInput name="licensingSearch" placeholder="Procurar licenciamento" icon="Search" value={search} onChangeValue={(value) => setSearch(value)} />
                        </Components.Block>
                    </Components.Block>
                    <LicensesBlock>
                        {licenses.map((item) => {
                            return (
                                <Item key={item.id} pl={24} pr={28} height={48} row space="between" center>
                                    <Components.Text size={14} height={24}>{item.name}</Components.Text>
                                    <Components.Block width={16}>
                                        <Components.CheckInput 
                                        value={selectedLicenses.find((license) => license.id === item.id)} 
                                        onChangeValue={(value) => {
                                            if (value) {
                                                setSelectedLicenses([...selectedLicenses, item]);
                                            } else {
                                                setSelectedLicenses(selectedLicenses.filter((license) => license.id !== item.id));
                                            }
                                        }} />
                                    </Components.Block>
                                </Item>
                            );
                        })}
                    </LicensesBlock>
                </Components.FormGroup>
            </Components.Modal>
        </>
    );
}

const Item = styled(Components.Block)`
    height: 48px;
    background-color: ${MediStrapProperties.colors.white100};
    border-top: 1px solid ${MediStrapProperties.colors.grey20};
`;

const LicensesBlock = styled(Components.Block)`
    max-height: 288px;
    overflow-y: auto;
`;

export default AccordionLicensing;