import React, { useRef, useState } from "react";
import { Components, MediStrap } from "../../../../MediStrap";

const MediStrapProperties = MediStrap.getProperties();

function AccordionAttachments({ name, value = [], anchor }) {
    const inputRef = useRef(null);
    const [deleteIndex, setDeleteIndex] = useState(null);

    return (
        <Components.FieldArray
            name={name}
            render={(arrayHelpers) => {
                return (
                    <>
                        <input
                            type="file"
                            ref={inputRef}
                            style={{ display: "none" }}
                            onChange={(e) => {
                                if (e.target.files[0]) {
                                    arrayHelpers.insert(value.length + 1, e.target.files[0])
                                    inputRef.current.value = null;
                                }
                            }} />
                        <Components.Portlet anchor={anchor} noInnerPadding icon="Anexo" title="Anexos" actions={<Components.Button type="button" label="Adicionar Anexo" color="brand" size="small" onClick={() => inputRef.current.click()} />}>
                            {value.map((item, index) => {
                                const isFileToUpload = !item.url;
                                return (
                                    <Components.Block key={index}>
                                        <Components.PortletLine
                                            left={
                                                <Components.Block column>
                                                    <Components.Text height={24}>{item.name}</Components.Text>
                                                    {!isFileToUpload && <Components.Text height={16} size={11} color={MediStrapProperties.colors.grey60}>{item.date}</Components.Text>}
                                                </Components.Block>
                                            }
                                            right={
                                                <Components.Block row space="between" center>
                                                    {!isFileToUpload && <>
                                                        <Components.Button
                                                            type="button"
                                                            icon="Download"
                                                            color="empty"
                                                            size="small"
                                                            label="Download"
                                                            onClick={() => window.open(item.url, '_blank')?.focus()}
                                                        />
                                                        <Components.Block width={1} height={16} mr={8} color={MediStrapProperties.colors.grey20} />
                                                    </>}
                                                    <Components.Button type="button" icon="Trash" color="empty" size="squareSmall" onClick={() => setDeleteIndex(index)} />
                                                    <Components.ActionPanel1
                                                        isOpen={deleteIndex === index}
                                                        onRequestClose={() => setDeleteIndex(null)}
                                                        title="Eliminar anexo"
                                                        description={"Esta ação irá eliminar os dados relativos ao anexo permanentemente, pretende mesmo assim continuar?"}
                                                        confirmButtonProps={{
                                                            label: "Eliminar",
                                                            icon: "Trash",
                                                            onClick: () => {
                                                                arrayHelpers.remove(index);
                                                                setDeleteIndex(null);
                                                            }
                                                        }}
                                                        cancelButtonProps={{
                                                            label: "Cancelar",
                                                            onClick: () => setDeleteIndex(null)
                                                        }}
                                                    />
                                                </Components.Block>
                                            }
                                        >
                                        </Components.PortletLine>
                                        <Components.Block height={1} color={MediStrapProperties.colors.grey20} />
                                    </Components.Block>
                                );
                            })}
                        </Components.Portlet>
                    </>
                );
            }}
        />
    );
}

export default AccordionAttachments;