import { useLazyQuery } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { Components, MediStrap } from "../../MediStrap";
import { getClientToken } from '../../utils/client';
import ModalCreate from './components/ModalCreate';
import ModalFilter from './components/ModalFilters';
import ModalUpdate from './components/ModalUpdate';
import { QUERY_CLIENTS, QUERY_FACTORINGS } from './graphql/queries';

const MediStrapProperties = MediStrap.getProperties();

const perPageOptions = [{ label: "10 linhas", value: 10 }, { label: "50 linhas", value: 50 }, { label: "100 linhas", value: 100 }];

function Clients() {
    const [fetchingExport, setFetchingExport] = useState(false);
    const [search, setSearch] = useState('');
    const [filters, setFilters] = useState({
        factorings: [],
        district: null,
        city: null
    });

    const [orderBy, setOrderBy] = useState({ column: "id", order: "DESC" });
    const [clients, setClients] = useState({
        rows: [],
        pagination: {
            total: 0,
            perPage: 10,
            lastPage: 0,
            currentPage: 1,
            hasPreviousPage: false,
            hasNextPage: false
        }
    });
    const [factorings, setFactorings] = useState([]);
    const [clientId, setClientId] = useState(null);

    const [isFiltersOpen, setFiltersOpen] = useState(false);
    const [isActionPanelDeleteOpen, setActionPanelDeleteOpen] = useState(false);
    const [isModalCreateOpen, setModalCreateOpen] = useState(false);
    const [isModalUpdateOpen, setModalUpdateOpen] = useState(false);

    const [queryClients] = useLazyQuery(
        QUERY_CLIENTS,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.clients) {
                    setClients(data.clients);
                }
            }
        }
    );

    const requestClients = (params = {}) => {
        const { perPage, currentPage } = params;

        queryClients({
            variables: {
                perPage: perPage || clients.pagination.perPage,
                currentPage: currentPage || clients.pagination.currentPage,
                search: search !== "" ? search : undefined,
                orderBy: !!orderBy ? `${orderBy.column}_${orderBy.order}` : undefined,
                factorings: !!filters.factorings ? filters.factorings.map((item) => item.value) : undefined,
                district: !!filters.district ? filters.district.value : undefined,
                city: !!filters.city ? filters.city.value : undefined
            }
        });
    }

    useEffect(() => {
        requestClients({ perPage: clients.pagination.perPage, currentPage: 1 });
    }, [search, filters, orderBy]);

    const [queryFactorings] = useLazyQuery(
        QUERY_FACTORINGS,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.factorings) {
                    setFactorings(data.factorings.rows);
                }
            }
        }
    );

    const requestFactorings = () => {
        queryFactorings({
            variables: {
                perPage: 1000,
                currentPage: 1
            }
        });
    }

    useEffect(() => {
        requestFactorings();
    }, []);

    const exportRequest = () => {
        if (!fetchingExport) {
            setFetchingExport(true);

            fetch(`${process.env.REACT_APP_BACKEND_HOST}/export/clients`, {
                method: "POST",
                headers: { "Authorization": getClientToken(), "Content-Type": "application/json" },
                body: JSON.stringify({
                    search,
                    ...filters
                })
            })
                .then(res => res.blob())
                .then(blob => {
                    var file = window.URL.createObjectURL(blob);
                    window.open(file, "_blank");
                    setFetchingExport(false);
                })
                .catch(() => {
                    setFetchingExport(false);
                });
        }
    }

    return (
        <>
            <Components.Block row space="between">
                <Components.Block row>
                    <Components.Block width={444}>
                        <Components.TextInput icon="Search" placeholder="Procurar cliente ou contrato" value={search} onChangeValue={(value) => setSearch(value)} />
                    </Components.Block>
                    <Components.Block ml={20}>
                        <Components.Button
                            color="brand"
                            label="Filtrar"
                            icon="Filter"
                            onClick={() => setFiltersOpen(!isFiltersOpen)}
                        />
                    </Components.Block>
                    <Components.Block row center width={163} ml={20}>
                        <Components.DropdownInput onChangeValue={(option) => requestClients({ perPage: option.value, currentPage: 1 })} value={perPageOptions.find((item) => item.value == clients.pagination.perPage)} options={perPageOptions} />
                    </Components.Block>
                    <Components.Block ml={12}>
                        <Components.Navigation
                            perPage={clients.pagination.perPage}
                            currentPage={clients.pagination.currentPage}
                            total={clients.pagination.total}
                            hasPreviousPage={clients.pagination.hasPreviousPage}
                            hasNextPage={clients.pagination.hasNextPage}
                            onClickPrevious={() => requestClients({ currentPage: (clients.pagination.currentPage - 1) })}
                            onClickNext={() => requestClients({ currentPage: (clients.pagination.currentPage + 1) })}
                        />
                    </Components.Block>
                </Components.Block>
                <Components.Block row>
                    <Components.Block>
                        <Components.Button loading={fetchingExport} color="grey" icon="Download" label="Exportar" onClick={exportRequest} />
                    </Components.Block>
                    <Components.Block ml={8}>
                        <Components.Button color="brand" label="Criar cliente" onClick={() => setModalCreateOpen(true)} />
                    </Components.Block>
                </Components.Block>
            </Components.Block>

            <Components.Block flex pt={32} style={{ overflow: "hidden" }}>
                <Components.AdvancedTable
                    orderBy={orderBy}
                    onClickOrderBy={(column, order) => { setOrderBy({ column, order }); }}
                    columns={[
                        {
                            label: "Nome",
                            value: "name",
                            width: 232,
                            order: true
                        },
                        {
                            label: "NIF",
                            value: "nif",
                            order: false
                        },
                        {
                            label: "Cidade",
                            value: "city",
                            width: 170,
                            order: true
                        },
                        {
                            label: "Distrito",
                            value: "district",
                            width: 170,
                            order: true
                        },
                        {
                            label: "Morada",
                            value: "address",
                            width: 400,
                            order: false
                        },
                        {
                            label: "Código Postal",
                            value: "postalCode",
                            width: 147
                        },
                        {
                            label: "Factoring",
                            value: "factoring",
                            width: 147,
                            order: false
                        },
                        {
                            label: "Contacto",
                            value: "contact",
                            width: 228
                        }
                    ]}
                    rows={clients.rows.map((client) => {
                        const contactToShow = (!!client.contacts[0] && !!client.contacts[0].phone ? client.contacts[0].phone : "") + (!!client.contacts[0] && !!client.contacts[0].phone && !!client.contacts[0].email ? " / " : "") + (!!client.contacts[0] && !!client.contacts[0].email ? client.contacts[0].email : "");
                        return ({
                            name: <Components.Block row center>
                                <Components.Block mr={12}>
                                    <Components.Button type="button" icon="Edit" size="squareSmall" color="empty" onClick={() => { setClientId(client.id); setModalUpdateOpen(true) }} />
                                </Components.Block>
                                <Components.Block>
                                    <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100} title={client.name}>{client.name}</Components.Text>
                                </Components.Block>
                            </Components.Block>,
                            nif: client.nif,
                            city: client.city,
                            district: client.district,
                            address: <Components.Block>
                                <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100} title={client.address}>{client.address}</Components.Text>
                            </Components.Block>,
                            postalCode: client.postalCode,
                            factoring: !!client.factoring && client.factoring.name,
                            contact: <Components.Block column>
                                <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100} title={!!client.contacts[0] && client.contacts[0].name}>{!!client.contacts[0] && client.contacts[0].name}</Components.Text>
                                <Components.Text size={11} height={16} color={MediStrapProperties.colors.grey60} title={contactToShow}>{contactToShow}</Components.Text>
                            </Components.Block>
                        })
                    })}
                />
            </Components.Block>
            <ModalCreate
                onRequestClose={() => setModalCreateOpen(false)}
                onCreate={() => requestClients({ currentPage: 1 })}
                isOpen={isModalCreateOpen}
                factorings={factorings}
            />

            <ModalUpdate
                onRequestClose={() => {
                    setClientId(null);
                    setModalUpdateOpen(false);
                }}
                onUpdate={(updatedClient) => {
                    const index = clients.rows.findIndex((item) => item.id == updatedClient.id);
                    clients.rows[index] = updatedClient;
                    setClients({ ...clients });
                }}
                onDelete={() => {
                    requestClients();
                }}
                isOpen={isModalUpdateOpen}
                factorings={factorings}
                clientId={clientId}
            />

            <ModalFilter
                onRequestClose={() => setFiltersOpen(false)}
                isOpen={isFiltersOpen}
                values={filters}
                onClickApply={(factorings, district, city) => {
                    setFilters({
                        factorings,
                        district,
                        city
                    });
                }}
                factorings={factorings}
            />

            <Components.ActionPanel1
                isOpen={isActionPanelDeleteOpen}
                onRequestClose={() => setActionPanelDeleteOpen(false)}
                title="Eliminar contacto"
                description="Esta acção irá eliminar definitavamente todos os dados associados ao contacto, pretende mesmo assim continuar?"
                confirmButtonProps={{
                    label: "Cancelar",
                    onClick: () => setActionPanelDeleteOpen(false)
                }}
                cancelButtonProps={{
                    label: "Eliminar",
                    icon: "Trash",
                    onClick: () => setActionPanelDeleteOpen(false)
                }}
            >

            </Components.ActionPanel1>
        </>
    );
}

export default Clients;
