import React from "react";
import { Components, MediStrap } from "../../../MediStrap";

const MediStrapProperties = MediStrap.getProperties();


function AccordionContractCycle({ name, anchor, errors, renovationPeriodUnits, contractEndActions }) {
    return (
        <Components.Portlet anchor={anchor} noInnerPadding icon="Cicle" title="Ciclo de contrato">
            <Components.AccordionItem
                key={0}
                index={0}
                name={name}
                custom={
                    <Components.Block row>
                        <Components.Block middle mr={12}>
                            <Components.Input error={errors[`${name}.type`]} Component={Components.RadioInput} name={`${name}.type`} radioValue="FIXED_WITH_RENOVATION_PERIOD" />
                        </Components.Block>
                        <Components.Block>
                            <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100}>Fixo</Components.Text>
                            <Components.Text size={11} height={16} color={MediStrapProperties.colors.grey60}>Com período de renovação</Components.Text>
                        </Components.Block>
                    </Components.Block>
                }
            >
                <Components.FormGroup title="Duração do ciclo">
                    <Components.Block row>
                        <Components.Block width={168} mr={20}>
                            <Components.Input error={errors[`${name}.startDate`]} Component={Components.DateInput} name={`${name}.startDate`} label="Data de ínicio*" />
                        </Components.Block>
                        <Components.Block width={168}>
                            <Components.Input error={errors[`${name}.endDate`]} Component={Components.DateInput} name={`${name}.endDate`} label="Data de fim*" />
                        </Components.Block>
                        {/*<Components.Block ml={8} mr={8} pt={20} mt={16}>
                            <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100}>ou</Components.Text>
                        </Components.Block>
                        <Components.Block width={151}>
                            <Components.ValueWithSelectInput disabled={!!value ? !value.startDate : true} name={`${name}.duration`} label="Duração*" placeholder="Meses" options={renovationPeriodUnits} />
            </Components.Block>*/}
                    </Components.Block>
                </Components.FormGroup>
                <Components.FormGroup>
                    <Components.Block row center>
                        <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100} width={177} mr={31}>Período de prolongamento</Components.Text>
                        <Components.Block width={151}>
                            <Components.Input Component={Components.ValueWithSelectInput} name={`${name}.extensionPeriod`} placeholder="Meses" options={renovationPeriodUnits} />
                        </Components.Block>
                    </Components.Block>
                </Components.FormGroup>
                <Components.FormGroup>
                    <Components.Block row center>
                        <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100} width={141} mr={67}>Notificação do termo</Components.Text>
                        <Components.Block width={151}>
                            <Components.Input Component={Components.ValueWithSelectInput} name={`${name}.termNotification`} placeholder="Meses" options={renovationPeriodUnits} />
                        </Components.Block>
                    </Components.Block>
                </Components.FormGroup>
                <Components.FormGroup>
                    <Components.Block row center>
                        <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100} width={140} mr={68}>Ação fim do contrato</Components.Text>
                        <Components.Block width={334}>
                            <Components.Input Component={Components.DropdownInput} name={`${name}.endAction`} placeholder="Selecionar ação" options={contractEndActions} />
                        </Components.Block>
                    </Components.Block>
                </Components.FormGroup>
            </Components.AccordionItem>
            <Components.AccordionItem
                key={1}
                index={1}
                name={name}
                custom={
                    <Components.Block row>
                        <Components.Block middle mr={12}>
                            <Components.Input error={errors[`${name}.type`]} Component={Components.RadioInput} name={`${name}.type`} initialValue="FIXED_WITH_RENOVATION_PERIOD" radioValue="FIXED" />
                        </Components.Block>
                        <Components.Block>
                            <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100}>Fixo</Components.Text>
                            <Components.Text size={11} height={16} color={MediStrapProperties.colors.grey60}>Sem período de renovação</Components.Text>
                        </Components.Block>
                    </Components.Block>
                }
            >
                <Components.FormGroup title="Duração do ciclo">
                    <Components.Block row>
                        <Components.Block width={168} mr={20}>
                            <Components.Input error={errors[`${name}.startDate`]} Component={Components.DateInput} name={`${name}.startDate`} label="Data de ínicio*" />
                        </Components.Block>
                        <Components.Block width={168}>
                            <Components.Input error={errors[`${name}.endDate`]} Component={Components.DateInput} name={`${name}.endDate`} label="Data de fim*" />
                        </Components.Block>
                        {/*<Components.Block ml={8} mr={8} pt={20} mr={16}>
                            <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100}>ou</Components.Text>
                        </Components.Block>
                        <Components.Block width={151}>
                            <Components.Input Component={Components.ValueWithSelectInput} name={`${name}.duration`} label="Duração*" placeholder="Meses" options={renovationPeriodUnits} />
            </Components.Block>*/}
                    </Components.Block>
                </Components.FormGroup>
                <Components.FormGroup>
                    <Components.Block row center>
                        <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100} width={141} mr={67}>Notificação do termo</Components.Text>
                        <Components.Block width={151}>
                            <Components.Input Component={Components.ValueWithSelectInput} name={`${name}.termNotification`} placeholder="Meses" options={renovationPeriodUnits} />
                        </Components.Block>
                    </Components.Block>
                </Components.FormGroup>
                <Components.FormGroup>
                    <Components.Block row center>
                        <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100} width={140} mr={68}>Ação fim do contrato</Components.Text>
                        <Components.Block width={334}>
                            <Components.Input Component={Components.DropdownInput} name={`${name}.endAction`} placeholder="Selecionar ação" options={contractEndActions} />
                        </Components.Block>
                    </Components.Block>
                </Components.FormGroup>
            </Components.AccordionItem>
            <Components.AccordionItem
                key={2}
                index={2}
                name={name}
                custom={
                    <Components.Block row>
                        <Components.Block middle mr={12}>
                            <Components.Input error={errors[`${name}.type`]} Component={Components.RadioInput} name={`${name}.type`} initialValue="FIXED_WITH_RENOVATION_PERIOD" radioValue="OPEN" />
                        </Components.Block>
                        <Components.Block>
                            <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100}>Em Aberto</Components.Text>
                        </Components.Block>
                    </Components.Block>
                }
            >
                <Components.FormGroup title="Duração do ciclo">
                    <Components.Block row>
                        <Components.Block width={168} mr={20}>
                            <Components.Input error={errors[`${name}.startDate`]} Component={Components.DateInput} name={`${name}.startDate`} label="Data de ínicio*" />
                        </Components.Block>
                    </Components.Block>
                </Components.FormGroup>
            </Components.AccordionItem>
        </Components.Portlet>
    );
}

export default AccordionContractCycle;
