import { useRef, useState } from "react";
import { Components, MediStrap } from "../../../../MediStrap";

const MediStrapProperties = MediStrap.getProperties();

function ModalFilters({ onRequestClose, isOpen, onClickApply, factorings, contractStatusCounts, values }) {
    const contractsFilterFormRef = useRef(null);
    const [numberOfFiltersUsed, setNumberOfFiltersUsed] = useState(0);

    return (
        <Components.Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            title="Filtrar contratos"
            leftActions={
                <Components.Block>
                    <Components.Text size={12} height={16} color={MediStrapProperties.colors.grey100}>Selecionados</Components.Text>
                    <Components.Block row>
                        <Components.Text size={14} height={24} color={MediStrapProperties.colors.brand100}>({numberOfFiltersUsed})</Components.Text>
                        <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey50} ml={4}>Filtros</Components.Text>
                    </Components.Block>
                </Components.Block>
            }
            rightActions={
                <Components.Block row>
                    <Components.Block>
                        <Components.Button icon="Trash" label="Limpar" color="empty" size="medium"
                            onClick={() => {
                                contractsFilterFormRef.current.setFieldValue('state', undefined);
                                contractsFilterFormRef.current.setFieldValue('value', undefined);
                                contractsFilterFormRef.current.setFieldValue('factorings', undefined);
                                contractsFilterFormRef.current.setFieldValue('frequencyPeriod', undefined);
                                contractsFilterFormRef.current.setFieldValue('startDate', undefined);
                                contractsFilterFormRef.current.setFieldValue('endDate', undefined);
                            }}
                        />
                    </Components.Block>
                    <Components.Block ml={12}>
                        <Components.Button label="Aplicar" color="brand" size="medium" onClick={() => contractsFilterFormRef.current.handleSubmit()} />
                    </Components.Block>
                </Components.Block>
            }
        >
            <Components.Form
                initialValues={values}
                innerRef={contractsFilterFormRef}
                onSubmit={(values) => {
                    const factorings = !!values.factorings ? values.factorings.map((item) => item) : undefined;
                    const totalAmount = !!values.value ? values.value : undefined;
                    const dateSearchType = !!values.frequencyPeriod ? values.frequencyPeriod : undefined;
                    const startDate = !!values.startDate ? values.startDate : undefined;
                    const endDate = !!values.endDate ? values.endDate : undefined;
                    const status = !!values.state ? [
                        !!values.state.active ? "ACTIVE" : undefined,
                        !!values.state.inRenovation ? "RENEWING" : undefined,
                        !!values.state.deactivated ? "DEACTIVATED" : undefined,
                        !!values.state.expireInOneMonth ? "EXPIRING_ONE_MONTH" : undefined,
                        !!values.state.expired ? "EXPIRED" : undefined
                    ] : undefined;

                    onClickApply(factorings, totalAmount, dateSearchType, startDate, endDate, status);
                    onRequestClose();
                }}
            >
                {({
                    values,
                    errors,
                    handleSubmit,
                }) => {
                    setNumberOfFiltersUsed((Object.keys(values).filter((key) => !!values[key] && (Array.isArray(values[key]) ? values[key].length > 0 : true))).length);
                    return (
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <Components.FormGroup title="Faturação">
                                <Components.Block width={334}>
                                    <Components.Input Component={Components.DropdownMultiInput} name="factorings" label="Factoring" placeholder="Selecione banco(s)" options={factorings} />
                                </Components.Block>
                                <Components.Block row>
                                    {/*<Components.Block width={232} pr={10}>
                                            <Components.Input Component={Components.DropdownInput} name="frequency" label="Periodicidade" placeholder="Selecione periodicidade" />
                        </Components.Block>*/}
                                    <Components.Block width={304}>
                                        <Components.Input Component={Components.SliderInput} name="value" label="Valor" min={0} max={50000} symbol="€" />
                                    </Components.Block>
                                </Components.Block>
                            </Components.FormGroup>
                            <Components.FormGroup title="Período de">
                                <Components.Block row>
                                    <Components.Block pr={10}>
                                        <Components.Input Component={Components.RadioInput} name="frequencyPeriod" radioValue="BILLING" label="Faturação" />
                                    </Components.Block>
                                    <Components.Block pr={10}>
                                        <Components.Input Component={Components.RadioInput} name="frequencyPeriod" radioValue="RENEWAL" label="Renovação" />
                                    </Components.Block>
                                    <Components.Block>
                                        <Components.Input Component={Components.RadioInput} name="frequencyPeriod" radioValue="CONTRACT_START" label="Início" />
                                    </Components.Block>
                                </Components.Block>
                                <Components.Block row>
                                    <Components.Block width={168} pr={10}>
                                        <Components.Input Component={Components.DateInput} name="startDate" label="Data início" />
                                    </Components.Block>
                                    <Components.Block width={168} pl={10}>
                                        <Components.Input Component={Components.DateInput} name="endDate" label="Data fim" />
                                    </Components.Block>
                                </Components.Block>
                            </Components.FormGroup>
                            <Components.FormGroup title="Estado">
                                <Components.Block row wrap>
                                    <Components.Block width="50%" pr={10}>
                                        <Components.Input Component={Components.CheckInput} name="state.active" label="Ativo" rightLabel={String(contractStatusCounts.active)} />
                                    </Components.Block>
                                    <Components.Block width="50%" pl={10}>
                                        <Components.Input Component={Components.CheckInput} name="state.inRenovation" label="Em renovação" rightLabel={String(contractStatusCounts.renewing)} />
                                    </Components.Block>
                                    <Components.Block width="50%" pr={10} mt={8}>
                                        <Components.Input Component={Components.CheckInput} name="state.deactivated" label="Desativado" rightLabel={String(contractStatusCounts.deactivated)} />
                                    </Components.Block>
                                    <Components.Block width="50%" pl={10} mt={8}>
                                        <Components.Input Component={Components.CheckInput} name="state.expireInOneMonth" label="Expiram - 1 mês" rightLabel={String(contractStatusCounts.expiringInOneMonth)} />
                                    </Components.Block>
                                    <Components.Block width="50%" pr={10} mt={8}>
                                        <Components.Input Component={Components.CheckInput} name="state.expired" label="Expirado" rightLabel={String(contractStatusCounts.expired)} />
                                    </Components.Block>
                                </Components.Block>
                            </Components.FormGroup>
                        </form>
                    )
                }}
            </Components.Form>
        </Components.Modal>
    )
};

export default ModalFilters;