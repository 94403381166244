import { useRef, useState } from "react";
import { Components, MediStrap } from "../../../../MediStrap";
import { CITIES, DISTRICTS } from "../../../../utils/commonData";

const MediStrapProperties = MediStrap.getProperties();

const districtOptions = DISTRICTS;

const cityOptions = CITIES;

function ModalFilters({ isOpen, onRequestClose, onClickApply, values, factorings }) {
    const clientsFilterFormRef = useRef(null);
    const [numberOfFiltersUsed, setNumberOfFiltersUsed] = useState(0);

    return (
        <Components.Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            title="Filtrar clientes"
            leftActions={
                <Components.Block>
                    <Components.Text size={12} height={16} color={MediStrapProperties.colors.grey100}>Selecionados</Components.Text>
                    <Components.Block row>
                        <Components.Text size={14} height={24} color={MediStrapProperties.colors.brand100}>({numberOfFiltersUsed})</Components.Text>
                        <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey50} ml={4}>Filtros</Components.Text>
                    </Components.Block>
                </Components.Block>
            }
            rightActions={
                <Components.Block row>
                    <Components.Block>
                        <Components.Button icon="Trash" label="Limpar" color="empty" size="medium" onClick={() => { clientsFilterFormRef.current.setFieldValue('city', null); clientsFilterFormRef.current.setFieldValue('district', null); clientsFilterFormRef.current.setFieldValue('factorings', []) }} />
                    </Components.Block>
                    <Components.Block ml={12}>
                        <Components.Button label="Aplicar" color="brand" size="medium" onClick={() => { clientsFilterFormRef.current.handleSubmit() }} />
                    </Components.Block>
                </Components.Block>
            }
        >
            <Components.Form
                initialValues={values}
                onSubmit={(values) => {
                    const factorings = !!values.factorings ? values.factorings.map((item) => item) : [];
                    const district = !!values.district ? values.district : null;
                    const city = !!values.city ? values.city : null;
                    onClickApply(factorings, district, city);
                    onRequestClose();
                }}
                innerRef={clientsFilterFormRef}
            >
                {({
                    values,
                    handleSubmit,
                    setFieldValue
                }) => {
                    !!values.city && !cityOptions.find((item) => item.district == values.district.value).cities.find((item) => item.value == values.city.value) && setFieldValue('city', null);
                    setNumberOfFiltersUsed((Object.keys(values).filter((key) => !!values[key] && (Array.isArray(values[key]) ? values[key].length > 0 : true))).length);
                    return (
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <Components.FormGroup title="Faturação">
                                <Components.InputGroup width={334}>
                                    <Components.Input Component={Components.DropdownMultiInput} name="factorings" label="Factoring" placeholder="Selecione banco(s)" options={factorings.map((factoring) => ({ label: factoring.name, value: factoring.id }))} />
                                </Components.InputGroup>
                            </Components.FormGroup>
                            <Components.FormGroup title="Localização">
                                <Components.InputGroup>
                                    <Components.Block row>
                                        <Components.Block width="50%" pr={10}>
                                            <Components.Input Component={Components.DropdownInput} name="district" label="Distrito" placeholder="Selecione distrito" options={districtOptions} />
                                        </Components.Block>
                                        <Components.Block width="50%" pl={10}>
                                            <Components.Input Component={Components.DropdownInput} name="city" label="Cidade" placeholder="Selecione cidade" options={!!values.district ? cityOptions.find((item) => item.district == values.district.value).cities : []} />
                                        </Components.Block>
                                    </Components.Block>
                                </Components.InputGroup>
                                <Components.Block height={20} />
                            </Components.FormGroup>
                        </form>
                    )
                }}
            </Components.Form>
        </Components.Modal>
    );
}

export default ModalFilters;