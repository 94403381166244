import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useEffect, useRef, useState } from "react";
import * as yup from 'yup';
import { Components, MediStrap } from "../../../../../MediStrap";
import { MUTATION_CATEGORY_DELETE, MUTATION_CATEGORY_UPDATE } from "../../../graphql/mutations";
import { QUERY_CATEGORY } from "../../../graphql/queries";

const MediStrapProperties = MediStrap.getProperties();

function ModalUpdate({ onRequestClose, onUpdate, onDelete, isOpen, categoryId }) {
    const updateFormRef = useRef(null);

    const [category, setCategory] = useState(null);

    const [queryCategory] = useLazyQuery(
        QUERY_CATEGORY,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.category) {
                    setCategory(data.category);
                }
            }
        }
    );

    const requestCategory = (params = {}) => {
        const { id } = params;

        queryCategory({
            variables: {
                id
            }
        });
    }

    useEffect(() => {
        if (!!categoryId) {
            requestCategory({ id: categoryId });
        }
    }, [categoryId]);

    const [mutationCategoryUpdate, { loading: loadingCategoryUpdate }] = useMutation(
        MUTATION_CATEGORY_UPDATE,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.categoryUpdate) {
                    onUpdate(data.categoryUpdate);
                    onRequestClose();
                }
            }
        }
    );

    const updateCategory = (params = {}) => {
        const { name } = params;

        mutationCategoryUpdate({
            variables: {
                id: category.id,
                name
            }
        });
    }

    const [mutationCategoryDelete] = useMutation(
        MUTATION_CATEGORY_DELETE,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.categoryDelete) {
                    onDelete();
                    onRequestClose();
                }
            }
        }
    );

    const deleteCategory = () => {
        mutationCategoryDelete({
            variables: {
                id: category.id
            }
        });
    }


    useEffect(() => {
        if (!isOpen) {
            setCategory(null);
        }
    }, [isOpen])

    return (
        <Components.Modal
            title="Editar categoria"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            rightActions={
                <Components.Block row center>
                    <Components.Block>
                        <Components.Button type="submit" loading={loadingCategoryUpdate} size="medium" label="Editar categoria" color="brand" onClick={() => updateFormRef.current.handleSubmit()} />
                    </Components.Block>
                </Components.Block>
            }
        >
            {!!category ?
                <>
                    <Components.Form
                        innerRef={updateFormRef}
                        initialValues={{
                            name: category.name
                        }}
                        schema={yup.object().shape({
                            name: yup.string().required()
                        })}
                        onSubmit={(values) => {
                            updateCategory(values);
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Components.FormGroup title="Geral">
                                        <Components.Block width={334}>
                                            <Components.Input error={errors.name} Component={Components.TextInput} name="name" label="Categoria" placeholder="Nome da categoria" />
                                        </Components.Block>
                                    </Components.FormGroup>
                                    <Components.FormGroup>
                                        <Components.DeleteLine
                                            title="Eliminar categoria"
                                            description="Esta ação irá eliminar a categoria permanentemente "
                                            onClickDelete={() => { deleteCategory({ id: category.id }); onRequestClose(); }}
                                        />
                                    </Components.FormGroup>
                                </form>
                            )
                        }}
                    </Components.Form>
                </>
                :
                <Components.Block center middle>
                    <Components.Loading color={MediStrapProperties.colors.brand100} />
                </Components.Block>}
        </Components.Modal>
    );
}

export default ModalUpdate;
