import gql from "graphql-tag";

export const MUTATION_REMINDER_CREATE = gql`
    mutation reminderCreate($contract: ID!, $motive: ReminderMotive!, $date: String!) {
        reminderCreate (contract: $contract, motive: $motive, date: $date){
            id
            motive
            date
            contract {
                id
                status
                code
                client {
                    id
                    name
                }
            }
        }
    }
`;
export const MUTATION_REMINDER_UPDATE = gql`
    mutation reminderUpdate($id: ID!, $contract: ID!, $motive: ReminderMotive!, $date: String!) {
        reminderUpdate (id: $id, contract: $contract, motive: $motive, date: $date){
            id
            motive
            date
            contract {
                id
                status
                code
                client {
                    id
                    name
                }
            }
        }
    }
`;
export const MUTATION_REMINDER_DELETE = gql`
    mutation reminderDelete($id: ID!) {
        reminderDelete (id: $id) {
            id
            motive
            date
            contract {
                id
                status
                code
                client {
                    id
                    name
                }
            }
        }
    }
`;