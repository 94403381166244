import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

@font-face {
  font-family: MedistrapRegular;
  src: url(${process.env.PUBLIC_URL}/fonts/AvertaStd-Regular.ttf);
}

@font-face {
  font-family: MedistrapLight;
  src: url(${process.env.PUBLIC_URL}/fonts/AvertaStd-Light.ttf);
}

@font-face {
  font-family: MedistrapSemiBold;
  src: url(${process.env.PUBLIC_URL}/fonts/AvertaStd-Semibold.ttf);
}

@font-face {
  font-family: MedistrapBold;
  src: url(${process.env.PUBLIC_URL}/fonts/AvertaStd-Bold.ttf);
}

@font-face {
  font-family: MedistrapIcons;
  src: url(${process.env.PUBLIC_URL}/fonts/icomoon.ttf);
  src: url(${process.env.PUBLIC_URL}/fonts/icomoon.eot?kwnwj8);
  src: url(${process.env.PUBLIC_URL}/fonts/icomoon.eot?kwnwj8#iefix) format('embedded-opentype'),
    url(${process.env.PUBLIC_URL}/fonts/icomoon.ttf?kwnwj8) format('truetype'),
    url(${process.env.PUBLIC_URL}/fonts/icomoon.woff?kwnwj8) format('woff'),
    url(${process.env.PUBLIC_URL}/fonts/icomoon.svg?kwnwj8#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'], [class*=" icon-"] {
    font-size: 16px;
  }

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: 'MedistrapSemiBold', sans-serif;
}

#root, html, body {
  height: 100%;
  font-size: 14px;
}

body {
  font-family: 'MedistrapSemiBold', sans-serif;
  -webkit-font-smoothing: antialiased !important;
}

.slide-pane__content {
  margin: 0;
  padding: 0;
}

.slide-pane_from_bottom {
    height: 100vh;
    margin-top: 0;
}
`;