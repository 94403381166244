import React, { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { Redirect, Route, Switch } from "react-router-dom";
import { Components, MediStrap } from '../../MediStrap';
import { Clients, Contracts, Home, Settings } from '../../pages';
import Accounts from '../../pages/Settings/Tabs/Accounts';
import Categories from '../../pages/Settings/Tabs/Categories';
import Factoring from '../../pages/Settings/Tabs/Factoring';
import Licenses from '../../pages/Settings/Tabs/Licenses';
import checkAuth from '../../utils/checkAuth';
import { store } from '../../utils/store';

const MediStrapProperties = MediStrap.getProperties();

function Dashboard() {
    const [cookies, setCookie, removeCookie] = useCookies(["backoffice_session"]);
    const { user } = useContext(store);

    return (
        <Components.Block flex width="100%" style={{ overflow: "hidden", minWidth: "1366px" }}>
            <Components.Header1
                menu={[
                    { to: "/dashboard/home", icon: "Dashboard", label: "Dashboard" },
                    { to: "/dashboard/clients", icon: "People", label: "Clientes" },
                    { to: "/dashboard/contracts", icon: "Archive", label: "Contractos" },
                    { to: "/dashboard/settings", icon: "Edit", label: "Definições" }
                ]}
                name={user.name}
                position={user.position}
                actions={<Components.Button type="button" label="Logout" size="big" onClick={() => {
                    removeCookie("backoffice_session", { path: "/" });
                    window.location.href = '/';
                    return false;
                }} />}
            />
            <Components.Block width="100%" height="calc(100% - 72px)" color={MediStrapProperties.colors.grey5} pt={32} pb={32} pl={32} pr={32} style={{ overflow: "hidden", position: "relative", minHeight: window.innerHeight - 72 }}>
                <Switch>
                    <Route exact path="/dashboard/home" component={Home} />
                    <Route exact path="/dashboard/clients" component={Clients} />
                    <Route exact path="/dashboard/contracts" component={Contracts} />
                    <Route exact path="/dashboard/settings" component={Settings} />
                    <Route path="/dashboard/settings/accounts" component={Accounts} />
                    <Route path="/dashboard/settings/categories" component={Categories} />
                    <Route path="/dashboard/settings/licensing" component={Licenses} />
                    <Route path="/dashboard/settings/factoring" component={Factoring} />
                    <Route
                        exact
                        path="/dashboard"
                        render={() => <Redirect to="/dashboard/home" />}
                    />
                </Switch>
            </Components.Block>
        </Components.Block>
    );
}

export default checkAuth(Dashboard);