import gql from "graphql-tag";

export const QUERY_CLIENTS = gql`
    query clients($perPage: Int, $currentPage: Int, $search: String, $orderBy: ClientsOrderBy, $factorings: [ID], $district: String, $city: String) {
        clients (perPage: $perPage, currentPage: $currentPage, search: $search, orderBy: $orderBy, factorings: $factorings, district: $district, city: $city){
            rows {
                id
                name
                nif
                address
                city
                district
                postalCode
                factoring {
                    id
                    name
                }
                contacts {
                    name
                    email
                    phone
                }
            }
            pagination {
                total
                perPage
                lastPage
                currentPage
                hasPreviousPage
                hasNextPage
            }
        }
    }
`;

export const QUERY_CLIENT = gql`
    query client($id: ID!) {
        client (id: $id){
            id
            name
            nif
            address
            city
            district
            postalCode
            factoring {
                id
                name
            }
            contacts {
                name
                email
                phone
            }
        }
    }
`;

export const QUERY_FACTORINGS = gql`
    query factorings($perPage: Int, $currentPage: Int) {
        factorings (perPage: $perPage, currentPage: $currentPage){
            rows {
                id
                name
            }
        }
    }
`;