import React from 'react';
import { Components } from "../../../../MediStrap";

const tabs = [
    { to: "/dashboard/settings/accounts", icon: "Lock", label: "Contas" },
    { to: "/dashboard/settings/categories", icon: "Categories", label: "Categorias de cliente" },
    { to: "/dashboard/settings/licensing", icon: "Licenses", label: "Licenciamentos" },
    { to: "/dashboard/settings/factoring", icon: "Euro", label: "Factoring" }
];

export default function TabMenuSettings() {
    return (
        <Components.TabMenu tabs={tabs} />
    )
}