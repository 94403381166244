import gql from "graphql-tag";

export const QUERY_CONTRACTS = gql`
    query contracts($perPage: Int, $currentPage: Int, $search: String, $orderBy: ContractsOrderBy, $filters: InputContractFilters) {
        contracts(perPage: $perPage, currentPage: $currentPage, search: $search, orderBy: $orderBy, filters: $filters){
            rows {
                id
                status
                code
                client {
                    id
                    name
                    factoring {
                        id
                        name
                    }
                }
                category {
                    id
                    name
                }
                description
                support
                contacts {
                    name
                    email
                    phone
                }
                files {
                    name
                    date
                    url
                }
                notes {
                    title
                    description
                }
                licenses {
                    id
                    name
                }
                billings {
                    id
                    amount
                    frequency
                    partitionType
                    partitionValue
                    startDate
                    endDate
                    description
                    reference
                    softwareCode
                }
                cycle {
                    id
                    type
                    startDate
                    endDate
                    extensionPeriod {
                        value
                        unit
                    }
                    termNotification {
                        value
                        unit
                    }
                    description
                    endAction
                }
                renewalDate
                startDate
                totalAmount
                yearlyAmount
                nextBilling
            }
            statusCount {
                active
                deactivated
                expired
                renewing
                expiringInOneMonth
            }
            pagination {
                total
                perPage
                lastPage
                currentPage
                hasPreviousPage
                hasNextPage
            }
        }
    }
`;

export const QUERY_CONTRACT = gql`
    query contract($id: ID!) {
        contract(id: $id) {
            id
            status
            code
            client {
                id
                name
            }
            category {
                id
                name
            }
            description
            support
            contacts {
                name
                email
                phone
            }
            files {
                name
                date
                url
            }
            notes {
                title
                description
            }
            licenses {
                id
                name
            }
            billings {
                id
                amount
                frequency
                partitionType
                partitionValue
                startDate
                endDate
                description
                reference
                softwareCode
            }
            cycle {
                id
                type
                startDate
                endDate
                extensionPeriod {
                    value
                    unit
                }
                termNotification {
                    value
                    unit
                }
                description
                endAction
            }
        }
    }
`;


export const QUERY_EXTRA_DATA = gql`
    query extraData {
        clients (perPage: 1000, currentPage: 1){
            rows {
                value: id
                label: name
            }
        }
        categories (perPage: 1000, currentPage: 1){
            rows {
                value: id
                label: name
            }
        }
        factorings (perPage: 1000, currentPage: 1){
            rows {
                value: id
                label: name
            }
        }
    }
`;

export const QUERY_LICENSES = gql`
    query licenses($perPage: Int, $currentPage: Int, $search: String) {
        licenses (perPage: $perPage, currentPage: $currentPage, search: $search){
            rows {
                id
                name
            }
        }
    }
`;