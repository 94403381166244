import React from 'react';
import styled from 'styled-components';
import { Components, MediStrap } from "../../../../MediStrap";

const MediStrapProperties = MediStrap.getProperties();

export default function ContractCard(
    {
        label,
        iconColor,
        counter,
        counterDiff,
        ...props
    }
) {
    const counterDiffBackgroud = (counterDiff >= 0) ? MediStrapProperties.colors.brand10 : MediStrapProperties.colors.alert10;
    const counterDiffBorder = (counterDiff >= 0) ? MediStrapProperties.colors.brand30 : MediStrapProperties.colors.alert30;
    const counterDiffTextColor = (counterDiff >= 0) ? MediStrapProperties.colors.brand100 : MediStrapProperties.colors.alert100;
    const counterDiffPlusSign = (counterDiff >= 0);

    return (
        <Card
            height={80}
            width={212}
            pt={16}
            pb={16}
            pl={24}
            pr={24}
            mb={16}
            color={MediStrapProperties.colors.white100}
            br={MediStrapProperties.style.borderRadius}
            row
            space="between"
        >
            <Components.Block>
                <Components.Block height={16} row center>
                    <Components.Block height={8} width={8} br={5} color={iconColor} mr={6} />
                    <Components.Text
                        color={MediStrapProperties.colors.grey60}
                        size={10}
                        height={16}
                        bold
                    >{label}</Components.Text>
                </Components.Block>
                <Components.Text
                    height={32}
                    size={24}
                >{counter.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                })}</Components.Text>
            </Components.Block>
            {/*<CounterDiff
                height={24}
                br={MediStrapProperties.style.borderRadius}
                pt={4}
                pb={4}
                pl={8}
                pr={8}
                color={counterDiffBackgroud}
                counterDiffBorder = {counterDiffBorder}
            >
                <Components.Text size={11} height={16} color={counterDiffTextColor}>{counterDiffPlusSign && "+"}{counterDiff}</Components.Text>
            </CounterDiff>*/}
        </Card>
    )
}

const CounterDiff = styled(Components.Block)`
    border: 1px ${({counterDiffBorder}) => counterDiffBorder} solid;
`
const Card = styled(Components.Block)`
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
`