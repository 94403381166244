import { useMutation } from "@apollo/react-hooks";
import { useRef } from "react";
import * as yup from 'yup';
import { Components, MediStrap } from "../../../../../MediStrap";
import { REMINDER_MOTIVES } from "../../../../../utils/commonData";
import { MUTATION_REMINDER_CREATE } from "../../../graphql/mutations";
import InputContract from "../InputContract";

const MediStrapProperties = MediStrap.getProperties();

const reminderMotives = REMINDER_MOTIVES;

function ModalCreate({ onRequestClose, onCreate, isOpen, values = {} }) {
    const createFormRef = useRef(null);

    const [mutationReminderCreate, { loading: loadingCreateReminder }] = useMutation(
        MUTATION_REMINDER_CREATE,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.reminderCreate) {
                    onCreate();
                    onRequestClose();
                }
            }
        }
    );

    const createReminder = (params = {}) => {
        const { contract, motive, date } = params;

        mutationReminderCreate({
            variables: {
                contract,
                motive,
                date
            }
        });
    }

    return (
        <Components.Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            title="Criar lembrete"
            rightActions={
                <Components.Block>
                    <Components.Block>
                        <Components.Button type="submit" label="Criar lembrete" loading={loadingCreateReminder} color="brand" size="medium" onClick={() => createFormRef.current.handleSubmit()} />
                    </Components.Block>
                </Components.Block>
            }
        >
            <Components.Form
                innerRef={createFormRef}
                initialValues={values}
                schema={yup.object().shape({
                    contract: yup.object().required().nullable(),
                    motive: yup.object().required(),
                    date: yup.date().required().nullable().transform((currentValue, originalValue) => originalValue === "" ? null : currentValue)
                })}
                onSubmit={(values) => {
                    createReminder({ contract: values.contract.id, motive: values.motive.value, date: values.date });
                }}
            >
                {({
                    values,
                    errors,
                    handleSubmit,
                }) => {
                    return (
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <Components.FormGroup title="Contrato">
                                <Components.Input error={errors.contract} Component={InputContract} name="contract" />
                            </Components.FormGroup>
                            <Components.FormGroup title="Motivo do lembrete">
                                <Components.Block width={334}>
                                    <Components.Input error={errors.motive} Component={Components.DropdownInput} name="motive" label="Motivo" placeholder="Seleccione motivo" options={reminderMotives} />
                                </Components.Block>
                            </Components.FormGroup>
                            <Components.FormGroup title="Data do lembrete">
                                <Components.Block width={168}>
                                    <Components.Input error={errors.date} Component={Components.DateInput} name="date" label="Data" placeholder="DD / MM / AAAA" />
                                </Components.Block>
                            </Components.FormGroup>
                        </form>
                    )
                }}
            </Components.Form>
        </Components.Modal>
    )
}

export default ModalCreate;