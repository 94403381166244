import React from 'react';
import styled from 'styled-components';
import { Components, MediStrap } from '../../../../../../MediStrap/';
import { EVENT_DISPLAYS } from '../../../../../../utils/commonData';

const MediStrapProperties = MediStrap.getProperties();

export default function Day(
    {
        date,
        currentMonth,
        isEventModalOpen,
        setEventModalOpen,
        selectedDay,
        setSelectedDay,
        setEventsOfDay,
        events
    }) {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let dayColor = MediStrapProperties.colors.grey100;
    let circleColor = null;
    if (date.getMonth() != currentMonth) {
        dayColor = MediStrapProperties.colors.grey50;
    }
    if (date.valueOf() == today.valueOf()) {
        circleColor = MediStrapProperties.colors.grey10;
    }
    if (!!selectedDay && date.valueOf() == selectedDay.valueOf()) {
        dayColor = MediStrapProperties.colors.brand100;
        circleColor = MediStrapProperties.colors.brand10;
    }
    return (
        <Components.Block flex center height={80} mr={7} ml={7} pt={20} pb={20}>
            <Circle
                pointer
                center
                height={40}
                width={40}
                br={40}
                color={circleColor}
                pt={8}
                pb={8}
                onClick={() => { setSelectedDay(date); setEventsOfDay(date); setEventModalOpen(date); }}
                hasBorder={!!selectedDay && date.valueOf() == selectedDay.valueOf()}
            >
                <Components.Text pointer size={16} height={24} color={dayColor}>{date.getDate()}</Components.Text>
            </Circle>
            <Components.Block row center flex space="between" mt={4}>
                {loadEvents(date, events)}
            </Components.Block>
        </Components.Block>
    );
}

function loadEvents(date, events) {
    const dayEvents = events.filter((item) => item.date.valueOf() == date.valueOf());
    if (typeof dayEvents !== "undefined" && dayEvents.length > 0) {
        if (dayEvents.length > 2) {
            return (
                <>
                    <Components.Block height={8} width={8} br={5} color={EVENT_DISPLAYS[dayEvents[0].type].color} mr={4} />
                    <Components.Text height={16} size={10} color={MediStrapProperties.colors.grey50}>+{dayEvents.length - 1}</Components.Text>
                </>
            );
        }
        return (
            <Components.Block row mr={-4} ml={-4}>
                {dayEvents.map((item, key) => {
                    return (
                        <Components.Block height={8} width={8} br={5} color={EVENT_DISPLAYS[item.type].color} key={key} mr={4} ml={4} />
                    );
                })}
            </Components.Block>
        );
    }
}

const Circle = styled(Components.Block)`
    &:hover {
        background-color: ${MediStrapProperties.colors.brand10} !important;
        span {
            color: ${MediStrapProperties.colors.brand100} !important;
        }
    }
    border: ${({ hasBorder }) => hasBorder ? "1px" : "0px"} solid ${MediStrapProperties.colors.brand30};
`