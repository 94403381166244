import { useLazyQuery } from "@apollo/react-hooks";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Components, MediStrap } from "../../../../../MediStrap";
import { QUERY_CONTRACTS } from "../../../graphql/queries";

const MediStrapProperties = MediStrap.getProperties();

function InputContract({ value = null, onChangeValue, error }) {
    const [search, setSearch] = useState('');
    const [contracts, setContracts] = useState([]);
    const [isContractModalOpen, setContractModalOpen] = useState(false);
    const [selectedContract, setSelectedContract] = useState(null);

    const [queryContracts] = useLazyQuery(
        QUERY_CONTRACTS,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.contracts) {
                    setContracts(data.contracts.rows);
                }
            }
        }
    );

    const requestContracts = (params = {}) => {
        const { perPage, currentPage } = params;

        queryContracts({
            variables: {
                perPage: 6,
                currentPage: 1,
                search: search !== "" ? search : undefined,
            }
        });
    }

    useEffect(() => {
        requestContracts();
    }, [search]);
    
    return (
        <>
            {!!value ?
                <Components.Block
                    height={72}
                    color={MediStrapProperties.colors.white100}
                    row
                    center
                    space="between"
                    pt={24}
                    pb={24}
                    pl={24}
                    pr={24}
                    mb={-24}
                >
                    <Components.Text height={24} size={14}>{value.client.name}</Components.Text>
                    <Components.Block>
                        <Components.Button type="button" icon="Trash" color="empty" size="squareSmall" onClick={() => { setSelectedContract(null); onChangeValue(null); }} />
                    </Components.Block>
                </Components.Block>
                :
                <>
                    <Components.Button type="button" label="Adicionar Contrato" size="big" onClick={() => setContractModalOpen(true)} />
                    {!!error &&
                        <Components.Block mt={8}>
                            <Components.Text
                                size={11}
                                height={16}
                                color={MediStrapProperties.colors.alert100}
                            >
                                {error}
                            </Components.Text>
                        </Components.Block>
                    }
                </>
            }
            <Components.Modal
                isOpen={isContractModalOpen}
                onRequestClose={() => setContractModalOpen(false)}
                title="Selecionar Contrato"
                rightActions={
                    <Components.Block row>
                        <Components.Block>
                            <Components.Button icon="Trash" label="Limpar" color="empty" size="medium" onClick={() => setSelectedContract(null)} />
                        </Components.Block>
                        <Components.Block ml={12}>
                            <Components.Button label="Adicionar" color="brand" size="medium" onClick={() => { onChangeValue(selectedContract); setContractModalOpen(false) }} />
                        </Components.Block>
                    </Components.Block>
                }
                noInnerPadding
                width={444}
            >
                <Components.FormGroup>
                    <Components.Block center mt={24}>
                        <Components.Block width={396}>
                            <Components.TextInput name="contractSearch" placeholder="Procurar Contrato" icon="Search" value={search} onChangeValue={(value) => setSearch(value)} />
                        </Components.Block>
                    </Components.Block>
                    <ContractsBlock>
                        {contracts.map((item) => {
                            return (
                                <Item key={item.id} pl={24} pr={28} height={48} row space="between" center>
                                    <Components.Text size={14} height={24}>{item.client.name}</Components.Text>
                                    <Components.Block width={16}>
                                        <Components.RadioInput name="contract" radioValue={item}
                                            value={selectedContract}
                                            onChangeValue={(value) => {
                                                setSelectedContract(value);
                                            }}
                                        />
                                    </Components.Block>
                                </Item>
                            );
                        })}
                    </ContractsBlock>
                </Components.FormGroup>
            </Components.Modal>
        </>
    )
}

export default InputContract;

const Item = styled(Components.Block)`
    height: 48px;
    background-color: ${MediStrapProperties.colors.white100};
    border-top: 1px solid ${MediStrapProperties.colors.grey20};
`;

const ContractsBlock = styled(Components.Block)`
    max-height: 288px;
    overflow-y: auto;
`;