import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useEffect, useRef, useState } from "react";
import * as yup from 'yup';
import { Components, MediStrap } from "../../../../../MediStrap";
import { MUTATION_FACTORING_DELETE, MUTATION_FACTORING_UPDATE } from "../../../graphql/mutations";
import { QUERY_FACTORING } from "../../../graphql/queries";

const MediStrapProperties = MediStrap.getProperties();

function ModalUpdate({ onRequestClose, onUpdate, onDelete, isOpen, factoringId }) {
    const updateFormRef = useRef(null);

    const [factoring, setFactoring] = useState(null);

    const [queryFactoring] = useLazyQuery(
        QUERY_FACTORING,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.factoring) {
                    setFactoring(data.factoring);
                }
            }
        }
    );

    const requestFactoring = (params = {}) => {
        const { id } = params;

        queryFactoring({
            variables: {
                id
            }
        });
    }

    useEffect(() => {
        if (!!factoringId) {
            requestFactoring({ id: factoringId });
        }
    }, [factoringId]);

    const [mutationFactoringUpdate, { loading: loadingFactoringUpdate }] = useMutation(
        MUTATION_FACTORING_UPDATE,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.factoringUpdate) {
                    onUpdate(data.factoringUpdate);
                    onRequestClose();
                }
            }
        }
    );

    const updateFactoring = (params = {}) => {
        const { name } = params;

        mutationFactoringUpdate({
            variables: {
                id: factoring.id,
                name
            }
        });
    }

    const [mutationFactoringDelete] = useMutation(
        MUTATION_FACTORING_DELETE,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.factoringDelete) {
                    onDelete();
                    onRequestClose();
                }
            }
        }
    );

    const deleteFactoring = () => {
        mutationFactoringDelete({
            variables: {
                id: factoring.id
            }
        });
    }

    useEffect(() => {
        if (!isOpen) {
            setFactoring(null);
        }
    }, [isOpen])

    return (
        <Components.Modal
            title="Editar factoring"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            rightActions={
                <Components.Block row center>
                    <Components.Block>
                        <Components.Button type="submit" loading={loadingFactoringUpdate} size="medium" label="Editar factoring" color="brand" onClick={() => updateFormRef.current.handleSubmit()} />
                    </Components.Block>
                </Components.Block>
            }
        >
            {!!factoring ?
                <>
                    <Components.Form
                        innerRef={updateFormRef}
                        initialValues={{
                            name: factoring.name
                        }}
                        schema={yup.object().shape({
                            name: yup.string().required()
                        })}
                        onSubmit={(values) => {
                            updateFactoring(values);
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Components.FormGroup title="Geral">
                                        <Components.Block width={334}>
                                            <Components.Input error={errors.name} Component={Components.TextInput} name="name" label="Factoring" placeholder="Nome da factoring" />
                                        </Components.Block>
                                    </Components.FormGroup>
                                    <Components.FormGroup>
                                        <Components.DeleteLine
                                            title="Eliminar factoring"
                                            description="Esta ação irá eliminar a factoring permanentemente "
                                            onClickDelete={() => { deleteFactoring({ id: factoring.id }); onRequestClose(); }}
                                        />
                                    </Components.FormGroup>
                                </form>
                            )
                        }}
                    </Components.Form>
                </>
                :
                <Components.Block center middle>
                    <Components.Loading color={MediStrapProperties.colors.brand100} />
                </Components.Block>}
        </Components.Modal>
    );
}

export default ModalUpdate;
