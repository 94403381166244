import { useLazyQuery } from '@apollo/react-hooks';
import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Components, MediStrap } from '../../MediStrap';
import Calendar from './components/Calendar';
import ContractCard from './components/ContractCard';
//import Reminders from './components/Reminders';
import { Reminders } from './components/Reminders';
import { QUERY_CONTRACTS, QUERY_EVENTS } from './graphql/queries';

const MediStrapProperties = MediStrap.getProperties();

function Home() {
    const remindersRef = useRef(null);
    const [events, setEvents] = useState([]);
    const [contractCountByStatus, setContractCountByStatus] = useState({
        active: 0,
        deactivated: 0,
        expired: 0,
        renewing: 0,
        expiringInOneMonth: 0
    });

    const [queryContracts] = useLazyQuery(
        QUERY_CONTRACTS,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.contracts) {
                    setContractCountByStatus(data.contracts.statusCount);
                }
            }
        }
    );

    const requestContracts = (params = {}) => {
        const { perPage, currentPage } = params;

        queryContracts({
            variables: {
                perPage: 100000,
                currentPage: 1
            }
        });
    }

    useEffect(() => {
        requestContracts();
    }, []);

    const [queryEvents] = useLazyQuery(
        QUERY_EVENTS,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.events) {
                    setEvents(data.events.rows);
                }
            }
        }
    );

    const requestEvents = (params = {}) => {
        const { perPage, currentPage } = params;

        queryEvents({
            variables: {
                perPage: 100000,
                currentPage: 1
            }
        });
    }

    useEffect(() => {
        requestEvents();
    }, []);

    return (
        <Components.Block width="100%" flex color={MediStrapProperties.colors.grey5} row space="between">
            <Calendar events={events} remindersRef={remindersRef} />
            <Reminders ref={remindersRef} />
            <Components.Block height={486} width={212}>
                <Components.Text size={16} height={24} mb={12}>Contratos</Components.Text>
                <ContractCard label="ACTIVOS" iconColor={MediStrapProperties.colors.brand100} counter={contractCountByStatus.active} counterDiff={31} />
                <ContractCard label="EM RENOVAÇÃO" iconColor={MediStrapProperties.colors.validation100} counter={contractCountByStatus.renewing} counterDiff={5} />
                <ContractCard label="EXPIRADOS" iconColor={MediStrapProperties.colors.alert100} counter={contractCountByStatus.expired} counterDiff={-3} />
                <ContractCard label="A EXPIRAR - 1 MÊS" iconColor={MediStrapProperties.colors.information100} counter={contractCountByStatus.expiringInOneMonth} counterDiff={-1} />
                <Components.Block mt={-4}>
                    <Link to="/dashboard/contracts">
                        <Components.Button icon="Archive" label="Ver Contratos" size="medium" width="100%" />
                    </Link>
                </Components.Block>
            </Components.Block>

        </Components.Block>
    );
}

export default Home;

const Link = styled(NavLink)`
    text-decoration: none;
    width: 212px;
`
