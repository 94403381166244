import React from "react";
import { Components, MediStrap } from "../../../../MediStrap";

const MediStrapProperties = MediStrap.getProperties();

function InputSupportDays({ name, value = [] }) {
    return (
        <Components.FieldArray
            name={name}
            render={(arrayHelpers) => {
                let dayCounter = 0;
                value.map((value) => dayCounter += parseInt(value.days));
                return (
                    <Components.FormGroup title="Suporte" description="Dias suporte ao software/serviço contratado">
                        <Components.Block row wrap ml={-10} mr={-10} mb={-10}>
                            {value.map((_item, index) => {
                                return (
                                    <Components.Block key={index} width={118} ml={10} mr={10} mb={10}>
                                        <Components.Input Component={Components.QuantityInput} name={`${name}[${index}].days`} initialValue={0} label={`Ano ${index + 1}`} min={0} max={365} />
                                    </Components.Block>
                                );
                            })}
                            <Components.Block ml={10} mr={10} mt={24}>
                                <Components.Button type="button" icon="Plus" color="brand" size="square" onClick={() => {arrayHelpers.insert(value.length + 1, {days: 0})}} />
                            </Components.Block>
                        </Components.Block>
                        <Components.Block color={MediStrapProperties.colors.grey5} row space="between" pt={12} pr={16} pb={12} pl={16} br={MediStrapProperties.style.borderRadius}>
                            <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey50}>Total de dias</Components.Text>
                            <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100}>{dayCounter}</Components.Text>
                        </Components.Block>
                    </Components.FormGroup>
                );
            }}
        />
    );
}

export default InputSupportDays;
