import { useLazyQuery } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { Components, MediStrap } from "../../MediStrap";
import { CONTRACT_STATUSES, PER_PAGE_OPTIONS } from '../../utils/commonData';
import ModalCreate from './components/ModalCreate';
import ModalFilters from './components/ModalFilters';
import ModalUpdate from './components/ModalUpdate';
import { QUERY_CONTRACTS, QUERY_EXTRA_DATA } from './graphql/queries';
import { getClientToken } from '../../utils/client';

const MediStrapProperties = MediStrap.getProperties();

const contractStatus = CONTRACT_STATUSES;

const perPageOptions = PER_PAGE_OPTIONS;

function Contracts() {
    const [fetchingExport, setFetchingExport] = useState(false);
    const [search, setSearch] = useState("");
    const [filters, setFilters] = useState({
        factorings: undefined,
        totalAmount: undefined,
        dateSearchType: undefined,
        startDate: undefined,
        endDate: undefined,
        status: undefined
    });

    const [orderBy, setOrderBy] = useState({ column: "id", order: "DESC" });
    const [contracts, setContracts] = useState({
        rows: [],
        statusCount: {
            active: 0,
            deactivated: 0,
            expired: 0,
            renewing: 0,
            expiringInOneMonth: 0
        },
        pagination: {
            total: 0,
            perPage: 10,
            lastPage: 0,
            currentPage: 1,
            hasPreviousPage: false,
            hasNextPage: false,
            // orderBy: "name_ASC"
        }
    });

    const [factorings, setFactorings] = useState([]);
    const [clients, setClients] = useState([]);
    const [categories, setCategories] = useState([]);
    const [contractId, setContractId] = useState(null);

    const [isFiltersOpen, setFiltersOpen] = useState(false);
    const [isModalCreateOpen, setModalCreateOpen] = useState(false);
    const [isModalUpdateOpen, setModalUpdateOpen] = useState(false);

    const [queryContracts] = useLazyQuery(
        QUERY_CONTRACTS,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.contracts) {
                    setContracts(data.contracts);
                }
            }
        }
    );

    const requestContracts = (params = {}) => {
        const { perPage, currentPage } = params;

        queryContracts({
            variables: {
                perPage: perPage || contracts.pagination.perPage,
                currentPage: currentPage || contracts.pagination.currentPage,
                search: search !== "" ? search : undefined,
                orderBy: !!orderBy ? `${orderBy.column}_${orderBy.order}` : undefined,
                filters: !!filters ? {
                    ...filters,
                    factorings: !!filters.factorings ? filters.factorings.map((item) => item.value) : undefined
                } : undefined
            }
        });
    }

    useEffect(() => {
        requestContracts({ perPage: contracts.pagination.perPage, currentPage: 1 });
    }, [search, filters, orderBy]);

    const [queryExtraData] = useLazyQuery(
        QUERY_EXTRA_DATA,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.factorings) {
                    setFactorings(data.factorings.rows);
                }
                if (data && data.clients) {
                    setClients(data.clients.rows);
                }
                if (data && data.categories) {
                    setCategories(data.categories.rows);
                }
            }
        }
    );

    const requestExtraData = () => {
        queryExtraData();
    }

    useEffect(() => {
        requestExtraData();
    }, []);

    const exportRequest = () => {
        if (!fetchingExport) {
            setFetchingExport(true);

            fetch(`${process.env.REACT_APP_BACKEND_HOST}/export/contracts`, {
                method: "POST",
                headers: { "Authorization": getClientToken(), "Content-Type": "application/json" },
                body: JSON.stringify({
                    search,
                    filters
                })
            })
                .then(res => res.blob())
                .then(blob => {
                    var file = window.URL.createObjectURL(blob);
                    window.open(file, "_blank");
                    setFetchingExport(false);
                })
                .catch(() => {
                    setFetchingExport(false);
                });
        }
    }
    return (
        <>
            <Components.Block row space="between">
                <Components.Block row>
                    <Components.Block width={444}>
                        <Components.TextInput icon="Search" placeholder="Procurar contrato" value={search} onChangeValue={(value) => setSearch(value)} />
                    </Components.Block>
                    <Components.Block ml={20}>
                        <Components.Button
                            color="brand"
                            label="Filtrar"
                            icon="Filter"
                            onClick={() => setFiltersOpen(!isFiltersOpen)}
                        />
                    </Components.Block>
                    <Components.Block row center width={163} ml={20}>
                        <Components.DropdownInput onChangeValue={(option) => requestContracts({ perPage: option.value, currentPage: 1 })} value={perPageOptions.find((item) => item.value == contracts.pagination.perPage)} options={perPageOptions} />
                    </Components.Block>
                    <Components.Block ml={12}>
                        <Components.Navigation
                            perPage={contracts.pagination.perPage}
                            currentPage={contracts.pagination.currentPage}
                            total={contracts.pagination.total}
                            hasPreviousPage={contracts.pagination.hasPreviousPage}
                            hasNextPage={contracts.pagination.hasNextPage}
                            onClickPrevious={() => requestContracts({ currentPage: (contracts.pagination.currentPage - 1) })}
                            onClickNext={() => requestContracts({ currentPage: (contracts.pagination.currentPage + 1) })}
                        />
                    </Components.Block>
                </Components.Block>
                <Components.Block row>
                    <Components.Block>
                        <Components.Button loading={fetchingExport} color="grey" icon="Download" label="Exportar" onClick={() => exportRequest()} />
                    </Components.Block>
                    <Components.Block ml={8}>
                        <Components.Button color="brand" label="Criar contrato" onClick={() => setModalCreateOpen(true)} />
                    </Components.Block>
                </Components.Block>
            </Components.Block>

            <Components.Block flex pt={32} style={{ overflow: "hidden" }}>
                <Components.AdvancedTable
                    orderBy={orderBy}
                    onClickOrderBy={(column, order) => { setOrderBy({ column, order }); }}
                    columns={[
                        {
                            label: "Estado",
                            value: "state",
                            width: 142
                        },
                        {
                            label: "Cliente",
                            value: "client",
                            width: 250
                        },
                        {
                            label: "Categoria",
                            value: "category",
                            width: 216
                        },
                        {
                            label: "Pró. Faturação",
                            value: "nextBilling",
                            width: 216
                        },
                        {
                            label: "Data início",
                            value: "startDate"
                        },
                        {
                            label: "Data renovação",
                            value: "renovationDate",
                            width: 147
                        },
                        /*{
                            label: "Periodicidade",
                            value: "frequency",
                            width: 116
                        },*/
                        {
                            label: "Valor contrato",
                            value: "contractValue",
                            width: 147
                        },
                        {
                            label: "Valor anual",
                            value: "contractAnnualValue",
                            width: 147
                        },
                        {
                            label: "Contacto",
                            value: "contact",
                            width: 196
                        },
                        {
                            label: "Suporte",
                            value: "support",
                            width: 147
                        }
                    ]}
                    rows={contracts.rows.map((contract) => {
                        return ({
                            state: <Components.Block row center>
                                <Components.Block mr={12} pointer>
                                    <Components.Button type="button" icon="Edit" size="squareSmall" color="empty" onClick={() => { setContractId(contract.id); setModalUpdateOpen(true); }} />
                                </Components.Block>
                                <Components.Block>
                                    <Components.Badge label={contractStatus[contract.status]} color="brand" />
                                </Components.Block>
                            </Components.Block>,
                            client: <Components.Block column>
                                <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100} title={contract.client.name}>{contract.client.name}</Components.Text>
                                <Components.Text size={11} height={16} color={MediStrapProperties.colors.grey60} title={contract.code}>{contract.code}</Components.Text>
                            </Components.Block>,
                            category: contract.category.name,
                            nextBilling: <Components.Block column>
                                <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100} title={contract.nextBilling}>{contract.nextBilling}</Components.Text>
                                <Components.Text size={11} height={16} color={MediStrapProperties.colors.grey60} title={!!contract.client.factoring && contract.client.factoring.name}>{!!contract.client.factoring && contract.client.factoring.name}</Components.Text>
                            </Components.Block>,
                            startDate: contract.startDate,
                            renovationDate: contract.renewalDate,
                            //frequency: !!contract.billings[0] && <Components.Badge label={contract.billings[0].frequency} color="brand" />,
                            contractValue: contract.totalAmount + "€",
                            contractAnnualValue: contract.yearlyAmount + "€",
                            contact: !!contract.contacts[0] && !!contract.contacts[0].phone && contract.contacts[0].phone,
                            support: contract.support.reduce((accumulator, a) => accumulator + a, 0),
                        });
                    })}
                />
            </Components.Block>

            <ModalCreate
                onRequestClose={() => setModalCreateOpen(false)}
                onCreate={() => requestContracts({ currentPage: 1 })}
                isOpen={isModalCreateOpen}
                clients={clients}
                categories={categories}
            />

            <ModalUpdate
                onRequestClose={() => {
                    setContractId(null);
                    setModalUpdateOpen(false);
                }}
                onUpdate={(updateContract) => {
                    const index = contracts.rows.findIndex((item) => item.id == updateContract.id);
                    contracts.rows[index] = updateContract;
                    setContracts({ ...contracts });
                }}
                onDelete={() => {
                    requestContracts();
                }}
                isOpen={isModalUpdateOpen}
                clients={clients}
                categories={categories}
                contractId={contractId}
            />

            <ModalFilters
                onRequestClose={() => setFiltersOpen(false)}
                isOpen={isFiltersOpen}
                factorings={factorings}
                contractStatusCounts={contracts.statusCount}
                values={{
                    factorings: filters.factorings,
                    value: filters.totalAmount,
                    frequencyPeriod: filters.dateSearchType,
                    startDate: filters.startDate,
                    endDate: filters.endDate,
                    state: !!filters.status && {
                        active: filters.status.find((item) => item === "ACTIVE"),
                        inRenovation: filters.status.find((item) => item === "RENEWING"),
                        deactivated: filters.status.find((item) => item === "DEACTIVATED"),
                        expireInOneMonth: filters.status.find((item) => item === "EXPIRING_ONE_MONTH"),
                        expired: filters.status.find((item) => item === "EXPIRED"),
                    }
                }}
                onClickApply={(factorings, totalAmount, dateSearchType, startDate, endDate, status) => {
                    setFilters({
                        factorings,
                        totalAmount,
                        dateSearchType,
                        startDate,
                        endDate,
                        status
                    });
                }}
            />

        </>
    );
}

export default Contracts;
