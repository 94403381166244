import React from 'react';
import { Link } from 'react-scroll';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import styled from 'styled-components';
import MediStrap from "../../MediStrap";
import Block from '../Block';
import Button from '../Button';
import Icon from "../Icon";
import Text from '../Text';

const MediStrapProperties = MediStrap.getProperties();

export default function ModalThatSlides({ id, title, onRequestClose, actions, menu, menuActions, onClickExport, loadingExport, children, ...props }) {
    return (
        <SlidingPane
            className="sliding-modal"
            overlayClassName="sliding-modal-overlay"
            hideHeader={true}
            onRequestClose={onRequestClose}
            {...props}
        >
            <Block height="100%" color={MediStrapProperties.colors.grey5}>
                <Block center row color={MediStrapProperties.colors.brand140} width="100%" height={72} pl={28} pr={24} space="between">
                    <Block row center>
                        <Block pointer onClick={onRequestClose}>
                            <IconAngleLeft className="icon-Angle_left"></IconAngleLeft>
                        </Block>
                        <Text size={16} height={24} color={MediStrapProperties.colors.white100} ml={20}>{title}</Text>
                    </Block>
                    <Block>
                        {actions}
                    </Block>
                </Block>
                <Content pt={100} pr={150} pb={120} pl={382} style={{ position: "relative" }} id={id}>
                    <MenuWrapper>
                        {menuActions}
                        <Text size={14} height={24} color={MediStrapProperties.colors.grey100}>Menu</Text>
                        {!!menu && menu.map((item, index) => {
                            return (
                                <MenuItem key={index} activeClass="active" containerId={id} to={item.anchor} spy={true} smooth={true} offset={-100}>
                                    <Icon name={item.icon} color={MediStrapProperties.colors.grey60} />
                                    <Text ml={8} size={14} height={24} color={MediStrapProperties.colors.grey60}>{item.title}</Text>
                                </MenuItem>
                            );
                        })}
                        {!!onClickExport && <Block mt={12}>
                            <Button loading={loadingExport} icon="Download" label="Exportar" color="grey" width="100%" onClick={onClickExport} />
                        </Block>}
                    </MenuWrapper>
                    {children}
                </Content>
            </Block>
        </SlidingPane>
    );
}

const IconAngleLeft = styled.span`
    ::before {
        color: ${MediStrapProperties.colors.white100};
    }
`;

const Content = styled(Block)`
    overflow-y: auto;
`;

const MenuWrapper = styled(Block)`
    position: fixed;
    top: 172px;
    left: 150px;
`;

const MenuItem = styled(Link)`
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    height: 48px;
    width: 212px;
    margin-top: 12px;
    display: flex;
    flex-flow: row;
    align-items: center;
    padding: 16px;
    border-radius: ${MediStrapProperties.style.borderRadius}px;

    &.active {
        background-color: ${MediStrapProperties.colors.brand10};
        border: 1px solid ${MediStrapProperties.colors.brand30};

        span {
            color: ${MediStrapProperties.colors.brand100} !important;
        }

        span::before {
            color: ${MediStrapProperties.colors.brand100} !important;
        }
    }
`;