import React from "react";
import { Components } from "../../../../MediStrap";

function AccordionNotes({ name, value = [], anchor, errors }) {
    return (
        <Components.FieldArray
            name={name}
            render={(arrayHelpers) => {
                return (
                    <Components.Portlet anchor={anchor} noInnerPadding icon="Notes" title="Notas" actions={<Components.Button type="button" label="Adicionar Nota" color="brand" size="small" onClick={() => arrayHelpers.insert(value.length + 1, {})} />}>
                        {value.map((_item, index) => {
                            return (
                                <Components.AccordionItem
                                    key={index}
                                    index={index}
                                    name={name}
                                    title={"Nota" + " #" + (index + 1)}
                                >
                                    <Components.FormGroup>
                                        <Components.Block width={652}>
                                            <Components.Input error={errors[`${name}[${index}].title`]} Component={Components.TextInput} name={`${name}[${index}].title`} label="Title" placeholder="Título da nota" />
                                        </Components.Block>
                                        <Components.Block>
                                            <Components.Input error={errors[`${name}[${index}].description`]} Component={Components.TextAreaInput} name={`${name}[${index}].description`} label="Descrição" placeholder="Descrição da nota" />
                                        </Components.Block>
                                    </Components.FormGroup>
                                    <Components.FormGroup>
                                        <Components.DeleteLine
                                            title="Eliminar nota"
                                            description="Esta ação irá eliminar os dados relativos à nota permanentemente"
                                            onClickDelete={() => arrayHelpers.remove(index)}
                                        />
                                    </Components.FormGroup>
                                </Components.AccordionItem>
                            );
                        })}
                    </Components.Portlet>
                );
            }}
        />
    );
}

export default AccordionNotes;
