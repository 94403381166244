import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { Components, MediStrap } from "../../../../MediStrap";
import EventAccordion from '../EventAccordion';
import Week from './components/Week';
import { EVENT_COLORS } from '../../../../utils/commonData';

const MediStrapProperties = MediStrap.getProperties();
ReactModal.setAppElement('body');

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: 0,
        border: "none",
        borderRadius: MediStrapProperties.style.borderRadius
    },
    overlay: {
        backgroundColor: "rgb(0 0 0 / 50%)"
    }
};
const contractStatusDisplays = {
    "ACTIVE": {
        label: "Activo",
        backgroundColor: MediStrapProperties.colors.brand10,
        borderColor: MediStrapProperties.colors.brand30,
        textColor: MediStrapProperties.colors.brand100
    },
    "EXPIRED": {
        label: "Expirado",
        backgroundColor: MediStrapProperties.colors.brand10,
        borderColor: MediStrapProperties.colors.brand30,
        textColor: MediStrapProperties.colors.brand100
    },
    "RENEWING": {
        label: "Em renovação",
        backgroundColor: MediStrapProperties.colors.brand10,
        borderColor: MediStrapProperties.colors.brand30,
        textColor: MediStrapProperties.colors.brand100
    }
}
const weekDays = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
const months = [
    { value: 0, label: "Janeiro" },
    { value: 1, label: "Fevereiro" },
    { value: 2, label: "Março" },
    { value: 3, label: "Abril" },
    { value: 4, label: "Maio" },
    { value: 5, label: "Junho" },
    { value: 6, label: "Julho" },
    { value: 7, label: "Agosto" },
    { value: 8, label: "Setembro" },
    { value: 9, label: "Outubro" },
    { value: 10, label: "Novembro" },
    { value: 11, label: "Dezembro" }
];
const years = getYearsInRange({ min: 2000, max: 2050 });

function getYearsInRange({ min, max }) {
    let years = [];
    for (let i = min; i <= max; i++) {
        years.push({ value: i, label: i });
    }
    return years
};

export default function Calendar(
    {
        label,
        iconColor,
        counter,
        counterDiff,
        events,
        createReminderActions,
        remindersRef,
        ...props
    }
) {
    const [isEventModalOpen, setEventModalOpen] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedDay, setSelectedDay] = useState(null);
    const [eventsOfSelectedDay, setEventsOfSelectedDay] = useState([]);
    const [todayEventsCount, setTodayEventsCount] = useState(
        events.filter((item) => item.date.valueOf() == (new Date()).setHours(0, 0, 0, 0).valueOf()).length
    );

    const hasPreviousPage = selectedYear !== years[0].value || selectedMonth !== months[0].value;
    const hasNextPage = selectedYear !== years[years.length - 1].value || selectedMonth !== months[months.length - 1].value;

    useEffect(() => {
        setTodayEventsCount(events.filter((item) => item.date.valueOf() == (new Date()).setHours(0, 0, 0, 0).valueOf()).length);
    }, [events]);
    return (
        <Components.Block flex mr={20}>
            <Components.Text size={16} height={24} mb={12}>Eventos</Components.Text>
            <CalendarBlock flex br={MediStrapProperties.style.borderRadius} color={MediStrapProperties.colors.white100}>
                <Components.Block height={72} center row space="between" pt={24} pb={24} pl={24} pr={24}>
                    <CalendarPaginationButton
                        size="squareSmall"
                        color="empty"
                        icon="Angle_left"
                        onClick={() => {
                            if (hasPreviousPage) {
                                const previousMonth = new Date(selectedYear, selectedMonth - 1);
                                setSelectedYear(previousMonth.getFullYear());
                                setSelectedMonth(previousMonth.getMonth());
                            }
                        }}
                        active={hasPreviousPage}
                    />
                    <Components.Block row>
                        <Components.Text size={16} height={24}
                        >{months.find((item) => item.value == selectedMonth).label} {years.find((item) => item.value == selectedYear).label}</Components.Text>
                    </Components.Block>
                    <CalendarPaginationButton
                        size="squareSmall"
                        color="empty"
                        icon="Angle_right"
                        onClick={() => {
                            if (hasNextPage) {
                                const nextMonth = new Date(selectedYear, selectedMonth + 1);
                                setSelectedYear(nextMonth.getFullYear());
                                setSelectedMonth(nextMonth.getMonth());
                            }
                        }}
                        active={hasNextPage}
                    />
                </Components.Block>
                <Components.Block
                    height={72}
                    center
                    row
                    space="between"
                    pt={24} pb={24} pl={24} pr={24}
                    color={MediStrapProperties.colors.grey5}
                    style={{ borderTop: `1px solid ${MediStrapProperties.colors.grey20}`, borderBottom: `1px solid ${MediStrapProperties.colors.grey20}` }}
                >
                    <Components.Block>
                        <Components.Text size={12} height={16}>Eventos hoje</Components.Text>
                        <Components.Block row>
                            <Components.Block mr={4}>
                                <Components.Text size={14} height={24} color={MediStrapProperties.colors.brand100}>({todayEventsCount})</Components.Text>
                            </Components.Block>
                            <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey50}>Eventos</Components.Text>
                        </Components.Block>
                    </Components.Block>
                    <Components.Block row>
                        <Components.Block width={188} mr={12}>
                            <Components.DropdownInput name="month" options={months} onChangeValue={(item) => setSelectedMonth(item.value)} value={months.find((item) => item.value == selectedMonth)} />
                        </Components.Block>
                        <Components.Block width={116} mr={12}>
                            <Components.DropdownInput name="year" options={years} onChangeValue={(item) => setSelectedYear(item.value)} value={years.find((item) => item.value == selectedYear)} />
                        </Components.Block>
                        <Components.Button
                            label="Ver eventos"
                            size="big"
                            disabled={!(events.filter((item) => item.date.valueOf() == new Date().setHours(0, 0, 0, 0).valueOf()).length > 0)}
                            onClick={() => {
                                setSelectedDay(new Date().valueOf());
                                setEventsOfSelectedDay(events.filter((item) => item.date.valueOf() == new Date().setHours(0, 0, 0, 0).valueOf()));
                                setEventModalOpen(!isEventModalOpen);
                            }}
                        />
                    </Components.Block>
                </Components.Block>
                <Components.Block pt={20} pb={32} pl={16} pr={16}>
                    <Components.Block height={72} row flex space="between" mr={-7} ml={-7}>
                        {weekDays.map((item, key) => {
                            return (
                                <Components.Block key={key} height={40} flex center mr={7} ml={7} pt={12} pb={12}>
                                    <Components.Text size={12} color={MediStrapProperties.colors.grey50} height={16}>{item}</Components.Text>
                                </Components.Block>
                            );
                        })}
                    </Components.Block>
                    <Components.Block>
                        {getCurrentPageDates(selectedYear, selectedMonth).map((item, key) => {
                            return (
                                <Week
                                    dates={item}
                                    key={key}
                                    currentMonth={selectedMonth}
                                    isEventModalOpen={isEventModalOpen}
                                    setEventModalOpen={(date) => events.filter((item) => item.date.valueOf() == date.valueOf()).length > 0 && setEventModalOpen(true)}
                                    selectedDay={selectedDay}
                                    setSelectedDay={(date) => setSelectedDay(date)}
                                    setEventsOfDay={(date) => { setEventsOfSelectedDay(events.filter((item) => item.date.valueOf() == date.valueOf())); }}
                                    events={events}
                                />
                            )
                        })}
                    </Components.Block>
                </Components.Block>
            </CalendarBlock>
            <ReactModal
                isOpen={isEventModalOpen}
                onRequestClose={() => {
                    setEventModalOpen(false);
                    setSelectedDay(null);
                }}
                style={customStyles}
            >
                {eventsOfSelectedDay.map((event) => {
                    const eventDate = new Date(parseInt(event.date));
                    return (<EventAccordion
                        event={event}
                        remindersRef={remindersRef}
                        key={event.id}
                        onClickCreateReminder={() => setEventModalOpen(false)}
                    >
                        <Components.Block center space="between" row pt={12} pb={12} pl={24} pr={24} style={{ borderBottom: `1px solid ${MediStrapProperties.colors.grey20}` }}>
                            <Components.Text height={24} size={14}>Estado</Components.Text>
                            <Components.Block height={24} pt={4} pb={4} pl={8} pr={8} color={contractStatusDisplays[event.contract.status].backgroundColor} style={{ border: `1px solid ${contractStatusDisplays[event.contract.status].borderColor}` }} br={MediStrapProperties.style.borderRadius}>
                                <Components.Text height={16} size={11} color={contractStatusDisplays[event.contract.status].textColor}>{contractStatusDisplays[event.contract.status].label}</Components.Text>
                            </Components.Block>
                        </Components.Block>
                        <Components.Block pt={12} pb={12} pl={24} pr={24} style={{ borderBottom: `1px solid ${MediStrapProperties.colors.grey20}` }}>
                            <Components.Text height={16} size={10} bold color={MediStrapProperties.colors.grey60}>CLIENTE</Components.Text>
                            <Components.Text height={24} size={14}>{event.contract.client.name}</Components.Text>
                            <Components.Text height={16} size={11}>{event.contract.code}</Components.Text>
                        </Components.Block>
                        <Components.Block pt={12} pb={12} pl={24} pr={24} style={{ borderBottom: `1px solid ${MediStrapProperties.colors.grey20}` }}>
                            <Components.Text height={16} size={10} bold color={MediStrapProperties.colors.grey60}>Data</Components.Text>
                            <Components.Text height={24} size={14} bold color={MediStrapProperties.colors.grey60}>{eventDate.toLocaleDateString("pt-PT")}</Components.Text>
                        </Components.Block>
                        {/*<Components.Block pt={24} pb={24} pl={24} pr={24}>
                            <Components.Button color="grey" width="100%" icon="Edit" size="small" label="Ver contrato" />
                </Components.Block>*/}
                    </EventAccordion>
                    )
                })
                }
            </ReactModal>
        </Components.Block>
    )
}

const CalendarBlock = styled(Components.Block)`
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
`

const CalendarPaginationButton = styled(Components.Button)`
    cursor: ${({ active }) => active ? "pointer" : "no-drop"};
`

function getCurrentPageDates(year, month) {
    const firstDayOfTheMonth = new Date(year, month);
    const lastDayOfTheMonth = new Date(year, month + 1, 0);
    const firstSunday = new Date(year, month, - (firstDayOfTheMonth.getDay() - 1));
    const lastSaturday = new Date(year, month + 1, (6 - lastDayOfTheMonth.getDay()));
    let page = [];
    let week = [];
    for (let iteratorDay = firstSunday; iteratorDay <= lastSaturday; iteratorDay.setDate(iteratorDay.getDate() + 1)) {
        week.push(new Date(iteratorDay));
        if (iteratorDay.getDay() == 6) {
            page.push(week);
            week = [];
        }
    }
    return page;
}