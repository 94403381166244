import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useEffect, useRef, useState } from "react";
import * as yup from 'yup';
import { Components, MediStrap } from "../../../../../MediStrap";
import { REMINDER_MOTIVES } from "../../../../../utils/commonData";
import { MUTATION_REMINDER_DELETE, MUTATION_REMINDER_UPDATE } from "../../../graphql/mutations";
import { QUERY_REMINDER } from "../../../graphql/queries";
import InputContract from "../InputContract";

const MediStrapProperties = MediStrap.getProperties();

const reminderMotives = REMINDER_MOTIVES;

function ModalUpdate({ onRequestClose, onUpdate, onDelete, isOpen, reminderId }) {
    const updateFormRef = useRef(null);
    const [reminder, setReminder] = useState(null);

    const [queryReminder] = useLazyQuery(
        QUERY_REMINDER,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.reminder) {
                    setReminder(data.reminder);
                }
            }
        }
    );

    const requestReminder = (params = {}) => {
        const { id } = params;

        queryReminder({
            variables: {
                id
            }
        });
    }

    useEffect(() => {
        if (!!reminderId) {
            requestReminder({ id: reminderId });
        }
    }, [reminderId]);

    const [mutationReminderUpdate, { loading: loadingUpdateReminder }] = useMutation(
        MUTATION_REMINDER_UPDATE,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.reminderUpdate) {
                    onUpdate(data.reminderUpdate);
                    onRequestClose();
                }
            }
        }
    );

    const updateReminder = (params = {}) => {
        const { contract, motive, date } = params;

        mutationReminderUpdate({
            variables: {
                id: reminder.id,
                contract,
                motive,
                date
            }
        });
    }

    const [mutationReminderDelete] = useMutation(
        MUTATION_REMINDER_DELETE,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.reminderDelete) {
                    onDelete(data.reminderDelete);
                }
            }
        }
    );

    const deleteReminder = (params = {}) => {
        const { id } = params;

        mutationReminderDelete({
            variables: {
                id
            }
        });
    }

    useEffect(() => {
        if (!isOpen) {
            setReminder(null);
        }
    }, [isOpen])

    return (
        <Components.Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            title="Editar lembrete"
            rightActions={
                <Components.Block>
                    <Components.Block>
                        <Components.Button type="submit" label="Guardar edição" loading={loadingUpdateReminder} color="brand" size="medium" onClick={() => updateFormRef.current.handleSubmit()} />
                    </Components.Block>
                </Components.Block>
            }
        >
            {!!reminder ?
                <Components.Form
                    initialValues={{
                        contract: reminder.contract,
                        motive: reminderMotives.find((item) => item.value === reminder.motive),
                        date: reminder.date
                    }}
                    innerRef={updateFormRef}
                    schema={yup.object().shape({
                        contract: yup.object().required().nullable(),
                        motive: yup.object().required(),
                        date: yup.date().required().nullable().transform((currentValue, originalValue) => originalValue === "" ? null : currentValue),
                    })}
                    onSubmit={(values) => {
                        updateReminder({ contract: values.contract.id, motive: values.motive.value, date: values.date });
                    }}
                >
                    {({
                        values,
                        errors,
                        handleSubmit,
                    }) => {
                        return (
                            <form onSubmit={handleSubmit} autoComplete="off">
                                <Components.FormGroup title="Contrato">
                                    <Components.Input error={errors.contract} Component={InputContract} name="contract" />
                                </Components.FormGroup>
                                <Components.FormGroup title="Motivo do lembrete">
                                    <Components.Block width={334}>
                                        <Components.Input error={errors.motive} Component={Components.DropdownInput} name="motive" label="Motivo" placeholder="Seleccione motivo" options={reminderMotives} />
                                    </Components.Block>
                                </Components.FormGroup>
                                <Components.FormGroup title="Data do lembrete">
                                    <Components.Block width={168}>
                                        <Components.Input error={errors.date} Component={Components.DateInput} name="date" label="Data" placeholder="DD / MM / AAAA" />
                                    </Components.Block>
                                </Components.FormGroup>
                                <Components.FormGroup>
                                    <Components.DeleteLine
                                        title="Eliminar lembrete"
                                        description="Esta ação irá eliminar todos os dados deste lembrete"
                                        onClickDelete={() => { deleteReminder({ id: reminder.id }); onRequestClose(); }}
                                    />
                                </Components.FormGroup>
                            </form>
                        )
                    }}
                </Components.Form>
                :
                <Components.Block center middle>
                    <Components.Loading color={MediStrapProperties.colors.brand100} />
                </Components.Block>}
        </Components.Modal>
    )
}

export default ModalUpdate;