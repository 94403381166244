import React from "react";
import { NavLink } from 'react-router-dom';
import styled from "styled-components";
import MediStrap from "../../MediStrap";
import Block from "../Block";
import Text from "../Text";

const MediStrapProperties = MediStrap.getProperties();

function Header1({ menu = [], name, position, actions }) {
    return (
        <Block color={MediStrapProperties.colors.brand140} flex height={72} row center space="between" pl={34} pr={34}>
            <Block>
                <img src={process.env.PUBLIC_URL + '/logo_white.png'} />
            </Block>
            <Block row>
                <Block row mr={56}>
                    {menu.map((item, index) => {
                        return (
                            <Link key={index} to={item.to} activeClassName="selected">
                                <Block row center middle height="100%">
                                    {!!item.icon && <Icon width={16} height={16} className={`icon-${item.icon}`} />}
                                    {!!item.label && <Text size={14} height={24} ml={8}>{item.label}</Text>}
                                </Block>
                            </Link>
                        );
                    })}
                </Block>
                <Block middle>
                    <Text size={14} height={24} color={MediStrapProperties.colors.white100}>{name}</Text>
                    <Text size={11} height={16} color={MediStrapProperties.colors.brand100}>{position}</Text>
                </Block>
                <Block row center ml={32}>
                    {actions}
                </Block>
            </Block>
        </Block>
    );
}

const Icon = styled.span`
    
`;

const Link = styled(NavLink)`
    text-decoration: none;
    height: 72px;
    border-bottom: 3px solid transparent;
    margin-left: 32px;
    
    span {
        color: ${MediStrapProperties.colors.brand120} !important;
    }

    ${Icon}::before {
        color: ${MediStrapProperties.colors.brand120} !important;
    }

    &.selected {
        border-bottom: 3px solid ${MediStrapProperties.colors.brand100};
    }

    &.selected span {
        color: ${MediStrapProperties.colors.white100} !important;
    }

    &.selected ${Icon}::before {
        color: ${MediStrapProperties.colors.brand100} !important;
    }

    /* :hover {
        span {
            color: ${MediStrapProperties.colors.white} !important;
        }

        ${Icon}::before {
            color: ${MediStrapProperties.colors.greenLight} !important;
        }
    } */
`;

export default React.memo(Header1);