import React from "react";
import styled, { css } from 'styled-components';
import MediStrap from "../../../MediStrap";
import Block from '../../Block';
import Text from '../../Text';

const MediStrapProperties = MediStrap.getProperties();

function DateInput(
    {
        label,
        description,
        error,
        onChangeValue,
        value = "",
        disabled
    }
) {
    return (
        <Block width="100%">
            {!!label && <Text size={12} height={16} mb={8} color={MediStrapProperties.colors.grey100}>{label}</Text>}
            <InputWrapper style={{ position: "relative" }}>
                <IconWrapper disabled={disabled}><span className="icon-Calendar" /></IconWrapper>
                {(!!error) && <StatusWrapper><span className="icon-Error" /></StatusWrapper>}
                <Input
                    type="date"
                    error={error}
                    value={value}
                    onChange={(e) => !!onChangeValue && onChangeValue(e.target.value)}
                    disabled={disabled}
                />
            </InputWrapper>
            {(() => {
                let color = null;
                let text = null;
                if (!!error) { text = typeof error === "string" ? error : null; color = MediStrapProperties.colors.alert100; }
                else if (!!description) { text = description; color = MediStrapProperties.colors.grey50; }

                if (!!text) {
                    return (
                        <Block mt={8}>
                            <Text
                                size={11}
                                height={16}
                                color={color}
                            >
                                {error || description}
                            </Text>
                        </Block>
                    );
                }
            })()}
        </Block>
    );
}

export default React.memo(DateInput);

const Input = styled.input`
    background-color: ${MediStrapProperties.colors.white100};
    height: 48px;
    color: ${MediStrapProperties.colors.grey100};
    font-size: 14px;
    line-height: 24px;
    font-family: "MedistrapSemiBold";
    border: 0;
    padding: 8px 12px 8px 44px;
    border: 1px solid ${MediStrapProperties.colors.grey20};
    border-radius: ${MediStrapProperties.style.borderRadius}px;

    ::placeholder {
        color: ${MediStrapProperties.colors.grey50};
    }

    :hover {
        border-color: ${({ error, success }) => {
        if (!!error) {
            return MediStrapProperties.colors.alert100;
        }

        if (success) {
            return MediStrapProperties.colors.validation100;
        }

        return MediStrapProperties.colors.grey60;
    }};
    }
    
    :focus {
        border-color: ${MediStrapProperties.colors.brand100};
    }

    :disabled {
        color: ${MediStrapProperties.colors.grey20};
        background-color: ${MediStrapProperties.colors.grey5};
        border-color: ${MediStrapProperties.colors.grey20};
    }

    ${({ error }) => !!error && css`
        border-color:  ${MediStrapProperties.colors.alert100} !important;
    `}

    ::-webkit-inner-spin-button {
        display: none;
        -webkit-appearance: none;
    }

    ::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }

    /* ::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    } */
`;

const IconWrapper = styled(Block)`
    position: absolute;
    top: 16px;
    left: 16px;
`;

const InputWrapper = styled(Block)`
    :hover {
        ${IconWrapper} {
            
        }
    }
`;

const StatusWrapper = styled(Block)`
    position: absolute;
    top: 16px;
    right: 16px;
`;