import gql from "graphql-tag";

export const MUTATION_CONTRACT_CREATE = gql`
    mutation contractCreate($status: ContractStatus!, $code: String!, $client: ID!, $category: ID!, $description: String, $support: [Int!]!, $contacts: [InputContractContact!]!, $filesToUpload: [Upload!], $notes: [InputContractNote!]!, $licenses: [ID!]!, $billings: [InputBilling!]!, $cycle: InputCycle!) {
        contractCreate (status: $status, code: $code, client: $client, category: $category, description: $description, support: $support, contacts: $contacts, filesToUpload: $filesToUpload, notes: $notes, licenses: $licenses, billings: $billings, cycle: $cycle){
            id
            status
            code
            client {
                id
                name
            }
            category {
                id
                name
            }
            description
            support
            contacts {
                name
                email
                phone
            }
            files {
                name
                date
                url
            }
            notes {
                title
                description
            }
            licenses {
                id
                name
            }
            billings {
                id
                amount
                frequency
                partitionType
                partitionValue
                startDate
                endDate
                description
                reference
                softwareCode
            }
            cycle {
                id
                type
                startDate
                endDate
                extensionPeriod {
                    value
                    unit
                }
                termNotification {
                    value
                    unit
                }
                description
                endAction
            }
        }
    }
`;
export const MUTATION_CONTRACT_UPDATE = gql`
    mutation contractUpdate($id: ID!, $status: ContractStatus!, $code: String!, $client: ID!, $category: ID!, $description: String, $support: [Int!]!, $contacts: [InputContractContact!]!, $filesToUpload: [Upload!], $files: [InputContractFile!]!, $notes: [InputContractNote!]!, $licenses: [ID!]!, $billings: [InputBilling!]!, $cycle: InputCycle!) {
        contractUpdate (id: $id, status: $status, code: $code, client: $client, category: $category, description: $description, support: $support, contacts: $contacts, filesToUpload: $filesToUpload, files: $files, notes: $notes, licenses: $licenses, billings: $billings, cycle: $cycle){
            id
            status
            code
            client {
                id
                name
                factoring {
                    id
                    name
                }
            }
            category {
                id
                name
            }
            description
            support
            contacts {
                name
                email
                phone
            }
            files {
                name
                date
                url
            }
            notes {
                title
                description
            }
            licenses {
                id
                name
            }
            billings {
                id
                amount
                frequency
                partitionType
                partitionValue
                startDate
                endDate
                description
                reference
                softwareCode
            }
            cycle {
                id
                type
                startDate
                endDate
                extensionPeriod {
                    value
                    unit
                }
                termNotification {
                    value
                    unit
                }
                description
                endAction
            }
            renewalDate
            startDate
            totalAmount
            yearlyAmount
            nextBilling
        }
    }
`;
export const MUTATION_CONTRACT_DELETE = gql`
    mutation contractDelete($id: ID!) {
        contractDelete (id: $id) {
            id
            status
            code
            client {
                id
                name
            }
            category {
                id
                name
            }
            description
            support
            contacts {
                name
                email
                phone
            }
            files {
                name
                date
                url
            }
            notes {
                title
                description
            }
            licenses {
                id
                name
            }
            billings {
                id
                amount
                frequency
                partitionType
                partitionValue
                startDate
                endDate
                description
                reference
                softwareCode
            }
            cycle {
                id
                type
                startDate
                endDate
                extensionPeriod {
                    value
                    unit
                }
                termNotification {
                    value
                    unit
                }
                description
                endAction
            }
        }
    }
`;