import { useLazyQuery } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { Components, MediStrap } from "../../../../MediStrap";
import { TabMenuSettings } from "../../components";
import { QUERY_CATEGORIES } from '../../graphql/queries';
import { PER_PAGE_OPTIONS } from '../../../../utils/commonData';
import ModalCreate from './ModalCreate';
import ModalUpdate from './ModalUpdate';

const MediStrapProperties = MediStrap.getProperties();
const perPageOptions = PER_PAGE_OPTIONS;

function Categories() {
    const [isModalCreateOpen, setModalCreateOpen] = useState(false);
    const [isModalUpdateOpen, setModalUpdateOpen] = useState(false);
    const [categoryId, setCategoryId] = useState(false);

    const [categories, setCategories] = useState({
        rows: [],
        pagination: {
            total: 0,
            perPage: 10,
            lastPage: 0,
            currentPage: 1,
            hasPreviousPage: false,
            hasNextPage: false,
            // orderBy: "name_ASC"
        }
    });
    const [orderBy, setOrderBy] = useState(null);
    const [search, setSearch] = useState("");

    const [queryCategories] = useLazyQuery(
        QUERY_CATEGORIES,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.categories) {
                    setCategories(data.categories);
                }
            }
        }
    );

    const requestCategories = (params = {}) => {
        const { perPage, currentPage } = params;

        queryCategories({
            variables: {
                perPage: perPage || categories.pagination.perPage,
                currentPage: currentPage || categories.pagination.currentPage,
                search: search !== "" ? search : undefined,
                orderBy: !!orderBy ? `${orderBy.column}_${orderBy.order}` : undefined
            }
        });
    }

    useEffect(() => {
        requestCategories({ perPage: categories.pagination.perPage, currentPage: 1 });
    }, [search, orderBy]);

    return (
        <>
            <Components.Block row space="between">
                <Components.Block row>
                    <Components.Block width={444}>
                        <Components.TextInput icon="Search" placeholder="Procurar categoria" value={search} onChangeValue={(value) => setSearch(value)} />
                    </Components.Block>
                    <Components.Block row center width={163} ml={20}>
                    <Components.DropdownInput onChangeValue={(option) => requestCategories({ perPage: option.value, currentPage: 1 })} value={perPageOptions.find((item) => item.value == categories.pagination.perPage)} options={perPageOptions} />                    </Components.Block>
                    <Components.Block ml={12}>
                        <Components.Navigation
                            perPage={categories.pagination.perPage}
                            currentPage={categories.pagination.currentPage}
                            total={categories.pagination.total}
                            hasPreviousPage={categories.pagination.hasPreviousPage}
                            hasNextPage={categories.pagination.hasNextPage}
                            onClickPrevious={() => requestCategories({ currentPage: (categories.pagination.currentPage - 1) })}
                            onClickNext={() => requestCategories({ currentPage: (categories.pagination.currentPage + 1) })}
                        />
                    </Components.Block>
                </Components.Block>
                <Components.Block row>
                    <Components.Button color="brand" label="Criar categoria" onClick={() => setModalCreateOpen(true)} />
                </Components.Block>
            </Components.Block>
            <Components.Block flex pt={32} style={{ overflow: "hidden" }}>
                <TabMenuSettings />
                <Components.AdvancedTable
                    columns={[
                        {
                            label: "Nome",
                            value: "name",
                            order: false
                        }
                    ]}
                    rows={categories.rows.map((category) => {
                        return ({
                            name: <Components.Block row center height={56}>
                                <Components.Block mr={12} pointer>
                                    <Components.Button type="button" icon="Edit" size="squareSmall" color="empty" onClick={() => { setCategoryId(category.id); setModalUpdateOpen(true); }} />
                                </Components.Block>
                                <Components.Block>
                                    <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100} title="Sigma">{category.name}</Components.Text>
                                </Components.Block>
                            </Components.Block>
                        })
                    })}
                    noBorderRadius
                />
            </Components.Block>

            <ModalCreate
                onRequestClose={() => setModalCreateOpen(false)}
                onCreate={() => requestCategories({ currentPage: 1 })}
                isOpen={isModalCreateOpen}
            />

            <ModalUpdate
                onRequestClose={() => {
                    setCategoryId(null);
                    setModalUpdateOpen(false);
                }}
                onUpdate={(updateCategory) => {
                    const index = categories.rows.findIndex((item) => item.id == updateCategory.id);
                    categories.rows[index] = updateCategory;
                    setCategories({ ...categories });
                }}
                onDelete={() => {
                    requestCategories();
                }}
                isOpen={isModalUpdateOpen}
                categoryId={categoryId}
            />
        </>
    );
}

export default Categories;
