import React, { useState } from "react";
import styled from "styled-components";
import MediStrap from "../../MediStrap";
import ActionPanel1 from "../ActionPanel1";
import Block from "../Block";
import Button from "../Button";
import Text from "../Text";

const MediStrapProperties = MediStrap.getProperties();

export default function DeleteLine(
    {
        title,
        description,
        onClickDelete
    }
) {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    return (
        <>
            <Wrapper center row space="between">
                <Block middle>
                    <Text size={14} height={24} color={MediStrapProperties.colors.alert100}>{title}</Text>
                    <Text size={14} height={24} color={MediStrapProperties.colors.grey50}>{description}</Text>
                </Block>
                <Block row center>
                    <Button type="button" icon="Trash" label="Eliminar" color="red" size="medium" onClick={() => setIsDeleteModalOpen(true)} />
                </Block>
            </Wrapper>
            <ActionPanel1
                isOpen={isDeleteModalOpen}
                onRequestClose={() => setIsDeleteModalOpen(false)}
                title={title}
                description={description + ", pretende mesmo assim continuar?"}
                confirmButtonProps={{
                    label: "Eliminar",
                    icon: "Trash",

                    onClick: () => { onClickDelete && onClickDelete(); setIsDeleteModalOpen(false); }
                }}
                cancelButtonProps={{
                    label: "Cancelar",
                    onClick: () => setIsDeleteModalOpen(false)
                }}
            />
        </>
    );
}

const Wrapper = styled(Block)`
    
`;