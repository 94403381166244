import { gql, useLazyQuery } from '@apollo/react-hooks';
import React, { useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { getClientToken, setClientToken } from './client';
import { store } from './store';

export default (Component) => {
    return () => {
        const { setUser, user } = useContext(store);
        const [cookies] = useCookies(['backoffice_session']);
        const history = useHistory();

        const [queryUserLogged] = useLazyQuery(
            gql`
                query userLogged {
                    userLogged {
                        id
                        name
                        email
                        position
                    }
                }
            `,
            {
                onError: () => {
                    history.push("/login");
                },
                onCompleted: (data) => {
                    if (data && data.userLogged) {
                        setUser(data.userLogged);
                    }
                }
            }
        );

        if (cookies.backoffice_session) {
            setClientToken(cookies.backoffice_session);
        }

        useEffect(() => {
            if (!user && getClientToken()) {
                queryUserLogged();
            }
        }, []);

        if (getClientToken()) {
            if (!user) {
                return null;
            }
            return <Component />
        } else {
            history.push("/login");
        }

        return null;
    }
}