import { MediStrap } from "../MediStrap";

const MediStrapProperties = MediStrap.getProperties();

export const DISTRICTS = [
    { label: 'Açores', value: 'Açores' },
    { label: 'Aveiro', value: 'Aveiro' },
    { label: 'Beja', value: 'Beja' },
    { label: 'Braga', value: 'Braga' },
    { label: 'Bragança', value: 'Bragança' },
    { label: 'Castelo Branco', value: 'Castelo Branco' },
    { label: 'Coimbra', value: 'Coimbra' },
    { label: 'Évora', value: 'Évora' },
    { label: 'Faro', value: 'Faro' },
    { label: 'Guarda', value: 'Guarda' },
    { label: 'Leiria', value: 'Leiria' },
    { label: 'Lisboa', value: 'Lisboa' },
    { label: 'Madeira', value: 'Madeira' },
    { label: 'Portalegre', value: 'Portalegre' },
    { label: 'Porto', value: 'Porto' },
    { label: 'Santarém', value: 'Santarém' },
    { label: 'Setúbal', value: 'Setúbal' },
    { label: 'Viana do Castelo', value: 'Viana do Castelo' },
    { label: 'Vila Real', value: 'Vila Real' },
    { label: 'Viseu', value: 'Viseu' }
];

export const CITIES = [
    {
        district: 'Açores',
        cities: [
            { label: 'Angra do Heroísmo', value: 'Angra do Heroísmo' },
            { label: 'Calheta', value: 'Calheta' },
            { label: 'Corvo', value: 'Corvo' },
            { label: 'Horta', value: 'Horta' },
            { label: 'Lagoa', value: 'Lagoa' },
            { label: 'Lajes das Flores', value: 'Lajes das Flores' },
            { label: 'Lajes do Pico', value: 'Lajes do Pico' },
            { label: 'Madalena', value: 'Madalena' },
            { label: 'Nordeste', value: 'Nordeste' },
            { label: 'Ponta Delgada', value: 'Ponta Delgada' },
            { label: 'Povoação', value: 'Povoação' },
            { label: 'Praia da Vitória', value: 'Praia da Vitória' },
            { label: 'Ribeira Grande', value: 'Ribeira Grande' },
            { label: 'Santa Cruz da Graciosa', value: 'Santa Cruz da Graciosa' },
            { label: 'Santa Cruz das Flores', value: 'Santa Cruz das Flores' },
            { label: 'São Roque do Pico', value: 'São Roque do Pico' },
            { label: 'Velas', value: 'Velas' },
            { label: 'Vila do Porto', value: 'Vila do Porto' },
            { label: 'Vila Franca do Campo', value: 'Vila Franca do Campo' }
        ]
    },
    {
        district: 'Aveiro',
        cities: [
            { label: 'Águeda', value: 'Águeda' },
            { label: 'Albergaria-a-Velha', value: 'Albergaria-a-Velha' },
            { label: 'Anadia', value: 'Anadia' },
            { label: 'Arouca', value: 'Arouca' },
            { label: 'Aveiro', value: 'Aveiro' },
            { label: 'Castelo de Paiva', value: 'Castelo de Paiva' },
            { label: 'Espinho', value: 'Espinho' },
            { label: 'Estarreja', value: 'Estarreja' },
            { label: 'Ílhavo', value: 'Ílhavo' },
            { label: 'Mealhada', value: 'Mealhada' },
            { label: 'Murtosa', value: 'Murtosa' },
            { label: 'Oliveira de Azeméis', value: 'Oliveira de Azeméis' },
            { label: 'Oliveira do Bairro', value: 'Oliveira do Bairro' },
            { label: 'Ovar', value: 'Ovar' },
            { label: 'Santa Maria da Feira', value: 'Santa Maria da Feira' },
            { label: 'São João da Madeira', value: 'São João da Madeira' },
            { label: 'Sever do Vouga', value: 'Sever do Vouga' },
            { label: 'Vagos', value: 'Vagos' },
            { label: 'Vale de Cambra', value: 'Vale de Cambra' }
        ]
    },
    {
        district: 'Beja',
        cities: [
            { label: 'Aljustrel', value: 'Aljustrel' },
            { label: 'Almodôvar', value: 'Almodôvar' },
            { label: 'Alvito', value: 'Alvito' },
            { label: 'Barrancos', value: 'Barrancos' },
            { label: 'Beja', value: 'Beja' },
            { label: 'Castro Verde', value: 'Castro Verde' },
            { label: 'Cuba', value: 'Cuba' },
            { label: 'Ferreira do Alentejo', value: 'Ferreira do Alentejo' },
            { label: 'Mértola', value: 'Mértola' },
            { label: 'Moura', value: 'Moura' },
            { label: 'Odemira', value: 'Odemira' },
            { label: 'Ourique', value: 'Ourique' },
            { label: 'Serpa', value: 'Serpa' },
            { label: 'Vidigueira', value: 'Vidigueira' }
        ]
    },
    {
        district: 'Braga',
        cities: [
            { label: 'Amares', value: 'Amares' },
            { label: 'Barcelos', value: 'Barcelos' },
            { label: 'Braga', value: 'Braga' },
            { label: 'Cabeceiras de Basto', value: 'Cabeceiras de Basto' },
            { label: 'Celorico de Basto', value: 'Celorico de Basto' },
            { label: 'Esposende', value: 'Esposende' },
            { label: 'Fafe', value: 'Fafe' },
            { label: 'Guimarães', value: 'Guimarães' },
            { label: 'Póvoa de Lanhoso', value: 'Póvoa de Lanhoso' },
            { label: 'Terras de Bouro', value: 'Terras de Bouro' },
            { label: 'Vieira do Minho', value: 'Vieira do Minho' },
            { label: 'Vila Nova de Famalicão', value: 'Vila Nova de Famalicão' },
            { label: 'Vila Verde', value: 'Vila Verde' },
            { label: 'Vizela', value: 'Vizela' }
        ]
    },
    {
        district: 'Bragança',
        cities: [
            { label: 'Alfândega da Fé', value: 'Alfândega da Fé' },
            { label: 'Bragança', value: 'Bragança' },
            { label: 'Carrazeda de Ansiães', value: 'Carrazeda de Ansiães' },
            { label: 'Freixo de Espada à Cinta', value: 'Freixo de Espada à Cinta' },
            { label: 'Macedo de Cavaleiros', value: 'Macedo de Cavaleiros' },
            { label: 'Miranda do Douro', value: 'Miranda do Douro' },
            { label: 'Mirandela', value: 'Mirandela' },
            { label: 'Mogadouro', value: 'Mogadouro' },
            { label: 'Torre de Moncorvo', value: 'Torre de Moncorvo' },
            { label: 'Vila Flor', value: 'Vila Flor' },
            { label: 'Vimioso', value: 'Vimioso' },
            { label: 'Vinhais ', value: 'Vinhais ' }
        ]
    },
    {
        district: 'Castelo Branco',
        cities: [
            { label: 'Belmonte', value: 'Belmonte' },
            { label: 'Castelo Branco', value: 'Castelo Branco' },
            { label: 'Covilhã', value: 'Covilhã' },
            { label: 'Fundão', value: 'Fundão' },
            { label: 'Idanha - a - Nova', value: 'Idanha - a - Nova' },
            { label: 'Oleiros', value: 'Oleiros' },
            { label: 'Penamacor', value: 'Penamacor' },
            { label: 'Proença - a - Nova', value: 'Proença - a - Nova' },
            { label: 'Sertã', value: 'Sertã' },
            { label: 'Vila de Rei', value: 'Vila de Rei' },
            { label: 'Vila Velha de Ródão', value: 'Vila Velha de Ródão' }
        ]
    },
    {
        district: 'Coimbra',
        cities: [
            { label: 'Arganil', value: 'Arganil' },
            { label: 'Cantanhede', value: 'Cantanhede' },
            { label: 'Coimbra', value: 'Coimbra' },
            { label: 'Condeixa-a-Nova', value: 'Condeixa-a-Nova' },
            { label: 'Figueira da Foz', value: 'Figueira da Foz' },
            { label: 'Góis', value: 'Góis' },
            { label: 'Lousã', value: 'Lousã' },
            { label: 'Mira', value: 'Mira' },
            { label: 'Miranda do Corvo', value: 'Miranda do Corvo' },
            { label: 'Montemor-o-Velho', value: 'Montemor-o-Velho' },
            { label: 'Oliveira do Hospital', value: 'Oliveira do Hospital' },
            { label: 'Pampilhosa da Serra', value: 'Pampilhosa da Serra' },
            { label: 'Penacova', value: 'Penacova' },
            { label: 'Penela', value: 'Penela' },
            { label: 'Soure', value: 'Soure' },
            { label: 'Tábua', value: 'Tábua' },
            { label: 'Vila Nova de Poiares', value: 'Vila Nova de Poiares' }
        ]
    },
    {
        district: 'Évora',
        cities: [
            { label: 'Alandroal', value: 'Alandroal' },
            { label: 'Arraiolos', value: 'Arraiolos' },
            { label: 'Borba', value: 'Borba' },
            { label: 'Estremoz', value: 'Estremoz' },
            { label: 'Évora', value: 'Évora' },
            { label: 'Montemor-o-Novo', value: 'Montemor-o-Novo' },
            { label: 'Mora', value: 'Mora' },
            { label: 'Mourão', value: 'Mourão' },
            { label: 'Olivença', value: 'Olivença' },
            { label: 'Portel', value: 'Portel' },
            { label: 'Redondo', value: 'Redondo' },
            { label: 'Reguengos de Monsaraz', value: 'Reguengos de Monsaraz' },
            { label: 'Vendas Novas', value: 'Vendas Novas' },
            { label: 'Viana do Alentejo', value: 'Viana do Alentejo' },
            { label: 'Vila Viçosa ', value: 'Vila Viçosa ' }
        ]
    },
    {
        district: 'Faro',
        cities: [
            { label: 'Albufeira', value: 'Albufeira' },
            { label: 'Alcoutim', value: 'Alcoutim' },
            { label: 'Aljezur', value: 'Aljezur' },
            { label: 'Castro Marim', value: 'Castro Marim' },
            { label: 'Faro', value: 'Faro' },
            { label: 'Lagoa', value: 'Lagoa' },
            { label: 'Lagos', value: 'Lagos' },
            { label: 'Loulé', value: 'Loulé' },
            { label: 'Monchique', value: 'Monchique' },
            { label: 'Olhão', value: 'Olhão' },
            { label: 'Portimão', value: 'Portimão' },
            { label: 'São Brás de Alportel', value: 'São Brás de Alportel' },
            { label: 'Silves', value: 'Silves' },
            { label: 'Tavira', value: 'Tavira' },
            { label: 'Vila do Bispo', value: 'Vila do Bispo' },
            { label: 'Vila Real de Santo António', value: 'Vila Real de Santo António' }
        ]
    },
    {
        district: 'Guarda',
        cities: [
            { label: 'Aguiar da Beira', value: 'Aguiar da Beira' },
            { label: 'Almeida', value: 'Almeida' },
            { label: 'Celorico da Beira', value: 'Celorico da Beira' },
            { label: 'Figueira de Castelo Rodrigo', value: 'Figueira de Castelo Rodrigo' },
            { label: 'Fornos de Algodres', value: 'Fornos de Algodres' },
            { label: 'Gouveia', value: 'Gouveia' },
            { label: 'Guarda', value: 'Guarda' },
            { label: 'Manteigas', value: 'Manteigas' },
            { label: 'Mêda', value: 'Mêda' },
            { label: 'Pinhel', value: 'Pinhel' },
            { label: 'Sabugal', value: 'Sabugal' },
            { label: 'Seia', value: 'Seia' },
            { label: 'Trancoso', value: 'Trancoso' },
            { label: 'Vila Nova de Foz Côa', value: 'Vila Nova de Foz Côa' }
        ]
    },
    {
        district: 'Leiria',
        cities: [
            { label: 'Alcobaça', value: 'Alcobaça' },
            { label: 'Alvaiázere', value: 'Alvaiázere' },
            { label: 'Ansião', value: 'Ansião' },
            { label: 'Batalha', value: 'Batalha' },
            { label: 'Bombarral', value: 'Bombarral' },
            { label: 'Caldas da Rainha', value: 'Caldas da Rainha' },
            { label: 'Castanheira de Pera', value: 'Castanheira de Pera' },
            { label: 'Figueiró dos Vinhos', value: 'Figueiró dos Vinhos' },
            { label: 'Leiria', value: 'Leiria' },
            { label: 'Marinha Grande', value: 'Marinha Grande' },
            { label: 'Nazaré', value: 'Nazaré' },
            { label: 'Óbidos', value: 'Óbidos' },
            { label: 'Pedrógão Grande', value: 'Pedrógão Grande' },
            { label: 'Peniche', value: 'Peniche' },
            { label: 'Pombal', value: 'Pombal' },
            { label: 'Porto de Mós', value: 'Porto de Mós' }
        ]
    },
    {
        district: 'Lisboa',
        cities: [
            { label: 'Alenquer', value: 'Alenquer' },
            { label: 'Amadora', value: 'Amadora' },
            { label: 'Arruda dos Vinhos', value: 'Arruda dos Vinhos' },
            { label: 'Azambuja', value: 'Azambuja' },
            { label: 'Cadaval', value: 'Cadaval' },
            { label: 'Cascais', value: 'Cascais' },
            { label: 'Lisboa', value: 'Lisboa' },
            { label: 'Loures', value: 'Loures' },
            { label: 'Lourinhã', value: 'Lourinhã' },
            { label: 'Mafra', value: 'Mafra' },
            { label: 'Odivelas', value: 'Odivelas' },
            { label: 'Oeiras', value: 'Oeiras' },
            { label: 'Sintra', value: 'Sintra' },
            { label: 'Sobral de Monte Agraço', value: 'Sobral de Monte Agraço' },
            { label: 'Torres Vedras', value: 'Torres Vedras' },
            { label: 'Vila Franca de Xira', value: 'Vila Franca de Xira' }
        ]
    },
    {
        district: 'Madeira',
        cities: [
            { label: 'Calheta', value: 'Calheta' },
            { label: 'Câmara de Lobos', value: 'Câmara de Lobos' },
            { label: 'Funchal', value: 'Funchal' },
            { label: 'Machico', value: 'Machico' },
            { label: 'Ponta do Sol', value: 'Ponta do Sol' },
            { label: 'Porto Moniz', value: 'Porto Moniz' },
            { label: 'Porto Santo', value: 'Porto Santo' },
            { label: 'Ribeira Brava', value: 'Ribeira Brava' },
            { label: 'Santa Cruz', value: 'Santa Cruz' },
            { label: 'Santana', value: 'Santana' },
            { label: 'São Vicente', value: 'São Vicente' }
        ]
    },
    {
        district: 'Portalegre',
        cities: [
            { label: 'Alter do Chão', value: 'Alter do Chão' },
            { label: 'Arronches', value: 'Arronches' },
            { label: 'Avis', value: 'Avis' },
            { label: 'Campo Maior', value: 'Campo Maior' },
            { label: 'Castelo de Vide', value: 'Castelo de Vide' },
            { label: 'Crato', value: 'Crato' },
            { label: 'Elvas', value: 'Elvas' },
            { label: 'Fronteira', value: 'Fronteira' },
            { label: 'Gavião', value: 'Gavião' },
            { label: 'Marvão', value: 'Marvão' },
            { label: 'Monforte', value: 'Monforte' },
            { label: 'Nisa', value: 'Nisa' },
            { label: 'Ponte de Sor', value: 'Ponte de Sor' },
            { label: 'Portalegre', value: 'Portalegre' },
            { label: 'Sousel', value: 'Sousel' }
        ]
    },
    {
        district: 'Porto',
        cities: [
            { label: 'Amarante', value: 'Amarante' },
            { label: 'Baião', value: 'Baião' },
            { label: 'Felgueiras', value: 'Felgueiras' },
            { label: 'Gondomar', value: 'Gondomar' },
            { label: 'Lousada', value: 'Lousada' },
            { label: 'Maia', value: 'Maia' },
            { label: 'Marco de Canaveses', value: 'Marco de Canaveses' },
            { label: 'Matosinhos', value: 'Matosinhos' },
            { label: 'Paços de Ferreira', value: 'Paços de Ferreira' },
            { label: 'Paredes', value: 'Paredes' },
            { label: 'Penafiel', value: 'Penafiel' },
            { label: 'Porto', value: 'Porto' },
            { label: 'Póvoa de Varzim', value: 'Póvoa de Varzim' },
            { label: 'Santo Tirso', value: 'Santo Tirso' },
            { label: 'Trofa', value: 'Trofa' },
            { label: 'Valongo', value: 'Valongo' },
            { label: 'Vila do Conde', value: 'Vila do Conde' },
            { label: 'Vila Nova de Gaia', value: 'Vila Nova de Gaia' }
        ]
    },
    {
        district: 'Santarém',
        cities: [
            { label: 'Abrantes', value: 'Abrantes' },
            { label: 'Alcanena', value: 'Alcanena' },
            { label: 'Almeirim', value: 'Almeirim' },
            { label: 'Alpiarça', value: 'Alpiarça' },
            { label: 'Benavente', value: 'Benavente' },
            { label: 'Cartaxo', value: 'Cartaxo' },
            { label: 'Chamusca', value: 'Chamusca' },
            { label: 'Constância', value: 'Constância' },
            { label: 'Coruche', value: 'Coruche' },
            { label: 'Entroncamento', value: 'Entroncamento' },
            { label: 'Ferreira do Zêzere', value: 'Ferreira do Zêzere' },
            { label: 'Golegã', value: 'Golegã' },
            { label: 'Mação', value: 'Mação' },
            { label: 'Ourém', value: 'Ourém' },
            { label: 'Rio Maior', value: 'Rio Maior' },
            { label: 'Salvaterra de Magos', value: 'Salvaterra de Magos' },
            { label: 'Santarém', value: 'Santarém' },
            { label: 'Sardoal', value: 'Sardoal' },
            { label: 'Tomar', value: 'Tomar' },
            { label: 'Torres Novas', value: 'Torres Novas' },
            { label: 'Vila Nova da Barquinha', value: 'Vila Nova da Barquinha' }
        ]
    },
    {
        district: 'Setúbal',
        cities: [
            { label: 'Alcácer do Sal', value: 'Alcácer do Sal' },
            { label: 'Alcochete', value: 'Alcochete' },
            { label: 'Almada', value: 'Almada' },
            { label: 'Barreiro', value: 'Barreiro' },
            { label: 'Grândola', value: 'Grândola' },
            { label: 'Moita', value: 'Moita' },
            { label: 'Montijo', value: 'Montijo' },
            { label: 'Palmela', value: 'Palmela' },
            { label: 'Santiago do Cacém', value: 'Santiago do Cacém' },
            { label: 'Seixal', value: 'Seixal' },
            { label: 'Sesimbra', value: 'Sesimbra' },
            { label: 'Setúbal', value: 'Setúbal' },
            { label: 'Sines', value: 'Sines' }
        ]
    },
    {
        district: 'Viana do Castelo',
        cities: [
            { label: 'Arcos de Valdevez', value: 'Arcos de Valdevez' },
            { label: 'Caminha', value: 'Caminha' },
            { label: 'Melgaço', value: 'Melgaço' },
            { label: 'Monção', value: 'Monção' },
            { label: 'Paredes de Coura', value: 'Paredes de Coura' },
            { label: 'Ponte da Barca', value: 'Ponte da Barca' },
            { label: 'Ponte de Lima', value: 'Ponte de Lima' },
            { label: 'Valença', value: 'Valença' },
            { label: 'Viana do Castelo', value: 'Viana do Castelo' },
            { label: 'Vila Nova de Cerveira', value: 'Vila Nova de Cerveira' }
        ]
    },
    {
        district: 'Vila Real',
        cities: [
            { label: 'Alijó', value: 'Alijó' },
            { label: 'Boticas', value: 'Boticas' },
            { label: 'Chaves', value: 'Chaves' },
            { label: 'Mesão Frio', value: 'Mesão Frio' },
            { label: 'Mondim de Basto', value: 'Mondim de Basto' },
            { label: 'Montalegre', value: 'Montalegre' },
            { label: 'Murça', value: 'Murça' },
            { label: 'Peso da Régua', value: 'Peso da Régua' },
            { label: 'Ribeira de Pena', value: 'Ribeira de Pena' },
            { label: 'Sabrosa', value: 'Sabrosa' },
            { label: 'Santa Marta de Penaguião', value: 'Santa Marta de Penaguião' },
            { label: 'Valpaços', value: 'Valpaços' },
            { label: 'Vila Pouca de Aguiar', value: 'Vila Pouca de Aguiar' },
            { label: 'Vila Real', value: 'Vila Real' }
        ]
    },
    {
        district: 'Viseu',
        cities: [
            { label: 'Armamar', value: 'Armamar' },
            { label: 'Carregal do Sal', value: 'Carregal do Sal' },
            { label: 'Castro Daire', value: 'Castro Daire' },
            { label: 'Cinfães', value: 'Cinfães' },
            { label: 'Lamego', value: 'Lamego' },
            { label: 'Mangualde', value: 'Mangualde' },
            { label: 'Moimenta da Beira', value: 'Moimenta da Beira' },
            { label: 'Mortágua', value: 'Mortágua' },
            { label: 'Nelas', value: 'Nelas' },
            { label: 'Oliveira de Frades', value: 'Oliveira de Frades' },
            { label: 'Penalva do Castelo', value: 'Penalva do Castelo' },
            { label: 'Penedono', value: 'Penedono' },
            { label: 'Resende', value: 'Resende' },
            { label: 'Santa Comba Dão', value: 'Santa Comba Dão' },
            { label: 'São João da Pesqueira', value: 'São João da Pesqueira' },
            { label: 'São Pedro do Sul', value: 'São Pedro do Sul' },
            { label: 'Sátão', value: 'Sátão' },
            { label: 'Sernancelhe', value: 'Sernancelhe' },
            { label: 'Tabuaço', value: 'Tabuaço' },
            { label: 'Tarouca', value: 'Tarouca' },
            { label: 'Tondela', value: 'Tondela' },
            { label: 'Vila Nova de Paiva', value: 'Vila Nova de Paiva' },
            { label: 'Viseu', value: 'Viseu' },
            { label: 'Vouzela', value: 'Vouzela' }
        ]
    }
];

export const CONTRACT_STATUSES = [
    { value: "ACTIVE", label: "Activo" },
    { value: "DEACTIVATED", label: "Desativado" },
    { value: "EXPIRED", label: "Expirado" },
    { value: "RENEWING", label: "Em renovação" }
];

export const RENOVATION_PERIOD_UNITS = [
    { value: "days", label: "Dias" },
    { value: "weeks", label: "Semanas" },
    { value: "months", label: "Meses" },
    { value: "years", label: "Anos" }
];

export const CONTRACT_END_ACTIONS = [
    { value: "EXAMPLE", label: "Example" }
];

export const BILLING_CYCLE_OPTIONS = [
    { value: "0", label: "Uma só vez" },
    { value: "12", label: "Mensal", divisionLabel: "Mês" },
    { value: "4", label: "Trimestral", divisionLabel: "Trimestre" },
    { value: "3", label: "Quadrimestral", divisionLabel: "Quadrimestre" },
    { value: "2", label: "Semestral", divisionLabel: "Semestre" },
    { value: "1", label: "Anual", divisionLabel: "Ano" }
];

export const PER_PAGE_OPTIONS = [
    { label: "10 linhas", value: 10 },
    { label: "50 linhas", value: 50 },
    { label: "100 linhas", value: 100 }
];

export const REMINDER_MOTIVES = [
    { label: "Example", value: "EXAMPLE" },
    { label: "Example 2", value: "EXAMPLE_2" }
]

export const EVENT_DISPLAYS = {
    "EXAMPLE": { label: "Example", color: MediStrapProperties.colors.alert100 },
    "EXAMPLE_2": { label: "Example 2", color: MediStrapProperties.colors.brand100 }
};