import { useMutation } from "@apollo/react-hooks";
import { useEffect, useRef, useState } from "react";
import * as yup from 'yup';
import { AccordionContacts, AccordionContractCycle } from "../../../../components";
import { Components, MediStrap } from "../../../../MediStrap";
import { BILLING_CYCLE_OPTIONS, CONTRACT_END_ACTIONS, CONTRACT_STATUSES, RENOVATION_PERIOD_UNITS } from "../../../../utils/commonData";
import { MUTATION_CONTRACT_CREATE } from "../../graphql/mutations";
import AccordionAttachments from '../AccordionAttachments';
import AccordionBilling from '../AccordionBilling';
import AccordionLicensing from '../AccordionLicensing';
import AccordionNotes from '../AccordionNotes';
import InputSupportDays from "../InputSupportDays";

const MediStrapProperties = MediStrap.getProperties();

function ModalCreate({ isOpen, onRequestClose, onCreate, clients, categories }) {
    const createFormRef = useRef(null);
    const [contractStatus, setContractStatus] = useState(CONTRACT_STATUSES[0]);

    const [mutationContractCreate, { loading: loadingCreateContract }] = useMutation(
        MUTATION_CONTRACT_CREATE,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.contractCreate) {
                    onCreate();
                    onRequestClose();
                }
            }
        }
    );

    const createContract = (params = {}) => {
        const { code, client, category, description, support, contacts, filesToUpload, notes, licenses, billings, cycle } = params;

        mutationContractCreate({
            variables: {
                status: contractStatus.value,
                code,
                client,
                category,
                description: !!description ? description : undefined,
                support: !!support ? support : [],
                contacts: !!contacts ? contacts : [],
                filesToUpload: !!filesToUpload ? filesToUpload : [],
                notes: !!notes ? notes : [],
                licenses: !!licenses ? licenses : [],
                billings: !!billings ? billings : [],
                cycle
            }
        });
    }

    useEffect(() => {
        setContractStatus(CONTRACT_STATUSES[0]);
    }, [isOpen === false]);

    return (
        <Components.ModalThatSlides
            id="criar-contrato"
            title="Criar Contrato"
            width="100%"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            actions={
                <Components.Block row center>
                    <Components.Block mr={12}>
                        <Components.Button type="submit" loading={loadingCreateContract} label="Guardar" color="brand" onClick={() => { createFormRef.current.handleSubmit() }} />
                    </Components.Block>
                </Components.Block>
            }
            menu={[
                { icon: "Information", title: "Informações", anchor: "informations" },
                { icon: "Cicle", title: "Ciclo de contrato", anchor: "cycle" },
                { icon: "Paper", title: "Faturação", anchor: "billing" },
                { icon: "Contacts", title: "Pessoas de contato", anchor: "contacts" },
                { icon: "Anexo", title: "Anexos", anchor: "attachments" },
                { icon: "Notes", title: "Notas", anchor: "notes" },
                { icon: "Licenses", title: "Licenciamento", anchor: "licensing" }
            ]}
            menuActions={
                <Components.Block mb={24}>
                    <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100}>Estado</Components.Text>
                    <Components.Block mt={8}>
                        <Components.DropdownInput monocolor options={CONTRACT_STATUSES} value={CONTRACT_STATUSES.find((item) => item.value === contractStatus.value)} onChangeValue={(value) => setContractStatus(value)} />
                    </Components.Block>
                </Components.Block>
            }
        >
            <Components.Form
                initialValues={{
                    contractCycle: {
                        type: "FIXED_WITH_RENOVATION_PERIOD"
                    }
                }}
                schema={yup.object().shape({
                    code: yup.string().required(),
                    client: yup.object().required(),
                    category: yup.object().required(),
                    description: yup.string().nullable(),
                    support: yup.array(yup.object({
                        days: yup.number()
                    })),
                    contacts: yup.array(yup.object({
                        name: yup.string().nullable(),
                        email: yup.string().email().nullable(),
                        phone: yup.string().nullable()
                    })),
                    files: yup.array(),
                    notes: yup.array(yup.object({
                        title: yup.string().nullable(),
                        description: yup.string().nullable()
                    })),
                    licensing: yup.array(yup.object()),
                    billings: yup.array(yup.object({
                        amount: yup.number().required(),
                        cycleOption: yup.object().required(),
                        partitionType: yup.string(),
                        partitionValue: yup.array(),
                        startDate: yup.date().nullable().transform((currentValue, originalValue) => originalValue === "" ? null : currentValue),
                        endDate: yup.date().nullable().transform((currentValue, originalValue) => originalValue === "" ? null : currentValue),
                        description: yup.string(),
                        reference: yup.string(),
                        softwareCode: yup.string()
                    })),
                    contractCycle: yup.object({
                        type: yup.string().required(),
                        startDate: yup.date().required().nullable().transform((currentValue, originalValue) => originalValue === "" ? null : currentValue),
                        endDate: yup.date().min(yup.ref('startDate'))
                            .when("type", {
                                is: (value) => value === "OPEN",
                                then: yup.date().nullable().transform((currentValue, originalValue) => originalValue === "" ? null : currentValue),
                                otherwise: yup.date().required().nullable().transform((currentValue, originalValue) => originalValue === "" ? null : currentValue)
                            }),
                        extentionPeriods: yup.object().nullable(),
                        termNotification: yup.object().nullable(),
                        description: yup.string().nullable(),
                        endAction: yup.object().nullable()
                    })
                })}
                innerRef={createFormRef}
                onSubmit={(values) => {
                    let cycle = {};
                    if (!!values && !!values.contractCycle && (values.contractCycle.type === 'OPEN')) {
                        cycle = { type: values.contractCycle.type, startDate: values.contractCycle.startDate };
                    } else if (!!values && !!values.contractCycle && (values.contractCycle.type === 'FIXED')) {
                        cycle = {
                            type: values.contractCycle.type,
                            startDate: values.contractCycle.startDate,
                            endDate: values.contractCycle.endDate,
                            termNotification: !!values.contractCycle.termNotification ? { value: parseInt(values.contractCycle.termNotification.number), unit: values.contractCycle.termNotification.select.value } : undefined,
                            description: values.contractCycle.description,
                            endAction: !!values.contractCycle.endAction ? values.contractCycle.endAction.value : undefined
                        };
                    } else {
                        cycle = {
                            type: values.contractCycle.type,
                            startDate: values.contractCycle.startDate,
                            endDate: values.contractCycle.endDate,
                            termNotification: !!values.contractCycle.termNotification ? { value: parseInt(values.contractCycle.termNotification.number), unit: values.contractCycle.termNotification.select.value } : undefined,
                            extensionPeriod: !!values.contractCycle.extensionPeriod ? { value: parseInt(values.contractCycle.extensionPeriod.number), unit: values.contractCycle.extensionPeriod.select.value } : undefined,
                            description: values.contractCycle.description,
                            endAction: !!values.contractCycle.endAction ? values.contractCycle.endAction.value : undefined
                        };
                    }

                    const billings = !!values.billings && values.billings.map((item) => {
                        if ((!!item.cycleOption && item.cycleOption.value === "0") || !item.cycleOption) {
                            return ({
                                amount: parseFloat(item.amount),
                                frequency: 0,
                                partitionType: 0,
                                partitionValue: [parseFloat(item.amount)],
                                startDate: !!item.startDate ? item.startDate : undefined,
                                endDate: !!item.endDate ? item.endDate : undefined,
                                description: !!item.description ? item.description : undefined,
                                softwareCode: !!item.softwareCode ? item.softwareCode : undefined,
                                reference: !!item.reference ? item.reference : undefined
                            })
                        } else {
                            let partitionValue;
                            if (item.partitionType === "0") {
                                partitionValue = !!item.amount ? (parseFloat(item.amount) / item.cycleOption.value) : undefined;
                            } else {
                                partitionValue = !!item.amountPart ? item.amountPart.map((item) => parseFloat(item)) : undefined;
                            }
                            return ({
                                amount: !!item.amount ? parseFloat(item.amount) : undefined,
                                frequency: parseInt(item.cycleOption.value),
                                partitionType: parseInt(item.partitionType),
                                partitionValue,
                                startDate: !!item.startDate ? item.startDate : undefined,
                                endDate: !!item.endDate ? item.endDate : undefined,
                                description: !!item.description ? item.description : undefined,
                                softwareCode: !!item.softwareCode ? item.softwareCode : undefined,
                                reference: !!item.reference ? item.reference : undefined
                            })
                        }
                    })
                    createContract({
                        code: values.code,
                        client: values.client.value,
                        category: values.category.value,
                        description: !!values.description && values.description,
                        support: !!values.support && values.support.map((item) => parseInt(item.days)),
                        contacts: !!values.contacts && values.contacts,
                        filesToUpload: values.files,
                        notes: !!values.notes && values.notes,
                        licenses: !!values.licensing && values.licensing.map((item) => item.id),
                        billings,
                        cycle
                    });
                }}
            >
                {({
                    values,
                    errors,
                    handleSubmit,
                }) => {
                    return (
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <Components.Portlet icon="Information" title="Informações" anchor="informations">
                                <Components.FormGroup title="Geral" description="Informação geral do contrato e cliente">
                                    <Components.Block width={652}>
                                        <Components.Input error={errors.code} Component={Components.TextInput} name="code" label="Código*" description="Código identificativo do contrato em listagem" placeholder="Código do contrato" />
                                    </Components.Block>
                                    <Components.Block row>
                                        <Components.Block width={304} pr={10}>
                                            <Components.Input error={errors.client} Component={Components.DropdownInput} name="client" label="Cliente*" placeholder="Selecionar cliente" options={clients} />
                                            {/*<Components.Block pointer row mt={8} center>
                                                <Components.Icon name="Plus" />
                                                <Components.Text size={12} height={16} color={MediStrapProperties.colors.grey60}>Novo cliente</Components.Text>
                                            </Components.Block>*/}
                                        </Components.Block>
                                        <Components.Block width={212} pl={10}>
                                            <Components.Input error={errors.category} Component={Components.DropdownInput} name="category" label="Categoria*" placeholder="Selecione categoria" options={categories} />
                                            {/*<Components.Block pointer row mt={8} center>
                                                <Components.Icon name="Plus" />
                                                <Components.Text size={12} height={16} color={MediStrapProperties.colors.grey60}>Nova categoria</Components.Text>
                                            </Components.Block>*/}
                                        </Components.Block>
                                    </Components.Block>
                                    <Components.Input Component={Components.TextAreaInput} name="description" label="Descrição" placeholder="Descrição do contrato" />
                                </Components.FormGroup>
                                <Components.Input Component={InputSupportDays} name="support" />
                            </Components.Portlet>
                            <Components.Input Component={AccordionContractCycle} name="contractCycle" anchor="cycle" errors={errors} renovationPeriodUnits={RENOVATION_PERIOD_UNITS} contractEndActions={CONTRACT_END_ACTIONS} />
                            <Components.Input Component={AccordionBilling} name="billings" anchor="billing" errors={errors} billingCycleOptions={BILLING_CYCLE_OPTIONS} />
                            <Components.Input Component={AccordionContacts} name="contacts" anchor="contacts" errors={errors} />
                            <Components.Input Component={AccordionAttachments} name="files" anchor="attachments" />
                            <Components.Input Component={AccordionNotes} name="notes" anchor="notes" errors={errors} />
                            <Components.Input Component={AccordionLicensing} name="licensing" anchor="licensing" />
                        </form>
                    )
                }}
            </Components.Form>
        </Components.ModalThatSlides >
    );
}

export default ModalCreate;