import { useLazyQuery } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { Components, MediStrap } from "../../../../MediStrap";
import { TabMenuSettings } from "../../components";
import { QUERY_USERS } from '../../graphql/queries';
import { PER_PAGE_OPTIONS } from '../../../../utils/commonData';
import ModalCreate from './ModalCreate';
import ModalUpdate from './ModalUpdate';

const MediStrapProperties = MediStrap.getProperties();
const perPageOptions = PER_PAGE_OPTIONS;

function Accounts() {
    const [isModalCreateOpen, setModalCreateOpen] = useState(false);
    const [isModalUpdateOpen, setModalUpdateOpen] = useState(false);
    const [accountId, setAccountId] = useState(false);

    const [accounts, setAccounts] = useState({
        rows: [],
        pagination: {
            total: 0,
            perPage: 10,
            lastPage: 0,
            currentPage: 1,
            hasPreviousPage: false,
            hasNextPage: false,
            // orderBy: "name_ASC"
        }
    });
    const [orderBy, setOrderBy] = useState(null);
    const [search, setSearch] = useState("");

    const [queryAccounts] = useLazyQuery(
        QUERY_USERS,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.users) {
                    setAccounts(data.users);
                }
            }
        }
    );

    const requestAccounts = (params = {}) => {
        const { perPage, currentPage } = params;

        queryAccounts({
            variables: {
                perPage: perPage || accounts.pagination.perPage,
                currentPage: currentPage || accounts.pagination.currentPage,
                search: search !== "" ? search : undefined,
                orderBy: !!orderBy ? `${orderBy.column}_${orderBy.order}` : undefined
            }
        });
    }

    useEffect(() => {
        requestAccounts({ perPage: accounts.pagination.perPage, currentPage: 1 });
    }, [search, orderBy]);

    return (
        <>
            <Components.Block row space="between">
                <Components.Block row>
                    <Components.Block width={444}>
                        <Components.TextInput icon="Search" placeholder="Procurar conta" value={search} onChangeValue={(value) => setSearch(value)} />
                    </Components.Block>
                    <Components.Block row center width={163} ml={20}>
                        <Components.DropdownInput onChangeValue={(option) => requestAccounts({ perPage: option.value, currentPage: 1 })} value={perPageOptions.find((item) => item.value == accounts.pagination.perPage)} options={perPageOptions} />
                    </Components.Block>
                    <Components.Block ml={12}>
                        <Components.Navigation
                            perPage={accounts.pagination.perPage}
                            currentPage={accounts.pagination.currentPage}
                            total={accounts.pagination.total}
                            hasPreviousPage={accounts.pagination.hasPreviousPage}
                            hasNextPage={accounts.pagination.hasNextPage}
                            onClickPrevious={() => requestAccounts({ currentPage: (accounts.pagination.currentPage - 1) })}
                            onClickNext={() => requestAccounts({ currentPage: (accounts.pagination.currentPage + 1) })}
                        />
                    </Components.Block>
                </Components.Block>
                <Components.Block row>
                    <Components.Button color="brand" label="Criar conta" onClick={() => setModalCreateOpen(true)} />
                </Components.Block>
            </Components.Block>
            <Components.Block flex pt={32} style={{ overflow: "hidden" }}>
                <TabMenuSettings />
                <Components.AdvancedTable
                    columns={[
                        {
                            label: "Nome",
                            value: "name",
                            order: false
                        },
                        {
                            label: "Cargo",
                            value: "role",
                            width: 484,
                            order: false
                        },
                        {
                            label: "Email",
                            value: "email",
                            width: 343,
                            order: false
                        }
                    ]}
                    rows={accounts.rows.map((account) => {
                        return ({
                            name: <Components.Block row center height={56}>
                                <Components.Block mr={12} pointer>
                                    <Components.Button type="button" icon="Edit" size="squareSmall" color="empty" onClick={() => { setAccountId(account.id); setModalUpdateOpen(true); }} />
                                </Components.Block>
                                <Components.Block>
                                    <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100} title="Sigma">{account.name}</Components.Text>
                                </Components.Block>
                            </Components.Block>,
                            role: account.position,
                            email: account.email,

                        })
                    })}
                    noBorderRadius
                />
            </Components.Block>

            <ModalCreate
                onRequestClose={() => setModalCreateOpen(false)}
                onCreate={() => requestAccounts({ currentPage: 1 })}
                isOpen={isModalCreateOpen}
            />

            <ModalUpdate
                onRequestClose={() => {
                    setAccountId(null);
                    setModalUpdateOpen(false);
                }}
                onUpdate={(updateAccount) => {
                    const index = accounts.rows.findIndex((item) => item.id == updateAccount.id);
                    accounts.rows[index] = updateAccount;
                    setAccounts({ ...accounts });
                }}
                onDelete={() => {
                    requestAccounts();
                }}
                isOpen={isModalUpdateOpen}
                accountId={accountId}
            />
        </>
    );
}

export default Accounts;
