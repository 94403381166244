import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useEffect, useRef, useState } from "react";
import * as yup from 'yup';
import { AccordionContacts, AccordionContractCycle } from "../../../../components";
import { Components, MediStrap } from "../../../../MediStrap";
import { getClientToken } from "../../../../utils/client";
import { MUTATION_CONTRACT_DELETE, MUTATION_CONTRACT_UPDATE } from "../../graphql/mutations";
import AccordionAttachments from '../AccordionAttachments';
import AccordionBilling from '../AccordionBilling';
import AccordionLicensing from '../AccordionLicensing';
import AccordionNotes from '../AccordionNotes';
import InputSupportDays from "../InputSupportDays";
import { CONTRACT_STATUSES, RENOVATION_PERIOD_UNITS, CONTRACT_END_ACTIONS, BILLING_CYCLE_OPTIONS } from "../../../../utils/commonData";
import { QUERY_CONTRACT } from "../../graphql/queries";

const MediStrapProperties = MediStrap.getProperties();

function ModalUpdate({ isOpen, onRequestClose, onUpdate, onDelete, clients, categories, contractId }) {
    const [fetchingExport, setFetchingExport] = useState(false);
    const updateFormRef = useRef(null);
    const [contractStatus, setContractStatus] = useState(CONTRACT_STATUSES[0]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [contract, setContract] = useState(null);

    const [queryContract] = useLazyQuery(
        QUERY_CONTRACT,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.contract) {
                    setContract(data.contract);
                }
            }
        }
    );

    const requestContract = (params = {}) => {
        const { id } = params;

        queryContract({
            variables: {
                id
            }
        });
    }

    useEffect(() => {
        if (!!contractId) {
            requestContract({ id: contractId });
        }
    }, [contractId]);

    useEffect(() => {
        !!contract && setContractStatus(CONTRACT_STATUSES.find((item) => item.value === contract.status));
    }, [contract])

    const [mutationContractUpdate, { loading: loadingContractUpdate }] = useMutation(
        MUTATION_CONTRACT_UPDATE,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.contractUpdate) {
                    onUpdate(data.contractUpdate);
                    onRequestClose();
                }
            }
        }
    );

    const updateContract = (params = {}) => {
        const { code, client, category, description, support, contacts, filesToUpload, files, notes, licenses, billings, cycle } = params;

        mutationContractUpdate({
            variables: {
                id: contract.id,
                status: contractStatus.value,
                code,
                client,
                category,
                description: !!description ? description : undefined,
                support: !!support ? support : [],
                contacts: !!contacts ? contacts : [],
                filesToUpload: !!filesToUpload ? filesToUpload : [],
                files: !!files ? files : [],
                notes: !!notes ? notes : [],
                licenses: !!licenses ? licenses : [],
                billings: !!billings ? billings : [],
                cycle
            }
        });
    }

    const [mutationContractDelete, { loading: loadingDelete }] = useMutation(
        MUTATION_CONTRACT_DELETE,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.contractDelete) {
                    onDelete(data.contractDelete);
                }
            }
        }
    );

    const deleteContract = (params = {}) => {
        const { id } = params;

        mutationContractDelete({
            variables: {
                id
            }
        });
    }

    useEffect(() => {
        if (!isOpen) {
            setContract(null);
        }
    }, [isOpen])
    const exportRequest = () => {
        if (!fetchingExport) {
            setFetchingExport(true);

            fetch(`${process.env.REACT_APP_BACKEND_HOST}/export/contracts`, {
                method: "POST",
                headers: { "Authorization": getClientToken(), "Content-Type": "application/json" },
                body: JSON.stringify({
                    contractId
                })
            })
                .then(res => res.blob())
                .then(blob => {
                    var file = window.URL.createObjectURL(blob);
                    window.open(file, "_blank");
                    setFetchingExport(false);
                })
                .catch(() => {
                    setFetchingExport(false);
                });
        }
    }
    return (
        <Components.ModalThatSlides
            id="update-contrato"
            title="Editar Contrato"
            width="100%"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            actions={
                <Components.Block row center>
                    <Components.Block mr={12}>
                        <Components.Button type="submit" loading={loadingContractUpdate} label="Guardar" color="brand" onClick={() => { updateFormRef.current.handleSubmit() }} />
                    </Components.Block>
                    <Components.Block>
                        <Components.Button loading={loadingDelete} icon="Trash" color="red" size="square" onClick={() => setIsDeleteModalOpen(true)} />
                    </Components.Block>
                </Components.Block>
            }
            menu={[
                { icon: "Information", title: "Informações", anchor: "informations" },
                { icon: "Cicle", title: "Ciclo de contrato", anchor: "cycle" },
                { icon: "Paper", title: "Faturação", anchor: "billing" },
                { icon: "Contacts", title: "Pessoas de contato", anchor: "contacts" },
                { icon: "Anexo", title: "Anexos", anchor: "attachments" },
                { icon: "Notes", title: "Notas", anchor: "notes" },
                { icon: "Licenses", title: "Licenciamento", anchor: "licensing" }
            ]}
            menuActions={
                <Components.Block mb={24}>
                    <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100}>Estado</Components.Text>
                    <Components.Block mt={8}>
                        <Components.DropdownInput monocolor options={CONTRACT_STATUSES} value={CONTRACT_STATUSES.find((item) => !!contractStatus && item.value === contractStatus.value)} onChangeValue={(value) => setContractStatus(value)} />
                    </Components.Block>
                </Components.Block>
            }
            onClickExport={exportRequest}
            loadingExport={fetchingExport}
        >
            {!!contract ?
                <>
                    <Components.Form
                        initialValues={!!contract && {
                            code: contract.code,
                            client: clients.find((item) => item.value === contract.client.id),
                            category: categories.find((item) => item.value === contract.category.id),
                            description: contract.description,
                            support: contract.support.map((item) => ({ days: item })),
                            contractCycle: !!contract.cycle && {
                                ...contract.cycle,
                                extensionPeriod: !!contract.cycle.extensionPeriod ? { number: contract.cycle.extensionPeriod.value + "", select: RENOVATION_PERIOD_UNITS.find((item) => item.value === contract.cycle.extensionPeriod.unit) } : {},
                                termNotification: !!contract.cycle.termNotification ? { number: contract.cycle.termNotification.value + "", select: RENOVATION_PERIOD_UNITS.find((item) => item.value === contract.cycle.termNotification.unit) } : {},
                                endAction: CONTRACT_END_ACTIONS.find((item) => item.value === contract.cycle.endAction)
                            },
                            contacts: !!contract.contacts && contract.contacts.map((item) => { return { name: item.name, phone: item.phone, email: item.email } }),
                            files: !!contract.files && contract.files.map((item) => { return { name: item.name, date: item.date, url: item.url } }),
                            notes: !!contract.notes && contract.notes.map((item) => { return { title: item.title, description: item.description } }),
                            licensing: contract.licenses,
                            billings: contract.billings.map((billing) => ({
                                ...billing,
                                startDate: !!billing.startDate ? billing.startDate : undefined,
                                cycleOption: BILLING_CYCLE_OPTIONS.find((item) => item.value == billing.frequency),
                                partitionType: billing.partitionType + "",
                                amountPart: billing.partitionValue
                            }))
                        }}
                        schema={yup.object().shape({
                            code: yup.string().required(),
                            client: yup.object().required(),
                            category: yup.object().required(),
                            description: yup.string().nullable(),
                            support: yup.array(yup.object({
                                days: yup.number()
                            })),
                            contacts: yup.array(yup.object({
                                name: yup.string().nullable(),
                                email: yup.string().email().nullable(),
                                phone: yup.string().nullable()
                            })),
                            files: yup.array(),
                            notes: yup.array(yup.object({
                                title: yup.string().nullable(),
                                description: yup.string().nullable()
                            })),
                            licensing: yup.array(yup.object()),
                            billings: yup.array(yup.object({
                                amount: yup.number().required(),
                                cycleOption: yup.object().required(),
                                partitionType: yup.string(),
                                partitionValue: yup.array(),
                                startDate: yup.date().nullable().transform((currentValue, originalValue) => originalValue === "" ? null : currentValue),
                                endDate: yup.date().nullable().transform((currentValue, originalValue) => originalValue === "" ? null : currentValue),
                                description: yup.string().nullable(),
                                reference: yup.string().nullable(),
                                softwareCode: yup.string().nullable()
                            })),
                            contractCycle: yup.object({
                                type: yup.string().required(),
                                startDate: yup.date().required().nullable().transform((currentValue, originalValue) => originalValue === "" ? null : currentValue),
                                endDate: yup.date().min(yup.ref('startDate'))
                                    .when("type", {
                                        is: (value) => value === "OPEN",
                                        then: yup.date().nullable().transform((currentValue, originalValue) => originalValue === "" ? null : currentValue),
                                        otherwise: yup.date().required().nullable().transform((currentValue, originalValue) => originalValue === "" ? null : currentValue)
                                    }),
                                extensionPeriod: yup.object().nullable(),
                                termNotification: yup.object().nullable(),
                                description: yup.string().nullable(),
                                endAction: yup.object().nullable()
                            })
                        })}
                        innerRef={updateFormRef}
                        onSubmit={(values) => {
                            let cycle = {};
                            if (!!values && !!values.contractCycle && (values.contractCycle.type === 'OPEN')) {
                                cycle = { type: values.contractCycle.type, startDate: values.contractCycle.startDate };
                            } else if (!!values && !!values.contractCycle && (values.contractCycle.type === 'FIXED')) {
                                cycle = {
                                    type: values.contractCycle.type,
                                    startDate: values.contractCycle.startDate,
                                    endDate: values.contractCycle.endDate,
                                    termNotification: !!values.contractCycle.termNotification && !!values.contractCycle.termNotification.select ? { value: parseInt(values.contractCycle.termNotification.number), unit: values.contractCycle.termNotification.select.value } : undefined,
                                    description: values.contractCycle.description,
                                    endAction: !!values.contractCycle.endAction ? values.contractCycle.endAction.value : undefined
                                };
                            } else {
                                cycle = {
                                    type: values.contractCycle.type,
                                    startDate: values.contractCycle.startDate,
                                    endDate: values.contractCycle.endDate,
                                    termNotification: !!values.contractCycle.termNotification && !!values.contractCycle.termNotification.select ? { value: parseInt(values.contractCycle.termNotification.number), unit: values.contractCycle.termNotification.select.value } : undefined,
                                    extensionPeriod: !!values.contractCycle.extensionPeriod && !!values.contractCycle.extensionPeriod.select ? { value: parseInt(values.contractCycle.extensionPeriod.number), unit: values.contractCycle.extensionPeriod.select.value } : undefined,
                                    description: values.contractCycle.description,
                                    endAction: !!values.contractCycle.endAction ? values.contractCycle.endAction.value : undefined
                                };
                            }

                            const billings = !!values.billings && values.billings.map((item) => {
                                if ((!!item.cycleOption && item.cycleOption.value === "0") || !item.cycleOption) {
                                    return ({
                                        amount: parseFloat(item.amount),
                                        frequency: 0,
                                        partitionType: 0,
                                        partitionValue: [parseFloat(item.amount)],
                                        startDate: !!item.startDate ? item.startDate : undefined,
                                        endDate: !!item.endDate ? item.endDate : undefined,
                                        description: !!item.description ? item.description : undefined,
                                        softwareCode: !!item.softwareCode ? item.softwareCode : undefined,
                                        reference: !!item.reference ? item.reference : undefined
                                    })
                                } else {
                                    let partitionValue;
                                    if (item.partitionType === "0") {
                                        partitionValue = !!item.amount ? (parseFloat(item.amount) / item.cycleOption.value) : undefined;
                                    } else {
                                        partitionValue = !!item.amountPart ? item.amountPart.map((item) => parseFloat(item)) : undefined;
                                    }
                                    return ({
                                        amount: !!item.amount ? parseFloat(item.amount) : undefined,
                                        frequency: parseInt(item.cycleOption.value),
                                        partitionType: parseInt(item.partitionType),
                                        partitionValue,
                                        startDate: !!item.startDate ? item.startDate : undefined,
                                        endDate: !!item.endDate ? item.endDate : undefined,
                                        description: !!item.description ? item.description : undefined,
                                        softwareCode: !!item.softwareCode ? item.softwareCode : undefined,
                                        reference: !!item.reference ? item.reference : undefined
                                    })
                                }
                            })
                            updateContract({
                                code: values.code,
                                client: values.client.value,
                                category: values.category.value,
                                description: !!values.description && values.description,
                                support: !!values.support && values.support.map((item) => parseInt(item.days)),
                                contacts: !!values.contacts && values.contacts,
                                filesToUpload: values.files.filter((attachment) => !attachment.url),
                                files: values.files.filter((attachment) => !!attachment.url),
                                notes: !!values.notes && values.notes,
                                licenses: !!values.licensing && values.licensing.map((item) => item.id),
                                billings,
                                cycle
                            });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Components.Portlet icon="Information" title="Informações" anchor="informations">
                                        <Components.FormGroup title="Geral" description="Informação geral do contrato e cliente">
                                            <Components.Block width={652}>
                                                <Components.Input error={errors.code} Component={Components.TextInput} name="code" label="Código*" description="Código identificativo do contrato em listagem" placeholder="Código do contrato" />
                                            </Components.Block>
                                            <Components.Block row>
                                                <Components.Block width={304} pr={10}>
                                                    <Components.Input error={errors.client} Component={Components.DropdownInput} name="client" label="Cliente*" placeholder="Selecionar cliente" options={clients} />
                                                    {/*<Components.Block pointer row mt={8} center>
                                                <Components.Icon name="Plus" />
                                                <Components.Text size={12} height={16} color={MediStrapProperties.colors.grey60}>Novo cliente</Components.Text>
                                            </Components.Block>*/}
                                                </Components.Block>
                                                <Components.Block width={212} pl={10}>
                                                    <Components.Input error={errors.category} Component={Components.DropdownInput} name="category" label="Categoria*" placeholder="Selecione categoria" options={categories} />
                                                    {/*<Components.Block pointer row mt={8} center>
                                                <Components.Icon name="Plus" />
                                                <Components.Text size={12} height={16} color={MediStrapProperties.colors.grey60}>Nova categoria</Components.Text>
                                            </Components.Block>*/}
                                                </Components.Block>
                                            </Components.Block>
                                            <Components.Input Component={Components.TextAreaInput} name="description" label="Descrição" placeholder="Descrição do contrato" />
                                        </Components.FormGroup>
                                        <Components.Input Component={InputSupportDays} name="support" />
                                    </Components.Portlet>
                                    <Components.Input Component={AccordionContractCycle} name="contractCycle" anchor="cycle" errors={errors} renovationPeriodUnits={RENOVATION_PERIOD_UNITS} contractEndActions={CONTRACT_END_ACTIONS} />
                                    <Components.Input Component={AccordionBilling} name="billings" anchor="billing" errors={errors} billingCycleOptions={BILLING_CYCLE_OPTIONS} />
                                    <Components.Input Component={AccordionContacts} name="contacts" anchor="contacts" errors={errors} />
                                    <Components.Input Component={AccordionAttachments} name="files" anchor="attachments" />
                                    <Components.Input Component={AccordionNotes} name="notes" anchor="notes" errors={errors} />
                                    <Components.Input Component={AccordionLicensing} name="licensing" anchor="licensing" contractLicenses={contract.licenses} />
                                </form>
                            )
                        }}
                    </Components.Form>
                    <Components.ActionPanel1
                        isOpen={isDeleteModalOpen}
                        onRequestClose={() => setIsDeleteModalOpen(false)}
                        title="Eliminar cliente"
                        description="Esta acção irá eliminar definitavamente todos os dados associados ao contrato, pretende mesmo assim continuar?"
                        confirmButtonProps={{
                            label: "Eliminar",
                            icon: "Trash",
                            onClick: () => { deleteContract({ id: contract.id }); setIsDeleteModalOpen(false); onRequestClose(); }
                        }}
                        cancelButtonProps={{
                            label: "Cancelar",
                            onClick: () => setIsDeleteModalOpen(false)
                        }}
                    />
                </>
                :
                <Components.Block center middle>
                    <Components.Loading color={MediStrapProperties.colors.brand100} />
                </Components.Block>}
        </Components.ModalThatSlides >
    );
}

export default ModalUpdate;