import React from "react";
import { Components, MediStrap } from "../../../../MediStrap";

const MediStrapProperties = MediStrap.getProperties();

function AccordionBilling({ name, value = [], anchor, errors, billingCycleOptions }) {
    return (
        <Components.FieldArray
            name={name}
            render={(arrayHelpers) => {
                return (
                    <Components.Portlet anchor={anchor} noInnerPadding icon="Paper" title="Faturação" actions={<Components.Button type="button" label="Adicionar Fatura" color="brand" size="small" onClick={() => arrayHelpers.insert(value.length + 1, { partitionType: "0", cycleOption: billingCycleOptions[0] })} />}>
                        {value.map((item, index) => {
                            return (
                                <Components.AccordionItem
                                    key={index}
                                    index={index}
                                    name={name}
                                    title={"Referência" + " #" + (index + 1)}
                                >
                                    <Components.FormGroup title="Ciclo de faturação">
                                        <Components.Block row>
                                            <Components.Block width={212} mr={20}>
                                                <Components.Input error={errors[`${name}[${index}].amount`]} Component={Components.TextInput} type="number" step="0.01" name={`${name}[${index}].amount`} label="Montante" placeholder="Montante a faturar" icon="Euro" />
                                            </Components.Block>
                                            <Components.Block width={212} pt={24}>
                                                <Components.Input error={errors[`${name}[${index}].cycleOption`]} Component={Components.DropdownInput} name={`${name}[${index}].cycleOption`} options={billingCycleOptions} initialValue={billingCycleOptions[0]} monocolor />
                                            </Components.Block>
                                        </Components.Block>
                                    </Components.FormGroup>

                                    {(!!item.cycleOption && item.cycleOption.value !== "0") && <Components.FormGroup title="Divisão de faturação">
                                        <Components.Block row>
                                            <Components.Block mr={12}>
                                                <Components.Input Component={Components.RadioInput} radioValue="0" name={`${name}[${index}].partitionType`} label="Automática" />
                                            </Components.Block>
                                            <Components.Block>
                                                <Components.Input Component={Components.RadioInput} radioValue="1" name={`${name}[${index}].partitionType`} label="Manual" />
                                            </Components.Block>
                                        </Components.Block>
                                        {(!!item.partitionType && item.partitionType === "1") ?
                                            <Components.Block row wrap mr={-10} ml={-10} mt={-10} mb={-10}>
                                                {(() => {
                                                    const inputs = [];
                                                    const numberOfInputs = parseInt(item.cycleOption.value);

                                                    for (let i = 0; i < numberOfInputs; i++) {
                                                        inputs.push(
                                                            <Components.Block mr={10} ml={10} mt={10} mb={10} key={i}>
                                                                <Components.Input Component={Components.TextInput} type="number" name={`${name}[${index}].amountPart[${i}]`} label={`${i + 1}º ${item.cycleOption.divisionLabel}`} icon="Euro" />
                                                            </Components.Block>
                                                        )
                                                    }

                                                    return inputs;
                                                })()}
                                            </Components.Block>
                                            :
                                            <Components.Block row wrap mr={-10} ml={-10} mt={-10} mb={-10}>
                                                <Components.Block mr={10} ml={10} mt={10} mb={10}>
                                                    <Components.Input Component={Components.TextInput} disabled placeholder={!!item.amount ? (item.amount / parseInt(item.cycleOption.value)) : ""} type="number" name={`${name}[${index}].amountPart`} label={`Por ${item.cycleOption.divisionLabel}`} icon="Euro" />
                                                </Components.Block>
                                            </Components.Block>
                                        }
                                    </Components.FormGroup>}
                                    <Components.FormGroup title="Duração do ciclo de faturação">
                                        <Components.Block row >
                                            <Components.Block width={168} mr={20}>
                                                <Components.Input error={errors[`${name}[${index}].startDate`]} Component={Components.DateInput} name={`${name}[${index}].startDate`} label="Data de ínicio" placeholder="DD / MM / AAAA" icon="Calendar" />
                                            </Components.Block>
                                            <Components.Block width={168}>
                                                <Components.Input error={errors[`${name}[${index}].endDate`]} Component={Components.DateInput} name={`${name}[${index}].endDate`} label="Data de fim" placeholder="DD / MM / AAAA" icon="Calendar" />
                                            </Components.Block>
                                        </Components.Block>
                                        <Components.Block>
                                            <Components.Input error={errors[`${name}[${index}].description`]} Component={Components.TextAreaInput} name={`${name}[${index}].description`} label="Descrição" placeholder="Descrição da fatura" />
                                        </Components.Block>
                                    </Components.FormGroup>
                                    <Components.FormGroup>
                                        <Components.Block row center>
                                            <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100} width={71} mr={137}>Referência</Components.Text>
                                            <Components.Block width={334}>
                                                <Components.Input error={errors[`${name}[${index}].reference`]} Component={Components.TextInput} name={`${name}[${index}].reference`} placeholder="Referência da fatura" />
                                            </Components.Block>
                                        </Components.Block>
                                    </Components.FormGroup>
                                    <Components.FormGroup>
                                        <Components.Block row center>
                                            <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100} width={129} mr={79}>Código de software</Components.Text>
                                            <Components.Block width={334}>
                                                <Components.Input error={errors[`${name}[${index}].softwareCode`]} Component={Components.TextInput} name={`${name}[${index}].softwareCode`} placeholder="Código de software de faturação" />
                                            </Components.Block>
                                        </Components.Block>
                                    </Components.FormGroup>
                                    <Components.FormGroup>
                                        <Components.DeleteLine
                                            title="Eliminar fatura"
                                            description="Esta ação irá eliminar os dados relativos à fatura permanentemente"
                                            onClickDelete={() => arrayHelpers.remove(index)}
                                        />
                                    </Components.FormGroup>
                                </Components.AccordionItem>
                            );
                        })}
                    </Components.Portlet>
                );
            }}
        />
    );
}

export default AccordionBilling;
