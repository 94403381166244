import gql from "graphql-tag";

export const QUERY_LICENSES = gql`
    query licenses($perPage: Int, $currentPage: Int, $search: String, $orderBy: LicensesOrderBy) {
        licenses(perPage: $perPage, currentPage: $currentPage, search: $search, orderBy: $orderBy){
            rows {
                id
                name
            }
            pagination {
                total
                perPage
                lastPage
                currentPage
                hasPreviousPage
                hasNextPage
            }
        }
    }
`;

export const QUERY_LICENSE = gql`
    query license($id: ID!) {
        license(id: $id) {
            id
            name
        }
    }
`;

export const QUERY_FACTORINGS = gql`
    query factorings($perPage: Int, $currentPage: Int, $search: String, $orderBy: FactoringsOrderBy) {
        factorings(perPage: $perPage, currentPage: $currentPage, search: $search, orderBy: $orderBy){
            rows {
                id
                name
            }
            pagination {
                total
                perPage
                lastPage
                currentPage
                hasPreviousPage
                hasNextPage
            }
        }
    }
`;

export const QUERY_FACTORING = gql`
    query factoring($id: ID!) {
        factoring(id: $id) {
            id
            name
        }
    }
`;

export const QUERY_CATEGORIES = gql`
    query categories($perPage: Int, $currentPage: Int, $search: String, $orderBy: CategoriesOrderBy) {
        categories(perPage: $perPage, currentPage: $currentPage, search: $search, orderBy: $orderBy){
            rows {
                id
                name
            }
            pagination {
                total
                perPage
                lastPage
                currentPage
                hasPreviousPage
                hasNextPage
            }
        }
    }
`;

export const QUERY_CATEGORY = gql`
    query category($id: ID!) {
        category(id: $id) {
            id
            name
        }
    }
`;

export const QUERY_USERS = gql`
    query users($perPage: Int, $currentPage: Int, $search: String, $orderBy: UsersOrderBy) {
        users(perPage: $perPage, currentPage: $currentPage, search: $search, orderBy: $orderBy){
            rows {
                id
                name
                email
                position
            }
            pagination {
                total
                perPage
                lastPage
                currentPage
                hasPreviousPage
                hasNextPage
            }
        }
    }
`;

export const QUERY_USER = gql`
    query user($id: ID!) {
        user(id: $id) {
            id
            name
            email
            position
        }
    }
`;