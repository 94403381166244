import React from "react";
import { Components } from "../../../MediStrap";

function AccordionContacts({ name, value = [], anchor, errors }) {
    return (
        <Components.FieldArray
            name={name}
            render={(arrayHelpers) => {
                return (
                    <Components.Portlet anchor={anchor} noInnerPadding icon="Contacts" title="Contactos" actions={<Components.Button label="Adicionar Contacto" type="button" color="brand" size="small" onClick={() => arrayHelpers.insert(value.length + 1, {})} />}>
                        {value.map((_item, index) => {
                            return (
                                <Components.AccordionItem
                                    key={index}
                                    index={index}
                                    name={name}
                                    title={"Contacto" + " #" + (index + 1)}
                                >
                                    <Components.FormGroup>
                                        <Components.Block width={652}>
                                            <Components.Input error={errors[`${name}[${index}].name`]} Component={Components.TextInput} name={`${name}[${index}].name`} label="Nome" placeholder="Primeiro e último nome" />
                                        </Components.Block>
                                        <Components.Block row>
                                            <Components.Block width={420} pr={10}>
                                                <Components.Input error={errors[`${name}[${index}].email`]} Component={Components.TextInput} name={`${name}[${index}].email`} label="Email" placeholder="Email de contacto" />
                                            </Components.Block>
                                            <Components.Block width={328} pl={10}>
                                                <Components.Input error={errors[`${name}[${index}].phone`]} Component={Components.TextInput} name={`${name}[${index}].phone`} label="Telefone" placeholder="Número de telefone" />
                                            </Components.Block>
                                        </Components.Block>
                                    </Components.FormGroup>
                                    <Components.FormGroup>
                                        <Components.DeleteLine
                                            title="Eliminar contacto"
                                            description="Esta ação irá eliminar os dados relativos ao contacto permanentemente"
                                            onClickDelete={() => arrayHelpers.remove(index)}
                                        />
                                    </Components.FormGroup>
                                </Components.AccordionItem>
                            );
                        })}
                    </Components.Portlet>
                );
            }}
        />
    );
}

export default AccordionContacts;
