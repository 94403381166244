import { useMutation } from "@apollo/react-hooks";
import { useRef } from "react";
import * as yup from 'yup';
import { AccordionContacts } from "../../../../components";
import { Components } from "../../../../MediStrap";
import { CITIES, DISTRICTS } from "../../../../utils/commonData";
import { MUTATION_CLIENT_CREATE } from "../../graphql/mutations";

const districtOptions = DISTRICTS;

const cityOptions = CITIES;

function ModalCreate({ isOpen, onRequestClose, onCreate, factorings }) {
    const clientCreateFormRef = useRef(null);

    const [mutationClientCreate, { loading }] = useMutation(
        MUTATION_CLIENT_CREATE,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.clientCreate) {
                    onCreate();
                    onRequestClose();
                }
            }
        }
    );

    const createClient = (params = {}) => {
        const { name, nif, address, city, district, postalCode, factoring, contacts } = params;

        mutationClientCreate({
            variables: {
                name: !!name ? name : undefined,
                nif: !!nif ? nif : undefined,
                address: !!address ? address : undefined,
                city: !!city ? city : undefined,
                district: !!district ? district : undefined,
                postalCode: !!postalCode ? postalCode : undefined,
                factoring: !!factoring ? factoring : undefined,
                contacts: !!contacts ? contacts : []
            }
        });
    }

    return (
        <Components.ModalThatSlides
            id="criar-cliente"
            title="Criar Cliente"
            width="100%"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            actions={
                <Components.Block row centser>
                    <Components.Block>
                        <Components.Button loading={loading} label="Guardar" color="brand" onClick={() => { clientCreateFormRef.current.handleSubmit() }} />
                    </Components.Block>
                </Components.Block>
            }
            menu={[
                { icon: "Information", title: "Informações", anchor: "informations" },
                { icon: "Contacts", title: "Contactos", anchor: "contacts" }
            ]}
        >
            <Components.Form
                schema={yup.object().shape({
                    name: yup.string().required(),
                    district: yup.object().nullable(),
                    city: yup.object().nullable(),
                    factoring: yup.object().nullable(),
                    address: yup.string().nullable(),
                    postalCode: yup.string().nullable(),
                    nif: yup.string().nullable(),
                    contacts: yup.array(yup.object({
                        name: yup.string().nullable(),
                        email: yup.string().email().nullable(),
                        phone: yup.string().nullable()
                    }))
                })}
                onSubmit={(values) => {
                    const district = !!values.district ? values.district.value : undefined;
                    const city = !!values.city ? values.city.value : undefined;
                    const factoring = !!values.factoring ? values.factoring.value : undefined;
                    createClient({ name: values.name, nif: values.nif, address: values.address, city, district, postalCode: values.postalCode, factoring, contacts: values.contacts })
                }}
                innerRef={clientCreateFormRef}
            >
                {({
                    values,
                    errors,
                    handleSubmit,
                    setFieldValue
                }) => {
                    !!values.city && !cityOptions.find((item) => item.district == values.district.value).cities.find((item) => item.value == values.city.value) && setFieldValue('city', null);
                    return (
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <Components.Portlet icon="Information" title="Informações" anchor="informations">
                                <Components.FormGroup title="Geral" description="Informação geral do contrato e cliente">
                                    <Components.Block width={652}>
                                        <Components.Input error={errors.name} Component={Components.TextInput} name="name" label="Nome*" description="Nome identificativo do cliente ou empresa" placeholder="Nome do cliente" />
                                    </Components.Block>
                                    <Components.Block row>
                                        <Components.Block width={328} pr={10}>
                                            <Components.Input error={errors.district} Component={Components.DropdownInput} name="district" label="Distrito" placeholder="Distrito" options={districtOptions} />
                                        </Components.Block>
                                        <Components.Block width={304} pl={10}>
                                            <Components.Input error={errors.city} Component={Components.DropdownInput} name="city" label="Cidade" placeholder="Cidade da sede" options={!!values.district ? cityOptions.find((item) => item.district == values.district.value).cities : []} />
                                        </Components.Block>
                                    </Components.Block>
                                    <Components.Block row>
                                        <Components.Block width={536} pr={10}>
                                            <Components.Input error={errors.address} Component={Components.TextInput} name="address" label="Morada" placeholder="Morada completa" />
                                        </Components.Block>
                                        <Components.Block width={190} pl={10}>
                                            <Components.Input error={errors.postalCode} Component={Components.TextInput} name="postalCode" label="Código Postal" placeholder="0000-000" />
                                        </Components.Block>
                                    </Components.Block>
                                </Components.FormGroup>
                                <Components.FormGroup title="Faturação" description="Dados de faturação">
                                    <Components.Block row ml={-10} mr={-10}>
                                        <Components.Block width={304} ml={10} mr={10}>
                                            <Components.Input error={errors.nif} Component={Components.TextInput} name="nif" label="NIF" placeholder="Número de Identificação Fiscal" />
                                        </Components.Block>
                                        <Components.Block width={232} ml={10} mr={10}>
                                            <Components.Input error={errors.factoring} Component={Components.DropdownInput} name="factoring" label="Factoring" placeholder="Seleccionar factoring" options={factorings.map((factoring) => ({ label: factoring.name, value: factoring.id }))} />
                                        </Components.Block>
                                    </Components.Block>
                                </Components.FormGroup>
                            </Components.Portlet>
                            <Components.Input Component={AccordionContacts} name="contacts" anchor="contacts" errors={errors} />
                        </form>
                    )
                }}
            </Components.Form>
        </Components.ModalThatSlides>
    );
}

export default ModalCreate;