import gql from "graphql-tag";

export const MUTATION_CLIENT_CREATE = gql`
    mutation clientCreate($name: String!, $nif: String, $address: String, $city: String, $district: String, $postalCode: String, $factoring: ID, $contacts: [InputClientContact!]!) {
        clientCreate (name: $name, nif: $nif, address: $address, city: $city, district: $district, postalCode: $postalCode, factoring: $factoring, contacts: $contacts){
            id
            name
            nif
            address
            city
            district
            postalCode
            factoring {
                id
                name
            }
            contacts {
                name
                email
                phone
            }
        }
    }
`;
export const MUTATION_CLIENT_UPDATE = gql`
    mutation clientUpdate($id: ID!, $name: String!, $nif: String, $address: String, $city: String, $district: String, $postalCode: String, $factoring: ID, $contacts: [InputClientContact!]!) {
        clientUpdate (id: $id, name: $name, nif: $nif, address: $address, city: $city, district: $district, postalCode: $postalCode, factoring: $factoring, contacts: $contacts){
            id
            name
            nif
            address
            city
            district
            postalCode
            factoring {
                id
                name
            }
            contacts {
                name
                email
                phone
            }
        }
    }
`;

export const MUTATION_CLIENT_DELETE = gql`
    mutation clientDelete($id: ID!) {
        clientDelete (id: $id){
            id
            name
            nif
            address
            city
            district
            postalCode
            factoring {
                id
                name
            }
            contacts {
                name
                email
                phone
            }
        }
    }
`;