import React, { useState } from 'react';
import styled from 'styled-components';
import { Components, MediStrap } from "../../../../MediStrap";
import { EVENT_DISPLAYS } from '../../../../utils/commonData';
import ModalCreate from '../Reminders/ModalCreate';

const MediStrapProperties = MediStrap.getProperties();

export default function EventAccordion(
    {
        event,
        children,
        remindersRef
    }
) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Wrapper>
            <Components.Block
                width={328}
                height={48}
                pointer
                row
                center
                space="between"
                onClick={() => setIsOpen(!isOpen)}
                pt={16}
                pb={16}
                pl={24}
                pr={28}
            >
                <Components.Block row center>
                    <Components.Block height={8} width={8} br={5} color={EVENT_DISPLAYS[event.type].color} mr={16} />
                    <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100}>{EVENT_DISPLAYS[event.type].label}</Components.Text>
                </Components.Block>
                <Components.Block row center>
                    <Components.Block mr={16}>
                        <Components.Button
                            color="empty"
                            icon="Sound"
                            size="squareSmall"
                            title="createNotificationButton"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                remindersRef.current.createReminder(event.contract);
                            }} />
                    </Components.Block>
                    <Components.Icon name={isOpen ? "Angle_up" : "Angle_down"} color={MediStrapProperties.colors.grey60} />
                </Components.Block>
            </Components.Block>
            <Content color={MediStrapProperties.colors.grey5} open={isOpen}>
                {children}
            </Content>
        </Wrapper>
    );
}

const Wrapper = styled(Components.Block)`
    border-top: 1px solid ${MediStrapProperties.colors.grey20};

    :first-of-type {
        border-top: none;
    }
`;

const Content = styled(Components.Block)`
    height: ${({ open }) => !!open ? "fit-content" : "0px"};
    overflow: hidden;
    border-top: ${({ open }) => !!open ? "1px" : "0px"} solid ${MediStrapProperties.colors.grey20};
`;