import React from 'react';
import { Components } from '../../../../../../MediStrap/';
import Day from '../Day';

export default function Week(
    {
        dates,
        currentMonth,
        isEventModalOpen,
        setEventModalOpen,
        selectedDay,
        setSelectedDay,
        setEventsOfDay,
        events
    }) {
    return (
        <Components.Block row flex space="between" mb={14} mr={-7} ml={-7}>
            {dates.map((item, key) => {
                return <Day
                    date={item}
                    key={key}
                    currentMonth={currentMonth}
                    isEventModalOpen={isEventModalOpen}
                    setEventModalOpen={setEventModalOpen}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    setEventsOfDay={setEventsOfDay}
                    events={events}
                />
            })}
        </Components.Block>
    );
}