import { useLazyQuery } from "@apollo/react-hooks";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import styled from "styled-components";
import { Components, MediStrap } from "../../../../MediStrap";
import { QUERY_REMINDERS } from "../../graphql/queries";
import ModalCreate from "./ModalCreate";
import ModalUpdate from "./ModalUpdate";

const MediStrapProperties = MediStrap.getProperties();

export const Reminders = forwardRef((props, ref) => {
    const [isModalCreateOpen, setModalCreateOpen] = useState(false);
    const [createModalInitialValues, setCreateModalInitialValues] = useState({});
    const [reminders, setReminders] = useState({
        rows: [],
        pagination: {
            total: 0,
            perPage: 10,
            lastPage: 0,
            currentPage: 1,
            hasPreviousPage: false,
            hasNextPage: false
        }
    });
    const [reminderId, setReminderId] = useState(null);
    const [isModalUpdateOpen, setModalUpdateOpen] = useState(false);

    const [queryReminders] = useLazyQuery(
        QUERY_REMINDERS,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.reminders) {
                    setReminders(data.reminders);
                }
            }
        }
    );

    const requestReminders = (params = {}) => {
        const { perPage, currentPage } = params;

        queryReminders({
            variables: {
                perPage: perPage || reminders.pagination.perPage,
                currentPage: currentPage || reminders.pagination.currentPage,
                search: undefined
            }
        });
    }

    useImperativeHandle(ref, () => ({
        createReminder(contract) {
            setCreateModalInitialValues({ contract });
            setModalCreateOpen(true);
        }
    }));
    useEffect(() => {
        requestReminders({ perPage: reminders.pagination.perPage, currentPage: 1 });
    }, []);

    return (
        <>
            <Components.Block width={444} mr={20}>
                <Components.Text size={16} height={24} mb={12}>Lembretes</Components.Text>
                <BlockWithShadow
                    height={72}
                    pt={24}
                    pb={24}
                    pl={24}
                    pr={24}
                    row
                    center
                    space="between"
                    color={MediStrapProperties.colors.white100}
                    style={{ borderTopLeftRadius: MediStrapProperties.style.borderRadius, borderTopRightRadius: MediStrapProperties.style.borderRadius }}
                >
                    <Components.Text size={16} height={24}>{reminders.rows.length} {reminders.rows.length === 1 ? "Lembrete" : "Lembretes"}</Components.Text>
                    <Components.Button size="small" label="Criar lembrete" onClick={() => setModalCreateOpen(true)} />
                </BlockWithShadow>
                <Components.AdvancedTable
                    columns={[
                        {
                            label: "Contrato",
                            value: "contract",
                            width: 188,
                            order: false
                        },
                        {
                            label: "Data",
                            value: "date",
                            width: 128,
                            order: false
                        },
                        {
                            label: "Motivo",
                            value: "motive",
                            width: 128,
                            order: false
                        }
                    ]}
                    rows={reminders.rows.map((reminder) => {
                        return ({
                            contract: <Components.Block column>
                                <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100} title={reminder.contract.client.name}>{reminder.contract.client.name}</Components.Text>
                                <Components.Text size={11} height={16} color={MediStrapProperties.colors.grey60} title="11 / 12 / 2021">11 / 12 / 2021</Components.Text>
                            </Components.Block>,
                            date: <Components.Block column>
                                <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100} title="11 / 12 / 2021">{reminder.date}</Components.Text>
                            </Components.Block>
                            ,
                            motive: <Components.Block row center space="between" height={56}>
                                <Components.Block row center>
                                    <Components.Block height={8} width={8} br={5} color={MediStrapProperties.colors.validation100} mr={16} />
                                </Components.Block>
                                <Components.Block mr={8}>
                                    <Components.Button icon="Edit" size="squareSmall" color="empty" onClick={() => { setReminderId(reminder.id); setModalUpdateOpen(true) }} />
                                </Components.Block>
                            </Components.Block>
                        })
                    })}
                    noBorderRadius
                    noContentBorder
                    backgroundColor={MediStrapProperties.colors.white100}
                />
            </Components.Block>
            <ModalCreate
                onRequestClose={() => setModalCreateOpen(false)}
                onCreate={() => requestReminders({ currentPage: 1 })}
                isOpen={isModalCreateOpen}
                values={createModalInitialValues}
            />
            <ModalUpdate
                onRequestClose={() => {
                    setReminderId(null);
                    setModalUpdateOpen(false);
                }}
                onUpdate={(updateReminder) => {
                    const index = reminders.rows.findIndex((item) => item.id == updateReminder.id);
                    reminders.rows[index] = updateReminder;
                    setReminders({ ...reminders });
                }}
                onDelete={() => {
                    requestReminders();
                }}
                isOpen={isModalUpdateOpen}
                reminderId={reminderId}
            />

        </>
    )
})


const BlockWithShadow = styled(Components.Block)`
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
`