import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from "apollo-link-error";
import { createUploadLink } from 'apollo-upload-client';
import { toast } from 'react-toastify';

let token = null;

export const setClientToken = (value) => {
    token = value;
}

export const getClientToken = () => {
    return token;
}

const errorLink = onError(({ graphQLErrors, networkError, response }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
            toast.error(graphQLErrors[0].message)
        );
    }

    if (networkError) {
        console.error("error.network_error");
    };
});

const authLink = setContext((operation, { headers }) => {
    const token = getClientToken();

    if (!headers) {
        headers = {};
    }

    if (token) {
        headers.Authorization = token;
    }

    return {
        headers: {
            ...headers
        }
    }
});

const client = new ApolloClient({
    link: ApolloLink.from([
        errorLink,
        authLink,
        createUploadLink({
            uri: `${process.env.REACT_APP_BACKEND_HOST}/graphql`,
        }),
    ]),
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'network-only',
            errorPolicy: 'ignore',
        },
        query: {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        },
    }
});

export default client;